const checkoutRouter = [
    {
        path: "/checkout",
        name: "Checkout",
        component: () => import("@/views/checkout/index.vue"),
        meta: { navbar: false, title: "結算頁" },
    },
];

export default checkoutRouter;
