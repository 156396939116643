<template>
    <div>
        <div class="pc-container">
            <div :class="lang === 'cht' ? 'app-footer1' : 'app-footer2'" :style="{ width: footerWidth }">
                <div class="content">
                    <div class="left content-main">
                        <div class="famkolls">
                            <el-image class="logo" :src="urlLogo" lazy />
                        </div>
                        <div class="left-content">
                            {{
                                $t(
                                    "Famkools是备受香港人信赖的本地时尚、美妆和潮流穿搭的一站式购物电商平台，致力于为用户带来顶级品牌商品和创意潮流体验。2024年，荣获香港商业时报颁发的【年度最杰出时尚美妆购物平台】奖项。"
                                )
                            }}<br />
                            {{
                                $t(
                                    "Famkools希望将香港的美好文化传播到全球各地，并全方位帮助推动香港传统品牌、新晋设计师品牌和明星主理品牌成为世界知名品牌。同时亦为喜爱香港文化的全球朋友提供更多文化交流机会。"
                                )
                            }}<br />
                            {{ $t("Famkools的目标是为您发现全球最lux和最cool的事物，一起来探索香港美好时尚生活！") }}
                        </div>
                    </div>
                    <div class="middle content-main">
                        <div class="guanyu">
                            {{ $t("了解更多平台规则和说明") }}
                        </div>

                        <div class="links">
                            <div v-for="(item, index) in linkss" :key="index + 10" class="linkBox">
                                <div v-for="({ url, name }, key) in item" :key="key" class="linkBoxLine">
                                    <router-link :to="url" class="link">
                                        <span class="spanfour" @click="checkPath(url)">{{ name }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="righ content-main">
                        <div class="downapp">{{ $t("立即下载手机端APP") }}</div>
                        <div class="downapp-img">
                            <div
                                v-for="{ url, toUrl, name, img } in urls"
                                :key="name"
                                class="downapp-div downloadIcons"
                            >
                                <a :href="toUrl" target="_blank">
                                    <el-image class="img1" :src="url" lazy />
                                </a>
                                <img :src="img" class="downloadImg" />
                            </div>
                        </div>
                        <div class="downapp titles">{{ $t("支持付款") }}</div>
                        <div class="downapp-img">
                            <div v-for="{ url, name } in urls1" :key="name" class="downapp-div">
                                <el-image :src="url" lazy class="img2" />
                            </div>
                        </div>
                        <div class="downapp titles">{{ $t("关注我们") }}</div>
                        <div class="downapp-img">
                            <div
                                v-for="{ url, name, pushUrl } in urls2"
                                :key="name"
                                class="downapp-div socialIcon"
                                :class="pushUrl !== '/' && 'active'"
                            >
                                <el-image class="img3" :src="url" lazy @click="goSocialPage(pushUrl)" />
                            </div>
                        </div>
                        <p class="copy-ver">
                            {{ $t("贵金属及宝石交易商A类注册号码: A-B-23-11-02878") }}
                        </p>
                        <p class="copy-ver">Copyright © 2023 Famkools All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="h5-container">
            <div class="logo">
                <img src="@/assets/images/Famkools-logo-700.png" class="logo-700" />
            </div>
            <div class="text">
                {{
                    $t(
                        "Famkools是备受香港人信赖的本地时尚、美妆和潮流穿搭的一站式购物电商平台，致力于为用户带来顶级品牌商品和创意潮流体验。2024年，荣获香港商业时报颁发的【年度最杰出时尚美妆购物平台】奖项。"
                    )
                }}<br />
                {{
                    $t(
                        "Famkools希望将香港的美好文化传播到全球各地，并全方位帮助推动香港传统品牌、新晋设计师品牌和明星主理品牌成为世界知名品牌。同时亦为喜爱香港文化的全球朋友提供更多文化交流机会。"
                    )
                }}<br />
                {{ $t("Famkools的目标是为您发现全球最lux和最cool的事物，一起来探索香港美好时尚生活！") }}
            </div>
            <div class="pay">
                <div class="title">{{ $t("支持付款") }}</div>
                <div class="payLogo">
                    <img class="logo-700" src="@/assets/images/pay/paypal.png" />
                    <img class="logo-700" src="@/assets/images/pay/Visa.png" />
                    <img class="logo-700" src="@/assets/images/pay/mastercard.png" />
                    <img class="logo-700" src="@/assets/images/pay/America.png" />
                    <img class="logo-700" src="@/assets/images/pay/AlipayHK.png" />
                    <img class="logo-700" src="@/assets/images/pay/FPS-700.png" />
                </div>
            </div>
            <div class="followUs">
                <div class="followword">{{ $t("关注我们") }}</div>
                <div class="logo">
                    <div
                        v-for="{ url, name, pushUrl } in urls2H5"
                        :key="name"
                        class="downapp-div socialIcon"
                    >
                        <el-image class="logo-20" :src="url" lazy @click="goSocialPage(pushUrl,'h5跳转')" />
                    </div>
                    <!-- <img class="logo-20" src="@/assets/images/follow/ins-700.png" />
                    <img class="logo-20" src="@/assets/images/follow/face-700.png" />
                    <img class="logo-20" src="@/assets/images/follow/bofang-700.png" /> -->
                    <!-- <img class="logo-20" src="@/assets/images/follow/weixin-700.png" /> -->
                </div>
            </div>
            <div class="copy">
                <div class="copyRx">{{ $t("贵金属及宝石交易商A类注册号码: A-B-23-11-02878") }}</div>
                <div class="copyRx">Copyright © 2023 Famkools All Rights Reserved.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getLang } from "@/utils/auth.js";

import ios from "@/assets/ios.png";
import android from "@/assets/android.png";

export default {
    name: "AppFooter",
    data() {
        return {
            email: "",
            lang: "cht",
            linkss: [
                [
                    {
                        name: this.$t("关于我们"),
                        url: "/statement/aboutus",
                    },
                    {
                        name: this.$t("购物流程"),
                        url: "/statement/shoppingGuide",
                    },
                ],
                [
                    {
                        name: this.$t("工作机会"),
                        url: "/statement/careers",
                    },
                    {
                        name: this.$t("配送说明"),
                        url: "/statement/delivery",
                    },
                ],
                [
                    {
                        name: this.$t("商家入驻"),
                        url: "/merchantpartnership",
                    },
                    {
                        name: this.$t("私隐条款"),
                        url: "/statement/privacy",
                    },
                ],
                [
                    {
                        name: this.$t("买手入驻"),
                        url: "/merchantpartnership",
                    },
                    {
                        name: this.$t("使用条款"),
                        url: "/statement/note",
                    },
                ],
                [
                    {
                        name: this.$t("达人入驻"),
                        url: "/talent/talentEntry",
                    },
                    {
                        name: this.$t("常见问题"),
                        url: "/statement/FAQs",
                    },
                ],
                [
                    {
                        name: this.$t("会员权益"),
                        url: "",
                    },
                    {
                        name: this.$t("退货政策"),
                        url: "/statement/return",
                    },
                ],
                [
                    {
                        name: this.$t("季节特卖"),
                        url: "/product/list?searchOrderType=1",
                    },
                    {
                        name: "",
                        url: "",
                    },
                ],
            ],
            urlLogo: require("@/assets/images/footerlogo.png"),
            urls: [
                {
                    url: require("@/assets/images/group132.png"),
                    toUrl: "https://apps.apple.com/us/app/famkools-國際名牌購物平台/id6473601571",
                    img: ios,
                },
                {
                    url: require("@/assets/images/group131.png"),
                    toUrl: "https://play.google.com/store/apps/details?id=com.famkools.android",
                    img: android,
                },
            ],
            urls1: [
                {
                    url: require("@/assets/images/mastercard.png"),
                },
                {
                    url: require("@/assets/images/visa.png"),
                },
                {
                    url: require("@/assets/images/unionpay.png"),
                },
                {
                    url: require("@/assets/images/alipayHK.png"),
                },
                {
                    url: require("@/assets/images/fps.png"),
                },
            ],
            urls2: [
                {
                    url: require("@/assets/images/social1.png"),
                    name: "ins",
                    pushUrl: "https://www.instagram.com/famkools_hk/",
                },
                {
                    url: require("@/assets/images/social3.png"),
                    name: "facebook",
                    pushUrl: "https://www.facebook.com/profile.php?id=61553470966481&mibextid=LQQJ4d",
                },
                {
                    url: require("@/assets/images/social2.png"),
                    name: "youTube",
                    pushUrl: "https://youtube.com/@famkools-eo8bl?si=l9sLPGFZ6bV_GF5H",
                },
                
                // {
                //     url: require("@/assets/images/wxpay.png"),
                //     name: "wx",
                //     pushUrl: "/",
                // },
            ],
            urls2H5: [
                {
                    url: require("@/assets/images/follow/ins-700.png"),
                    name: "ins",
                    pushUrl: "https://www.instagram.com/famkools_hk/",
                },
                {
                    url: require("@/assets/images/follow/face-700.png"),
                    name: "youTube",
                    pushUrl: "https://youtube.com/@famkools-eo8bl?si=l9sLPGFZ6bV_GF5H",
                },
                {
                    url: require("@/assets/images/follow/bofang-700.png"),
                    name: "facebook",
                    pushUrl: "https://www.facebook.com/profile.php?id=61553470966481&mibextid=LQQJ4d",
                },
            ],

            footerWidth: "100%",
        };
    },
    mounted() {
        this.lang = getLang();
        window.addEventListener("resize", this.handleResize);
        if (this.isMobile()) {
            this.$nextTick(() => {
                this.footerWidth = "1280px";
            });
        } else {
            this.footerWidth = "100%";
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        goSocialPage(url,el) {
            if (url === "/") return;
            console.log(url,el,111111)
            window.open(url);
        },
        handleResize() {
            this.$nextTick(() => {
                this.footerWidth = window.innerWidth - 8 + "px";
            });
        },
        checkPath(url) {
            if (!url) {
                this.$message({
                    message: this.$t("暂未开放，敬请期待!"),
                    type: "warning",
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.app-footer1 {
    min-height: 370px;
    background: #222222;
    padding: 10px 0 5px 0;
    z-index: 10;

    ::v-deep .copy-ver {
        margin: 10px 0;
        color: white;
        background-color: #222222;
        font-size: 12px;
        font-weight: 400;
    }

    .content {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;

        .left {
            width: 400px;
            //margin-right: 151px;
            line-height: 14px;

            .famkolls {
                .logo {
                    width: 160px;
                }
            }

            .left-content {
                width: 100%;
                margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 33px;
                color: #b3b3b3;
                font-family: PingFang SC;
                padding-bottom: 10px;
            }
        }

        .middle {
            width: 225px;
            text-align: center;
            .guanyu {
                margin-top: 7px;
                text-align: center;
                font-size: 16px;
                font-family: "PingFang SC";
                font-weight: 500;
                line-height: 14px;
                color: #fff;
                height: 14px;
                margin-bottom: 10px;
            }

            .links {
                margin-top: 4px;
                font-size: 14px;
                display: flex;
                //flex-direction: column;
                //width: 45%;
                flex-wrap: wrap;
                justify-content: space-between;
                flex: 2;
                height: 8rem;
                flex-wrap: wrap;
                width: 100%;
                .linkBox {
                    min-width: 100%;
                    margin-top: 9px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    .linkBoxLine {
                        width: 100%;
                        //text-align: left;
                        text-align: center;
                        .link {
                            font-size: 14px;
                            color: #b3b3b3;
                            margin-bottom: 0.625rem;
                            text-decoration: none;
                            margin-right: 0.625rem;

                            .spanfour {
                                margin-top: 10px;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                            }
                        }
                    }
                }
            }
        }

        .right {
            margin-top: 5px;
        }

        .downapp-img {
            margin-top: 18px;
            margin-bottom: 15px;
            display: flex;

            .downapp-div {
                margin-right: 10px;
                &.downloadIcons {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }

            // margin-top: 20px;
            .img1 {
                width: 110px;
                height: 33px;
            }

            .img2 {
                width: 38px;
                height: 26px;
            }

            .img3 {
                width: 20px;
                height: 20px;
            }
        }
    }

    div {
        color: #fff;
    }

    .righ .downapp {
        font-weight: 500;
        font-family: "PingFang SC";
    }
}

// 英文样式
.app-footer2 {
    min-height: 370px;
    background: #222222;
    padding: 10px 0 5px 0;
    z-index: 10;

    ::v-deep .copy-ver {
        margin: 13px 0;
        color: white;
        background-color: #222222;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
    }

    .content {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;

        .left {
            width: 600px;
            //margin-right: 151px;
            line-height: 14px;

            .famkolls {
                .logo {
                    width: 160px;
                }
            }

            .left-content {
                width: 100%;
                margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 33px;
                color: #b3b3b3;
                font-family: "PingFang SC";
                padding-bottom: 10px;
            }
        }

        .middle {
            width: 520px;
            text-align: center;
            .guanyu {
                margin-top: 7px;
                text-align: center;
                font-size: 16px;
                font-family: "PingFangSC";
                font-weight: 500;
                line-height: 14px;
                color: #fff;
                height: 14px;
                margin-bottom: 10px;
                padding-right: 110px;
            }

            .links {
                margin-top: 4px;
                font-size: 14px;
                display: flex;
                //flex-direction: column;
                //width: 45%;
                flex-wrap: wrap;
                justify-content: space-between;
                flex: 2;
                height: 8rem;
                flex-wrap: wrap;
                width: 100%;

                .linkBox {
                    min-width: 100%;
                    margin-top: 19px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    .linkBoxLine {
                        width: 100%;
                        text-align: left;
                        .link {
                            font-size: 14px;
                            color: #b3b3b3;
                            margin-bottom: 0.625rem;
                            text-decoration: none;
                            margin-right: 0.625rem;

                            .spanfour {
                                margin-top: 10px;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                            }
                        }
                    }
                }
            }
        }

        .right {
            margin-top: 5px;
        }

        .downapp-img {
            margin-top: 26px;
            margin-bottom: 15px;
            display: flex;

            .downapp-div {
                margin-right: 10px;
            }

            // margin-top: 20px;
            .img1 {
                width: 110px;
                height: 33px;
            }

            .img2 {
                width: 38px;
                height: 26px;
            }

            .img3 {
                width: 20px;
                height: 20px;
            }
        }
    }

    div {
        color: #fff;
    }

    .righ .downapp {
        font-weight: 500;
        font-family: "PingFang SC";
    }
}

.h5-container{
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: auto;
    background-color: #1a1a1a;
    .logo {
        .logo-700 {
            width: 160px;
        }
    }
    .text {
        font-family: "PingFang SC";
        font-size: 12px;
        line-height: 26px;
        font-weight: 500;
        color: #cccccc;
        //padding-right: 20px;
    }
    .pay {
        .title {
            margin: 10px 0;
            font-family: "PingFang SC";
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            color: #fff;
        }
        .payLogo {
            display: flex;
            gap: 10px;
            .logo-700 {
                width: 48px;
                height: 32px;
            }
        }
    }
    .followUs {
        .followword {
            color: #fff;
            margin: 10px 0;
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            font-family: "PingFang SC";
        }
        .logo {
            display: flex;
            gap: 10px;
            .logo-20 {
                width: 20px;
                height: 20px;
            }
        }
    }

    .copy {
        margin-top: 15px;
        .copyRx {
            margin-top: 10px;
            color: #b3b3b3;
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            font-family: "PingFang SC";
        }
    }
}

.downapp-div.socialIcon.active {
    cursor: pointer;
}

.downloadImg {
    width: 80px;
    margin-left: 10px;
}
</style>
