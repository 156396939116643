<template>
    <div class="app-header">
        <div class="bar">
            <jf-switch v-if="!find" class="switch" />
            <div class="logo pointer no-select" @click="$router.push('/')">
                <el-image :src="logo" fit="contain" class="logo-image" lazy />
            </div>
            <jf-ribbon class="ribbon" />
        </div>
        <jf-navbar v-if="navbar && !find" class="bar" />
        <find-navbar v-if="find" class="bar" />
        <subscriber-dialog />
    </div>
</template>

<script>
import JfSwitch from "./JfSwitch.vue";
import JfRibbon from "./JfRibbon.vue";
import JfNavbar from "./JfNavbar.vue";
import SubscriberDialog from "@/components/SubscriberDialog/index.vue";

import { mapGetters } from "vuex";
const logoUrl = require("@/assets/images/logo.png");
export default {
    name: "AppHeader",
    components: {
        JfSwitch,
        JfRibbon,
        JfNavbar,
        SubscriberDialog,
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters(["navbar", "find"]),
        logo() {
            return logoUrl;
        },
    },
    methods: {
        handleLogoRight() {
            this.$router.push("/business");
            document.oncontextmenu = new Function("event.returnValue=false");
            setTimeout(function () {
                document.oncontextmenu = new Function("event.returnValue=true");
            }, 500);
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.app-header {
    width: 100%;
    border-bottom: #e6e6e6 1px solid;
    padding: 0.625rem 0;
    background: #ffffff;
    z-index: 10;
    //height: $app-header-height;
    .bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $main-width;
        margin: auto;
        .switch,
        .ribbon,
        .logo {
            flex: 1;
        }
        .logo {
            font-size: 2.25rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
        .ribbon {
            display: flex;
            justify-content: end;
        }
    }
}

.logo-image {
    height: 25px;
}
</style>
