<template>
    <div
        class="container"
        :style="{ top: isSticky ? offsetTop + 'px' : '', width: width }"
        :class="{ sticky: isSticky }"
    >
        <slot />
    </div>
</template>
<script>
export default {
    props: {
        offsetTop: {
            type: Number,
            default: 0,
        },
        triggerTop: {
            type: Number,
            default: 0,
        },
        width: {
            type: String,
            default: "100%",
        },
    },
    data() {
        return {
            isSticky: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            this.isSticky = scrollTop > this.triggerTop;
            const appMain = document.querySelector(".app-main");
            const stickyHeight = this.offsetTop || document.querySelector(".container").offsetHeight;
            if (appMain) {
                appMain.style.paddingTop = this.isSticky ? `${stickyHeight}px` : "0";
            }
            this.$emit("sticky", this.isSticky);
        },
    },
};
</script>
<style>
.container {
    position: relative;
    top: 0;
    z-index: 1;
    transition: 1s;
}

.sticky {
    position: fixed;
    top: 0;
    right: 0;
    transition: 1s;
}
</style>
