import { getHomePageCms } from "@/api/home";
import { getProductByIds } from "@/api/goods";
import { getSocialListByIds } from "@/api/community";

const home = {
    state: {
        homeList: { cmsData: [], productList: [], socialList: [], resourceSocialItems: [], topBanner: [] },
        showTaxPopup: 1,
    },
    mutations: {
        SET_HOME_LIST: (state, data) => {
            state.homeList = data;
        },
        SET_TAX_POPUP_STATUS: (state, data) => {
            state.showTaxPopup = data;
        },
    },
    actions: {
        setTaxPopupStatus({ commit }, value) {
            commit("SET_TAX_POPUP_STATUS", value);
        },
        getHomeCmsData({ commit }) {
            return new Promise((resolve) => {
                getHomePageCms({}).then((val) => {
                    const resourceItems = [];
                    const resourceSocialItems = [];
                    const cmsData = val.data?.resourceList;
                    cmsData?.forEach((item) => {
                        if (item.resourceItems) {
                            item.resourceItems.forEach((val) => {
                                if (item.resourceType === 4) {
                                    resourceSocialItems.push(val.itemId);
                                } else {
                                    resourceItems.push(val.itemId);
                                }
                            });
                        }
                    });
                    Promise.all([
                        getProductByIds(resourceItems),
                        getSocialListByIds({ socialIdList: resourceSocialItems }),
                    ]).then(([products, social]) => {
                        const data = {
                            cmsData: cmsData,
                            productList: products.data,
                            socialList: social.data,
                            resourceSocialItems: resourceSocialItems,
                            topBanner: val.data?.notice,
                        };
                        commit("SET_HOME_LIST", data);
                        resolve(data);
                    });
                });
            });
        },
    },
};

export default home;
