<template>
    <div class="register-banner">
        <div class="register-box">
            <span>現在註冊送新人專屬HK$2000新人禮</span>
            <span class="register-btn" @click="onRegister">立即註冊</span>
            <img class="gif" :src="require('@/assets/images/register-benefits.gif')" alt="" @click="onRegister" />
        </div>
        <div v-if="show" class="dialog-register-benefits" :style="{ borderRadius: '4px' }">
            <div class="main">
                <i class="el-icon-close bnt-close" @click="show = false" />
                <div class="left-wrapper">
                    <div class="title-box">
                        <span class="title">限時新人福利</span>
                        <span class="sub-title">限時優惠，註冊即享</span>
                    </div>
                    <div class="coupon-box">
                        <img :src="require('@/assets/images/couponGift/coupon-register2.png')" alt="" />
                    </div>
                </div>
                <div class="btn-box">
                    <div class="btn-get" @click="onGetCoupon">
                        <span>立即領取</span>
                    </div>
                    <div class="tip">註冊領取更多福利</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RegisterBanner",
    data() {
        return {
            show: false,
            isAlreadyShow: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onRegister() {
            this.$store.dispatch("changeOpenStateType", "register");
            this.$store.dispatch("changeOpenState", true);
        },
        onGetCoupon() {
            this.show = false;
            setTimeout(() => {
                this.onRegister();
            }, 300);
        },
        onScroll() {
            if (this.isAlreadyShow) {
                window.removeEventListener("scroll", this.onScroll);
                return;
            }
            this.show = true;
            this.isAlreadyShow = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.register-banner {
    background-color: #000;
    height: 32px;
    .register-box {
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        position: relative;
        .register-btn {
            margin-left: 19px;
            padding-bottom: 2px;
            border-bottom: 1px solid #fff;
            cursor: pointer;
        }
        .gif {
            position: absolute;
            top: 10px;
            right: 108px;
            width: 172px;
        }
    }
    .dialog-register-benefits {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        width: 679px;
        background: linear-gradient(106deg, #e2c69a 0%, #ffe9c9 47.55%, #d5a96d 100%);
        box-sizing: border-box;
        padding: 30px 33px;
        .bnt-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
        }
        .main {
            display: flex;
            .title-box {
                margin-left: 7px;
                display: flex;
                align-items: baseline;
                .title {
                    color: #643f04;
                    font-size: 28px;
                    font-weight: 500;
                    line-height: normal;
                }
                .sub-title {
                    color: #1a1a1a;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 16px;
                }
            }
            .coupon-box {
                width: 330px;
                height: 68px;
                margin-top: 19px;
                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
            .btn-box {
                margin-left: 60px;
                margin-top: 33px;
                width: 191px;
                text-align: center;
                .btn-get {
                    width: 191px;
                    height: 48px;
                    border-radius: 240px;
                    background: #000;
                    color: #fff;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .tip {
                    color: #1a1a1a;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 8px;
                }
            }
        }
    }
}
</style>
