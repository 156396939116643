import axios from "axios";
import { Message, Loading } from "element-ui";
import { getPlatform, getToken } from "@/utils/auth";
import { blackList } from "./blackList";
import store from "@/store";
import router from "@/router";

let loading;
export let isRelogin = { show: false };
const requestCache = [];
const errorCode = {
    1000000: "登錄狀態已過期，請重新登錄",
    403: "當前操作沒有權限",
    404: "訪問資源不存在",
    default: "系統未知錯誤，請反饋給管理員",
};

const closeLoading = () => {
    if (requestCache.length) requestCache.pop();
    if (loading && !requestCache.length) {
        loading && loading.close();
    }
};

const request = axios.create({
    baseURL: "/prod-api",
    timeout: 60 * 1000,
});

request.interceptors.request.use(
    (config) => {
        const isToken = (config.headers || {}).isToken === false;
        // 是否需要防止数据重复提交
        // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
        if (getToken() && !isToken) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }

        if (getPlatform()) {
            config.headers["platform"] = getPlatform();
        }

        const loadLoading = blackList.find((item) => {
            return config.url.indexOf(item) !== -1;
        });

        if (blackList.includes(config.url) || loadLoading) return config;
        if (config.params?.loading !== false) {
            loading = Loading.service({
                lock: false,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, .7)",
            });
        }
        if (config.url) requestCache.push(config.url);
        return config;
    },
    (error) => Promise.reject(error)
);

request.interceptors.response.use(
    (response) => {
        closeLoading();
        const code = response.data.code || 200;
        const msg = errorCode[code] || response.data.msg || errorCode["default"];
        const res = response.data;
        if (response.config.responseType === "blob") {
            return response;
        }
        if (code === 1000000) {
            if (getToken()) {
                store.dispatch("LogOut").then(() => {
                    router.push("/");
                    Message({ message: msg, type: "error" });
                });
            }
        } else if (code === 9999999) {
            Message({ message: msg, type: "error" });
        } else if (code === 9999001 || code === 7000005) {
            Message({ message: msg, type: "warning" });
        } else if (code === 9999002) {
            Message({ message: msg, type: "error" });
        } else if (code === 1000002) {
            Message({ message: msg, type: "error" });
        } else if (code === 1000007 || code === 1000052) {
            Message({ message: msg, type: "error" });
        }
        return res;
    },
    (error) => {
        closeLoading();
        Message({
            message: error.msg || "網絡異常",
            type: "error",
            duration: 5 * 1000,
        });
        return Promise.reject(error);
    }
);
export default window.service || request;
