<template>
    <div>
        <div class="title">
            <div class="size-guide">{{ $t("尺码指南") }}</div>
            <div class="size-item">{{ goods?.brandName }}</div>
        </div>
        <i class="el-icon-close close close-hover pc-container" @click="$emit('close')" />
        <div class="flex" style="margin-top: 2.5rem">
            <el-image :src="goods?.productImg" class="product-img" fit="contain" lazy />
            <div class="product-info">
                <div>
                    {{ goods?.productTitle }}
                </div>
                <div>
                    {{ goods?.categoryName }}
                </div>
            </div>
        </div>
        <el-tabs v-model="activeName" class="tabs">
            <el-tab-pane :label="$t('尺码对照表')" name="1" class="sizeTablePane">
                <table v-if="getSizeTitle.length" class="sizeTable">
                    <tr>
                        <th v-for="(item, key) in getSizeTitle" :key="key">{{ item }}</th>
                    </tr>
                    <tr v-for="(sizeValue, index) in getSizeValue" :key="index">
                        <td v-for="(val, i) in sizeValue" :key="i">{{ val.value }}</td>
                    </tr>
                </table>
                <p v-else style="text-align: center; margin-bottom: 2rem">
                    {{ $t("暂无数据") }}
                </p>
                <p class="notice">{{ $t("注意：由于不同品牌测量尺码的方法可能稍有差异，因此该指南仅供参考使用。") }}</p>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import data from "@/views/product/productDetail/data";

export default {
    name: "SizeDialog",
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        goods: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            activeName: "1",
            country: "EU",
        };
    },
    computed: {
        data() {
            return data;
        },
        getSizeTitle() {
            let sizeTitle = [];
            if (this.goods.sizeData) {
                const sizeData = JSON.parse(this.goods.sizeData);
                if (sizeData.length) {
                    sizeTitle = sizeData[0].map((item) => item.sizeGroupName);
                }
            }
            return sizeTitle;
        },
        getSizeValue() {
            return JSON.parse(this.goods.sizeData);
        },
    },
    methods: {},
};
</script>
<style scoped lang="scss">
.dialog {
    display: flex;
    .title {
        font-size: 1.125rem;
        color: #000000;
        position: absolute;
        top: 1.0625rem;
    }

    .close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-size: 1.5rem;
        color: #000000;
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    color: #404040;

    th {
        padding: 20px;
    }
    td {
        text-align: center;
        padding: 12px;
    }

    tr:nth-child(even) {
        background-color: #f5f5f5;
    }
}
.hover {
    padding: 0 1.25rem;
    border-radius: 0;
}

.close-hover {
    padding: 0.5rem;
    cursor: pointer;
}
.close-hover:hover {
    background: #f5f5f5;
    border-radius: 0.5rem;
}

.size-guide {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
}

.size-item {
    font-size: 16px;
    margin-top: 10px;
}

.product-info div:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
}

.product-info div:nth-child(2) {
    font-size: 14px;
    margin-top: 8px;
}

.product-img {
    width: 120px;
    margin: 0 20px 20px 0px;
}

.select {
    width: 200px;
}

.notice {
    color: #999999;
    font-size: 14px;
    margin-top: 15px;
}

::v-deep .el-tabs__content {
    overflow: auto;
    width: 100%;
}
.sizeTable {
    width: max-content;
}
</style>
