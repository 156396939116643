/**
 * 表格时间格式化
 */
export function formatDate(cellValue) {
    if (cellValue == null || cellValue == "") return "";
    var date = new Date(cellValue);
    var year = date.getFullYear();
    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
    url = url == null ? window.location.href : url;
    const search = url.substring(url.lastIndexOf("?") + 1);
    const obj = {};
    const reg = /([^?&=]+)=([^?&=]*)/g;
    search.replace(reg, (rs, $1, $2) => {
        const name = decodeURIComponent($1);
        let val = decodeURIComponent($2);
        val = String(val);
        obj[name] = val;
        return rs;
    });
    return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
    // returns the byte length of an utf8 string
    let s = str.length;
    for (var i = str.length - 1; i >= 0; i--) {
        const code = str.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) s++;
        else if (code > 0x7ff && code <= 0xffff) s += 2;
        if (code >= 0xdc00 && code <= 0xdfff) i--;
    }
    return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
    const newArray = [];
    for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
            newArray.push(actual[i]);
        }
    }
    return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
    if (!json) return "";
    return cleanArray(
        Object.keys(json).map((key) => {
            if (json[key] === undefined) return "";
            return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
    ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = decodeURIComponent(url.split("?")[1]).replace(/\+/g, " ");
    if (!search) {
        return {};
    }
    const obj = {};
    const searchArr = search.split("&");
    searchArr.forEach((v) => {
        const index = v.indexOf("=");
        if (index !== -1) {
            const name = v.substring(0, index);
            const val = v.substring(index + 1, v.length);
            obj[name] = val;
        }
    });
    return obj;
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
    const div = document.createElement("div");
    div.innerHTML = val;
    return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
    if (typeof target !== "object") {
        target = {};
    }
    if (Array.isArray(source)) {
        return source.slice();
    }
    Object.keys(source).forEach((property) => {
        const sourceProperty = source[property];
        if (typeof sourceProperty === "object") {
            target[property] = objectMerge(target[property], sourceProperty);
        } else {
            target[property] = sourceProperty;
        }
    });
    return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
    if (!element || !className) {
        return;
    }
    let classString = element.className;
    const nameIndex = classString.indexOf(className);
    if (nameIndex === -1) {
        classString += "" + className;
    } else {
        classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
    }
    element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
    if (type === "start") {
        return new Date().getTime() - 3600 * 1000 * 24 * 90;
    } else {
        return new Date(new Date().toDateString());
    }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp;

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function (...args) {
        context = this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
    if (!source && typeof source !== "object") {
        throw new Error("error arguments", "deepClone");
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === "object") {
            targetObj[keys] = deepClone(source[keys]);
        } else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
    return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
    const timestamp = +new Date() + "";
    const randomNum = parseInt((1 + Math.random()) * 65536) + "";
    return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
    return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
    if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
        ele.className = ele.className.replace(reg, " ");
    }
}

export function makeMap(str, expectsLowerCase) {
    const map = Object.create(null);
    const list = str.split(",");
    for (let i = 0; i < list.length; i++) {
        map[list[i]] = true;
    }
    return expectsLowerCase ? (val) => map[val.toLowerCase()] : (val) => map[val];
}

export const exportDefault = "export default ";

export const beautifierConf = {
    html: {
        indent_size: "2",
        indent_char: " ",
        max_preserve_newlines: "-1",
        preserve_newlines: false,
        keep_array_indentation: false,
        break_chained_methods: false,
        indent_scripts: "separate",
        brace_style: "end-expand",
        space_before_conditional: true,
        unescape_strings: false,
        jslint_happy: false,
        end_with_newline: true,
        wrap_line_length: "110",
        indent_inner_html: true,
        comma_first: false,
        e4x: true,
        indent_empty_lines: true,
    },
    js: {
        indent_size: "2",
        indent_char: " ",
        max_preserve_newlines: "-1",
        preserve_newlines: false,
        keep_array_indentation: false,
        break_chained_methods: false,
        indent_scripts: "normal",
        brace_style: "end-expand",
        space_before_conditional: true,
        unescape_strings: false,
        jslint_happy: true,
        end_with_newline: true,
        wrap_line_length: "110",
        indent_inner_html: true,
        comma_first: false,
        e4x: true,
        indent_empty_lines: true,
    },
};

// 首字母大小
export function titleCase(str) {
    return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

// 下划转驼峰
export function camelCase(str) {
    return str.replace(/_[a-z]/g, (str1) => str1.substr(-1).toUpperCase());
}

export function isNumberStr(str) {
    return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}

// 获取分类名称, 多级拼接
// 入参 所有分类数据, 分类ID
export function getCategoryName(categoryList, categoryId) {
    if (categoryId == undefined) {
        return "";
    }
    let currentCategory = categoryList.find((category) => {
        return categoryId == category.categoryId;
    });
    if (currentCategory == undefined) {
        return "";
    }
    let categoryIdList = currentCategory.levelList.split("|");
    if (categoryIdList == 1) {
        return currentCategory.categoryName;
    }
    let parentName = getCategoryName(categoryList, categoryIdList[categoryIdList.length - 2]);
    return (parentName != "" ? parentName + "|" : parentName) + currentCategory.categoryName;
}
// 获取分类ID父级关系
export function getCategoryLevelList(categoryList, categoryId) {
    if (categoryId == undefined) {
        return [];
    }
    let currentCategory = categoryList.find((category) => {
        return categoryId == category.categoryId;
    });
    if (currentCategory == undefined) {
        return [];
    }
    return currentCategory.levelList.split("|");
}

export function clickDownload(query = "") {
    const loadDateTime = new Date();
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        if (query && query != "") {
            window.location = "famkools://" + query; //schema链接或者universal link
        }
        window.setTimeout(function () {
            //如果没有安装app,便会执行setTimeout跳转下载页
            const timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
                if (confirm("點擊確定前往App Store下載")) {
                    window.location = "https://apps.apple.com/us/app/famkools/id6473601571?l=zh-Hans-CN"; //ios下载地址
                }
            } else {
                window.close();
            }
        }, 500);
    } else if (navigator.userAgent.match(/android/i)) {
        if (query && query != "") {
            window.location = "famkools://" + query; //schema链接或者universal link
        }
        window.setTimeout(function () {
            //如果没有安装app,便会执行setTimeout跳转下载页
            const timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
                if (confirm("點擊確定下載Famkools APK")) {
                    window.location = "https://play.google.com/store/apps/details?id=com.famkools.android"; //下载地址
                }
            } else {
                window.close();
            }
        }, 500);
    }
}

export function distinguishApp() {
    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        return "ios";
    } else if (navigator.userAgent.match(/android/i)) {
        return "android";
    }
}

export /**
 * @param {date} time 订单时间（日期格式或者时间戳）
 * @param {number} num 返回几个时间单位，如：1周(num=1)，1周2天(num=2),1周2天1小时(num=3)
 */
function formatTime(time, num = 1) {
    let timeArr = [
        365 * 24 * 60 * 60 * 1000,
        30 * 24 * 60 * 60 * 1000,
        7 * 24 * 60 * 60 * 1000,
        24 * 60 * 60 * 1000,
        60 * 60 * 1000,
        60 * 1000,
        1000,
    ];
    let unit = ["年", "个月", "周", "天", "小时", "分钟", "秒钟"];
    let timeDiff = Date.now() - new Date(time).getTime();
    if (timeDiff <= 0) return "刚刚";
    let tip = [];
    for (let i = 0; i < timeArr.length; i++) {
        if (timeDiff < timeArr[i]) continue;

        let temp = Math.floor(timeDiff / timeArr[i]);
        timeDiff = timeDiff % timeArr[i];

        if (temp > 0) {
            tip.push(temp + unit[i]);
        }

        if (tip.length >= num) break;
    }
    return tip.join("") + "前";
}

export function copyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
}
