const layout = {
    data() {
        return {};
    },
    created() {
        if (this.isPhone() && !this.$route.path.includes("statement")) {
            if (this.$route.path === "/payment/check/paypal") {
                const token = this.$route.query.token;
                const payerId = this.$route.query.PayerID;
                if (token && payerId) {
                    this.$router.push(`/payment/check/paypal/h5?token=${token}&PayerID=${payerId}`);
                } else {
                    window.webkit && window.webkit.messageHandlers.closePaypal.postMessage("failed");
                }
            } else if (this.$route.path === "/findList") {
                const socialId = this.$route.query.socialId;
                if (socialId) {
                    this.$router.push(`/postshare?socialId=${socialId}`);
                }
            } else if (this.$route.path === "/product/detail") {
                const productId = this.$route.query.id;
                window.location.href = `http://m.famkools.com/#/pages/product/detail?productId=${productId}`;
            } else {
                /* const inviteCode = this.$route.query.inviteCode ? `?inviteCode=${this.$route.query.inviteCode}` : "";
                this.$router.push("/downloadApp" + inviteCode);*/
                window.location.href = `http://m.famkools.com/#/pages/productList/list?headerType=4`;
            }
        }
    },
    mounted() {
        const arouse = this.$route.query.arouseLogin;
        if (arouse) {
            this.$store.dispatch("changeOpenState", true);
        }
    },
};
export default layout;
