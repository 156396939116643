<template>
    <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
        <card v-for="(item, index) in products" :key="index" :item="item" />
    </div>
</template>

<script>
import card from "@/views/product/productCart/index.vue";
export default {
    components: { card },
    props: {
        products: {
            type: Array,
            detail: [],
        },
    },
    data() {
        return {
            productList: [
                {
                    productTitle: "HERMES/爱马仕 Constance 19 康康 金扣Epsom牛皮单肩斜挎包 迷你女款 l2 Nate奶油白",
                    heart: false,
                    brandName: "HERMES/爱马仕",
                    series: "包袋/女士包袋/单肩包/斜挎包",
                    sellPrice: 101867,
                    productImg: require("@/assets/images/mock/1.jpg"),
                },
                {
                    productTitle: "HERMES/爱马仕 Herbag Zip 31 金扣 帆布拼牛皮 手提单肩斜挎包 女款 黑色/冰川白",
                    heart: false,
                    brandName: "HERMES/爱马仕",
                    series: "包袋/女士包袋/手包/手袋",
                    sellPrice: 19657,
                    productImg: require("@/assets/images/mock/2.jpg"),
                },
                {
                    productTitle:
                        "HERME/爱马仕Picotin Lock18金扣Clemence小公牛皮菜篮子水桶包单肩手提包女款08 Bleu Pale浅蓝色",
                    heart: false,
                    brandName: "HERMES/爱马仕",
                    series: "包袋/女士包袋/手包/手袋",
                    sellPrice: 27727,
                    productImg: require("@/assets/images/mock/3.jpg"),
                },
                {
                    productTitle: "CHANEL/香奈儿经典金扣 翻盖零钱包卡包 颗粒压花牛皮 女款 黑色",
                    heart: false,
                    brandName: "CHANEL/香奈儿",
                    series: "包袋/女士包袋/手包/手袋",
                    sellPrice: 4857,
                    productImg: require("@/assets/images/mock/4.jpg"),
                },
                {
                    productTitle: "CHANEL/香奈儿小金球CF经典菱格 金扣 羊皮口盖包 斜挎单肩包 迷你 女款黑色",
                    heart: false,
                    brandName: "CHANEL/香奈儿",
                    series: "包袋/女士包袋/手包/手袋",
                    sellPrice: 46837,
                    productImg: require("@/assets/images/mock/5.jpg"),
                },
                {
                    productTitle:
                        "ROLEX/劳力士 潜航者型系列无历黑自动机械机芯300米防水 男款 瑞表41mm 41mm 黑色表盘式 钢表壳式钢表带m124060-0001",
                    heart: false,
                    brandName: "ROLEX/劳力士",
                    series: "手表/瑞士手表/机械表",
                    sellPrice: 80607,
                    productImg: require("@/assets/images/mock/6.jpg"),
                },
                {
                    productTitle: "Lamer/海蓝之谜 精粹水精华面霜挚爱尊宠六件套装 补水保湿紧致面部抗衰老 神仙修护套装",
                    heart: false,
                    brandName: "Lamer/海蓝之谜",
                    series: "美妆个护/彩妆/香水/美妆工具/BB霜",
                    sellPrice: 4987,
                    productImg: require("@/assets/images/mock/7.jpg"),
                },
                {
                    productTitle: "CHANEL/香奈儿SS22 闪亮斜纹软呢无帽外套女款黑色",
                    heart: true,
                    brandName: "CHANEL/香奈儿",
                    series: "女装/上衣/夹克",
                    sellPrice: 41957,
                    productImg: require("@/assets/images/mock/8.jpg"),
                },
            ],
        };
    },
};
</script>
