const communityRouter = {
    path: "/find",
    name: "Find",
    redirect: "/findList",
    component: () => import("@/views/find/index.vue"),
    // meta: { navbar: false },
    children: [
        {
            path: "/findList",
            name: "FindList",
            component: () => import("@/views/find/findList/index.vue"),
            meta: { find: true, title: "社區" },
        },
        {
            path: "/findPage",
            name: "FindPage",
            component: () => import("@/views/find/findPage/index.vue"),
            meta: { find: true, title: "我的社區" },
        },
    ],
};

export default communityRouter;
