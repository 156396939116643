<template>
    <div>
        <div v-if="total" class="recentlyBox">
            <table width="100%" align="center">
                <tr v-for="(item, index) in data" :key="index">
                    <td class="sex">
                        <span style="display: flex">
                            <span>
                                <img v-if="item.sex === 0" class="sexType" :src="require('@/assets/icons/man.png')" />
                                <img v-if="item.sex === 1" class="sexType" :src="require('@/assets/icons/woman.png')" />
                                <img
                                    v-if="item.sex === 2"
                                    class="sexType"
                                    :src="require('@/assets/icons/nosex.svg')"
                                    style="visibility: hidden"
                                />
                            </span>
                            <span class="name">{{ item.nickName }}</span>
                        </span>
                    </td>
                    <td>{{ item.address.split(" ").pop() }}</td>
                    <td>{{ item.contact }}</td>
                    <td class="sku">{{ item.skuName }}</td>
                    <td>{{ item.amount | currencyHk }}</td>
                    <td>
                        <span v-if="item?.sellPrice > item?.amount">
                            {{ (((item?.sellPrice - item?.amount) / item?.sellPrice) * 100).toFixed() }}% OFF
                        </span>
                    </td>
                    <td>{{ item.couponRemark }}</td>
                    <td>{{ item.giftRemark }}</td>
                    <td>{{ formatTime(item.createTime, 1) }}</td>
                </tr>
            </table>
        </div>
        <p v-else style="text-align: center">{{ $t("暂无数据") }}</p>
        <div v-if="total > 10" class="pagination">
            <el-pagination
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="total"
                current-page.sync="1"
                class="pagination"
                @current-change="recentlyOrder"
            />
        </div>
    </div>
</template>

<script>
import { getRecentlyOrder } from "@/api/goods";
import { formatTime } from "@/utils";

export default {
    data() {
        return {
            total: 0,
            data: [],
            pageSize: 10,
            pageNum: 1,
            leftSlice: 0,
            rightSlice: 0,
        };
    },
    mounted() {
        this.recentlyOrder();
    },
    methods: {
        formatTime,
        recentlyOrder(v) {
            const productId = this.$route.query.id;
            const parameter = { productId, pageSize: this.pageSize, pageNum: v, platform: "pc" };
            getRecentlyOrder(parameter).then((val) => {
                if (val.code === 200) {
                    this.total = val.data.total;
                    this.data = val.data.rows;
                    this.leftSlice = Math.ceil(val.data.rows.length / 2);
                    this.$emit("setTotal", this.total);
                }
            });
        },
    },
};
</script>

<style scoped>
.sex {
    width: 100px;
}

table tr td {
    padding-top: 20px;
}

.recentlyBox {
    display: flex;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.buy-left div {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    span {
        display: block;
        color: #333333;
    }
}

.name {
    width: 60px;
}

.sku {
    max-width: 250px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sexType {
    width: 25px;
}
</style>
