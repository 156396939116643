export const currency = (value = 0, num = 0, init = "￥") => {
    return (
        init +
        Number(value)
            .toFixed(num)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
    );
};

export const currencyPoints = (value = 0, num = 0, init = "") => {
    return (
        init +
        Number(value)
            .toFixed(num)
            .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
    );
};

export const merchantCode = (merchantId) => {
    return `F: ${merchantCodeComputed(merchantId)}`;
};

const merchantCodeComputed = (id) => {
    return id + 135791113 * 24681012;
};

export const dataFormat = (date) => {
    return date.split(" ")[0];
};

export const currencyHk = (price) => {
    return price === "-" || !price ? "HK$ -" : `HK$${Number(price).toLocaleString()}`;
};
