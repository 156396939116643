import request from "@/utils/request";

/**
 *
 */
export const postNewThread = (data) =>
    request({
        url: `/social/social/create`,
        method: "POST",
        data,
    });

/**
 * 社区列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getSocialList = (params) =>
    request({
        url: `/social/social/untoken/socialList`,
        method: "get",
        params,
    });

/**
 * 收藏
 * @returns {*}
 * @param data
 */
export const setSocialCollect = (data) =>
    request({
        url: `/social/collect/socialCollect`,
        method: "post",
        data,
    });

export const setSocialLike = (data) =>
    request({
        url: `/social/praise/socialLike`,
        method: "post",
        data,
    });

/**
 * 获取帖子资源
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getResourcesList = (params) =>
    request({
        url: `/social/resources/untoken/resourcesList`,
        method: "get",
        params,
    });

export const getResourcesListById = (socialId) =>
    request({
        url: `/social/resources/untoken/getBySocialId/${socialId}`,
        method: "get",
    });

/**
 * 社区个人信息
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getUserInfo = (params) =>
    request({
        url: `/system/user/untoken/getUserInfo`,
        method: "get",
        params,
    });

export const getFolllow = (params) =>
    request({
        url: `/system/user/follow/user/getFolllow`,
        method: "get",
        params,
    });

export const setFolllowStatus = (userId) =>
    request({
        url: `/system/user/follow/user/${userId}`,
        method: "get",
    });

/**
 * 关注列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getFolllowList = (params) =>
    request({
        url: `/system/user/untoken/follow/list`,
        method: "get",
        params,
    });

/**
 * 粉丝列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getFansList = (params) =>
    request({
        url: `/system/user/untoken/fans/list`,
        method: "get",
        params,
    });

/**
 * 好友列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getFriendList = (params) =>
    request({
        url: `/system/user/untoken/friend/list`,
        method: "get",
        params,
    });

export const getSocialListByIds = (data) =>
    request({
        url: `/social/social/untoken/listByIds`,
        method: "post",
        data,
    });

export const getSocialTypeList = (params) =>
    request({
        url: `/social/socialType/untoken/socialTypeList`,
        method: "get",
        params,
    });

export const getTranslate = (params) =>
    request({
        url: `/seller/translate/untoken/getTranslate`,
        method: "get",
        params,
    });

export const socialBrowse = (data) =>
    request({
        url: `/social/browseSocial/socialBrowse`,
        method: "post",
        data,
    });

/**
 * 查看某条帖子的评论
 */
export const getSocialCommentList = (params) =>
    request({
        url: "/social/untoken/comment/commentList",
        method: "GET",
        params,
    });

/**
 * 点赞评论或取消点赞
 */
export const praiseComment = (params) =>
    request({
        url: "/social/untoken/comment/praise",
        method: "GET",
        params,
    });

/**
 * 帖子下新增评论
 */
export function postSocialComment(data) {
    return request({
        url: "/social/untoken/comment",
        method: "POST",
        data,
    });
}
