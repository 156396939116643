const data = {
    newProduct: [
        {
            img: require("@/assets/images/women/xinpin1.png"),
            name: "Louis\nVuitton",
            heart: false,
        },
        {
            img: require("@/assets/images/women/xinpin2.png"),
            name: "GUCCI",
            heart: true,
        },
        {
            img: require("@/assets/images/women/xinpin3.png"),
            name: "Hermès",
            heart: false,
        },
        {
            img: require("@/assets/images/women/xinpin4.png"),
            name: "GUCCI Blondie",
            heart: true,
        },
        {
            img: require("@/assets/images/women/xinpin4.png"),
            name: "GUCCI Blondie",
            heart: true,
        },
    ],
    women3: {
        img: require("@/assets/images/women/women3.png"),
        text1: "A sparkling ring",
        text2: "Interlocking G Jewelry Collection\nand more",
    },
    women4: {
        img: require("@/assets/images/women/women4.png"),
        text1: "A sparkling ring",
        text2: "Interlocking G Jewelry Collection\nand more",
    },
    women5: {
        img: require("@/assets/images/women/women5.png"),
        text1: "A sparkling ring",
        text2: "Interlocking G Jewelry Collection\nand more",
    },
    sunlight: [
        {
            img: require("@/assets/images/women/sun1.png"),
            name: "Louis\nVuitton",
            heart: false,
            discount: true,
        },
        {
            img: require("@/assets/images/women/sun2.png"),
            name: "GUCCI",
            heart: false,
            discount: false,
        },
        {
            img: require("@/assets/images/women/sun3.png"),
            name: "Hermès",
            heart: true,
            discount: true,
        },
        {
            img: require("@/assets/images/women/sun4.png"),
            name: "GUCCI Blondie",
            heart: false,
            discount: false,
        },
    ],

    bagAndShoes: [
        {
            img: require("@/assets/images/women/bag.png"),
            title: "袋袋相传",
            subTitle: "集经典于一身，只要爱上就不会被改变",
            links: "选购包袋",
        },
        {
            img: require("@/assets/images/women/shoe.png"),
            title: "足尖风度",
            subTitle: "百穿不厌的杰作，绽放每一步的迷人姿态",
            links: "选购鞋履",
        },
    ],

    /*
     * 至臻精选
     * */
    perfects: [
        {
            img: require("@/assets/images/women/zz1.png"),
            name: "Louis\nVuitton",
        },
        {
            img: require("@/assets/images/women/zz2.png"),
            name: "GUCCI",
        },
        {
            img: require("@/assets/images/women/zz3.png"),
            name: "Hermès",
        },
        {
            img: require("@/assets/images/women/zz4.png"),
            name: "GUCCI Blondie",
        },
        {
            img: require("@/assets/images/women/zz5.png"),
            name: "GUCCI",
        },
        {
            img: require("@/assets/images/women/zz6.png"),
            name: "Hermès",
        },
    ],
    bottomMake: [
        {
            img: require("@/assets/images/women/b1.png"),
            title: "开启新的旅程",
            subTitle: "经过了漫长的等候，梦想是梦想，我还是\n一个我",
            links: "选购假日精选",
        },
        {
            img: require("@/assets/images/women/b2.png"),
            title: "霓裳盛事",
            subTitle: "那阳光碎裂在熟悉场景好安静",
            links: "选购精选设计",
        },
        {
            img: require("@/assets/images/women/b3.png"),
            title: "全新登陆：Dabcdefg",
            subTitle: "我是一只咸鱼不想承认也不能否认",
            links: "选购此系列",
        },
    ],
    guarantee: [
        {
            icon: require("@/assets/icons/home1.png"),
            name: "全新正品",
            dec: "平台所售卖的商品均通过严格的商家筛选、商品及质量检测，确保为全新正品",
            links: "/",
        },
        {
            icon: require("@/assets/icons/home2.png"),
            name: "專業鑒別",
            dec: "平台發出的所有商品，均逐件鑒別並出具鑒別證書、添加防偽扣",
            links: "/",
        },
        {
            icon: require("@/assets/icons/home3.png"),
            name: "運費全免",
            dec: "平台出售的所有商品，均享受免費郵寄服務，同時支持用戶到門店自提",
            links: "",
        },
        {
            icon: require("@/assets/icons/home4.png"),
            name: "保養服務",
            dec: "平台出售的包袋、手錶，均提供1次/年保養服務，提供全方位服务流程並發出相關檢測报告",
            links: "",
        },
    ],
};

export default data;
