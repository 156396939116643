const getters = {
    subscriberVisible: (state) => state.web.subscriberVisible,
    navbar: (state) => state.web.navbar,
    goodList: (state) => state.web.goodList,
    dict: (state) => state.dict.dict,
    find: (state) => state.web.find,
    token: (state) => state.user.token,
    name: (state) => state.user.name,
    loading: (state) => state.loading.loading,
    type: (state) => state.loading.type,
    cartNums: (state) => state.cart.cartNums,
    openLogin: (state) => state.app.openLogin,
    openLoginType: (state) => state.app.openLoginType,
    notPayment: (state) => state.app.notPayment,
    collectNums: (state) => state.product.collectNums,
    cartList: (state) => state.cart.cartList,
    roleData: (state) => state.user.roleData,
    roles: (state) => state.user.roles,
    userCouponList: (state) => state.user.userCouponList,
    userCouponListIsNull: (state) => state.user.userCouponListIsNull,
    userServiceList: (state) => state.user.userServiceList,
    userServiceListIsNull: (state) => state.user.userServiceListIsNull,
    userPointsInfo: (state) => state.user.userPointsInfo,
    dropDownMenuData: (state) => state.product.dropDownMenuData,
    firstLevelNavigation: (state) => state.product.first_level_navigation,
    couponStatus: (state) => state.product.couponStatus,
    homeList: (state) => state.home.homeList,
    showTaxPopup: (state) => state.user.showTaxPopup,
    taxPopupValidate: (state) => state.user.taxPopupValidate,
    currentRole: (state) => {
        let role = "";
        if (state.user.role.includes("role.registrar")) {
            role = "registrar";
        }
        if (state.user.role.includes("role.approve")) {
            role = "approve";
        }
        if (state.user.role.includes("role.admin")) {
            role = "admin";
        }
        return state.user.currentRole || role;
    },
    userId: (state) => state.user.userId,
    userInfo: (state) => state.user.userInfo,
};
export default getters;
