import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import getters from "./getters";
import loading from "./modules/loading";
import web from "./modules/web";
import dict from "./modules/dict";
import cart from "@/store/modules/cart";
import app from "@/store/modules/app";
import product from "@/store/modules/product";
import home from "@/store/modules/home";
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        web,
        dict,
        user,
        loading,
        cart,
        app,
        product,
        home,
    },
    getters,
});

export default store;
