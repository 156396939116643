import request from "@/utils/request";

export function addCart(data) {
    return request({
        url: "/order/cart/untoken/add",
        method: "post",
        data: data,
    });
}

export function getCartList(data) {
    return request({
        url: "/order/cart/query",
        method: "post",
        data: data,
    });
}

// 获取订单列表
export function getOrderList(data) {
    return request({
        url: "/order/order/list/",
        method: "post",
        data: data,
    });
}

// json-server
// export function getOrderList(){
//     return request({
//         url:'http://localhost:3000/good',
//         method:'get',
//     })
// }

export function deleteCartProduct(data) {
    return request({
        url: "/order/cart/delete",
        method: "post",
        data: data,
    });
}

export function updateCartProduct(data) {
    return request({
        url: "/order/cart/update",
        method: "post",
        data: data,
    });
}

export function createOrder(data) {
    return request({
        url: "/order/order/create",
        method: "post",
        data: data,
    });
}

// 获取订单列表信息
export function getOrderDetail(data) {
    return request({
        url: "order/order/detail",
        method: "post",
        data: data,
    });
}

export function getPaymentInfo(data) {
    return request({
        url: "/pay/payment/create",
        method: "post",
        data: data,
    });
}

export function updateCartSelectStatus(data) {
    return request({
        url: "/order/cart/updateSelect",
        method: "post",
        data: data,
    });
}

export function getMiniCartInfo(data) {
    return request({
        url: "/order/cart/miniCart",
        method: "post",
        data: data,
    });
}

/**
 * 订单结算 提交订单前计算结算金额
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getOrderSettle(data) {
    return request({
        url: "/order/cart/orderSettle",
        method: "post",
        data: data,
    });
}

export function getQueryUser(data) {
    return request({
        url: "/coupon/coupon/queryUser",
        method: "post",
        data: data,
    });
}

export function getServiceDetail(data) {
    return request({
        url: "coupon/coupon/couponDetail",
        method: "POST",
        data,
    });
}

export function getPoints(data) {
    return request({
        url: "/order/points/query",
        method: "post",
        data: data,
    });
}

export function confirmGet(data) {
    return request({
        url: "/order/order/confirmReceipt",
        method: "post",
        data: data,
    });
}
export function captureOrderStatus(data) {
    return request({
        url: "/pay/payment/untoken/captureOrder",
        method: "post",
        data: data,
    });
}

export function billUpload(data) {
    return request({
        url: "/order/bill/upload",
        method: "post",
        data,
    });
}
