import router from "./router";
import store from "./store";
import History from "@/utils/history";

router.beforeEach((to, from, next) => {
    if (to.meta.auth) {
        if (store.getters.token) {
            next();
        } else {
            next({
                path: "/",
                query: {
                    redirect: to.path,
                },
            });
        }
    } else {
        next();
    }
});
router.afterEach((to) => {
    if (router.isBack) {
        History.pop();
        router.isBack = false;
        router.transitionName = "route-back";
    } else {
        History.push(to.path);
        router.transitionName = "route-forward";
    }
});
