<template>
    <div>
        <el-dialog :visible.sync="dialogTableVisible" append-to-body width="30rem" @close="closeTable">
            <div class="payment-text">
                <p class="title">{{ $t("您有订单未完成支付") }}</p>
                <p class="text">{{ $t("请至个人中心-我的订单-待付款完成支付") }}</p>
                <div style="text-align: center">
                    <el-button type="primary" class="check-button" @click="quickGo">
                        <span>{{ $t("立即前往") }}</span>
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dialogTableVisible: false,
        };
    },
    computed: {
        openPayStatus() {
            return this.$store.getters.notPayment;
        },
    },
    watch: {
        openPayStatus(newValue) {
            if (newValue) {
                setTimeout(() => {
                    this.dialogTableVisible = true;
                }, 500);
            }
        },
    },
    methods: {
        closeTable() {
            this.dialogTableVisible = false;
            this.$store.dispatch("setPaymentStatus", false);
        },
        quickGo() {
            this.closeTable();
            this.$router.push("/account/order");
        },
    },
};
</script>
<style scoped lang="scss">
.payment-text {
    text-align: center;
    .title {
        font-weight: bold;
        font-size: 17px;
        color: black;
    }
    .text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
    }
}

.check-button {
    width: 280px;
    height: 40px;
    margin-top: 50px;
    span {
        font-size: 14px;
    }
}
</style>
