const sha1 = require("js-sha1");
function randomNum(num) {
    const ary = [];
    for (let i = 0; i < num; i++) {
        ary[i] = i + 1;
    }
    ary.sort(function () {
        return 0.5 - Math.random();
    });
    return ary;
}

export const setCustomerUdeskInfo = (data) => {
    let nonce = parseInt(randomNum(10).join(""));
    const timestamp = Date.parse(new Date());
    let sign_str = `nonce=${nonce}&timestamp=${timestamp}&web_token=${data.userId}&cd3ed502d4623e28833f584dc08c1db2`;
    sign_str = sha1(sign_str);
    sign_str = sign_str.toUpperCase();
    const udParams = {
        customer: {
            nonce: nonce,
            signature: sign_str,
            timestamp: timestamp,
            web_token: `${data.userId}`,
            c_name: data.userName,
        },
    };
    window.ud(udParams);
};
