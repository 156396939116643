<template>
    <div
        id="notice-box"
        class="ad pointer notice-bold"
        :style="{ color: list[index].color, background: list[index].background }"
        @click="to"
    >
        {{ list[index].content }}
        <span v-if="list[index].slot == 'register'" class="register-btn" @click="onRegister">{{ $t("立即注册") }}</span>
        <!-- <img
            v-if="!getToken()"
            class="register-gif"
            :src="require('@/assets/images/register-benefits.gif')"
            alt=""
            @click.stop="onRegister"
        /> -->
        <div v-if="!getToken()" class="register-gif">{{ $t("注册领取HK$2000优惠券") }}</div>
        <div v-if="false" class="dialog-register-benefits" :style="{ borderRadius: '4px' }">
            <div class="main">
                <i class="el-icon-close bnt-close" @click="show = false" />
                <div class="left-wrapper">
                    <div class="title-box">
                        <span class="title">{{ $t("限时新人福利") }}</span>
                        <span class="sub-title">{{ $t("限时优惠，注册即享") }}</span>
                    </div>
                    <div class="coupon-box">
                        <img :src="require('@/assets/images/limited.png')" alt="" />
                        <div class="price"><span>HK$</span>1000</div>
                        <div class="text">{{ $t("限时专享券") }}</div>
                    </div>
                </div>
                <div class="btn-box">
                    <div class="btn-get" @click="onGetCoupon">
                        <span>{{ $t("立即领取") }}</span>
                    </div>
                    <div class="tip">{{ $t("注册领取更多福利") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AD",
    data() {
        return {
            list: [
                {
                    content: this.$t("现在注册送新人专属HK$2000新人礼"),
                    color: "#ffffff",
                    background: "#222222",
                    link: "",
                    slot: "register",
                },
                {
                    content: this.$t("Happy Sharing! 立即落单获赠Hermès身体护理套装"),
                    color: "rgb(16, 76, 61)",
                    background: "#F2F2F2",
                    link: "",
                },
                {
                    content: this.$t(
                        "平台所售卖的商品均通过严格的商家筛选、商品及质量检测，确保为全新正品。平台出售的包袋、手表，均提供1次/年保养服务。"
                    ),
                    color: "#ffffff",
                    background: "#222222",
                    link: "/statement/platformMind",
                },
            ],
            index: 0,
            adWidth: "100%",
            show: false,
            isAlreadyShow: false,
        };
    },
    computed: {
        topBanner() {
            return this.$store.getters.homeList.topBanner;
        },
    },
    watch: {
        "topBanner"() {
            // 先暂时注释
            /*            const bannerText = JSON.parse(bannerObject.platformIntelligence);
      this.list = [
          {
              content: bannerText[0].desc,
              color: "rgb(16, 76, 61)",
              background: "#F2F2F2",
          },
          {
              content: bannerText[1].desc,
              color: "#ffffff",
              background: "#222222",
          },
      ];*/
        },
        "$store.getters.token": {
            immediate: true,
            handler(val) {
                if (!val) {
                    window.addEventListener("scroll", this.onScroll);
                } else {
                    window.removeEventListener("scroll", this.onScroll);
                    this.index = 0;
                    this.list.splice(0, 1);
                }
            },
        },
    },
    mounted() {
        this.changeAd();
        if (this.isMobile()) {
            this.$nextTick(() => {
                this.adWidth = "1280px";
            });
        } else {
            this.adWidth = "100%";
        }
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        // 定时器循环切换广告
        changeAd() {
            setInterval(() => {
                this.index = this.index === this.list.length - 1 ? 0 : this.index + 1;
            }, 4000);
        },
        to() {
            const link = this.list[this.index].link;
            if (link) {
                this.$router.push(link);
            }
        },
        onRegister() {
            this.$store.dispatch("changeOpenStateType", "register");
            this.$store.dispatch("changeOpenState", true);
        },
        onGetCoupon() {
            this.show = false;
            setTimeout(() => {
                this.onRegister();
            }, 300);
        },
        onScroll() {
            if (this.isAlreadyShow) {
                window.removeEventListener("scroll", this.onScroll);
                return;
            }
            this.show = true;
            this.isAlreadyShow = true;
        },
    },
};
</script>

<style scoped lang="scss">
$ad-width: v-bind(adWidth);
.ad {
    width: $ad-width;
    height: 32px;
    background-color: #f2f2f2;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    z-index: 10;
}

.notice-bold {
    font-weight: bold;
}
.register-btn {
    margin-left: 19px;
    padding-bottom: 2px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}
// .register-gif {
//     position: absolute;
//     top: 10px;
//     right: 120px;
//     width: 172px;
//     z-index: 999;
// }
@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
.register-gif {
    position: absolute;
    top: 10px;
    right: 120px;
    width: 172px;
    border-radius: 54px;
    background: linear-gradient(106deg, #e2c69a 0%, #ffe9c9 47.55%, #d5a96d 100%);
    z-index: 999;
    color: #643f04;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 10px;
    font-style: normal;
    font-weight: bold;
    line-height: 12px;
    padding: 8px 20px;
    box-sizing: border-box;
    animation: scaleAnimation 1s linear infinite;
}
.dialog-register-benefits {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 679px;
    background: linear-gradient(106deg, #e2c69a 0%, #ffe9c9 47.55%, #d5a96d 100%);
    box-sizing: border-box;
    padding: 30px 33px;
    cursor: auto;
    .bnt-close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        font-size: 20px;
        font-weight: 600;
    }
    .main {
        display: flex;
        .title-box {
            margin-left: 7px;
            display: flex;
            align-items: baseline;
            .title {
                color: #643f04;
                font-size: 28px;
                font-weight: 500;
                line-height: normal;
            }
            .sub-title {
                color: #1a1a1a;
                font-size: 14px;
                font-weight: 500;
                margin-left: 16px;
            }
        }
        .coupon-box {
            width: 330px;
            height: 68px;
            margin-top: 19px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                display: block;
            }
            .price {
                position: absolute;
                top: 50%;
                left: 20px;
                transform: translateY(-50%);
                color: #000;
                font-size: 26px;
                font-weight: bold;
                span {
                    font-size: 14px;
                }
            }
            .text {
                position: absolute;
                top: 50%;
                left: 160px;
                transform: translateY(-50%);
                color: #000;
                font-size: 14px;
                font-weight: bold;
                width: 140px;
                text-align: left;
                line-height: 20px;
            }
        }
        .btn-box {
            margin-left: 60px;
            margin-top: 33px;
            width: 191px;
            text-align: center;
            .btn-get {
                width: 191px;
                height: 48px;
                border-radius: 240px;
                background: #000;
                color: #fff;
                text-align: center;
                font-size: 20px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .tip {
                color: #1a1a1a;
                font-size: 14px;
                font-weight: 500;
                margin-top: 8px;
            }
        }
    }
}
</style>
