<template>
    <div class="app">
        <div v-show="showLottery" v-if="false" class="lotteryEntry pc-container">
            <i
                class="el-icon-circle-close"
                style="margin-right: 0.3rem; cursor: pointer; z-index: 999"
                @click="showLottery = false"
            />
            <!--            <img style="cursor: pointer" :src="require('@/assets/images/lottery/entry.gif')" @click="lotteryPage" />-->
            <img
                class="anima"
                style="cursor: pointer"
                :src="require('@/assets/images/lottery/redPacketRian.png')"
                @click="lotteryPage"
            />
        </div>
        <AD v-if="navbar && !find" class="pc-container" />
        <jf-sticky :trigger-top="navbar ? 32 : 0" style="z-index: 99">
            <app-header class="pc-container" />
        </jf-sticky>
        <app-main />
        <app-footer />
    </div>
</template>

<script>
import { AppMain, AppHeader, AppFooter, AD } from "./components";
import JfSticky from "@/components/JfSticky";
import layout from "@/mixins/layout";
export default {
    name: "Layout",
    components: {
        AppMain,
        AppHeader,
        AppFooter,
        AD,
        JfSticky,
    },
    mixins: [layout],
    data() {
        return {
            navbar: true,
            find: false,
            appWidth: "100%",
            showLottery: true,
        };
    },
    mounted() {
        if (this.isMobile() && !this.$route.path.includes("statement")) {
            this.$nextTick(() => {
                this.appWidth = "1280px";
            });
        } else {
            this.appWidth = "100%";
        }
    },
    methods: {
        lotteryPage() {
            if (this.getToken()) {
                this.$router.push("/redPacketRain");
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },
    },
};
</script>
<style scoped lang="scss">
$app-width: v-bind(appWidth);

.app {
    height: 100vh;
    width: $app-width;
    z-index: 0;
    position: relative;
}

.lotteryEntry {
    position: fixed;
    right: 30px;
    bottom: 160px;
    z-index: 99;
    .el-icon-circle-close {
        position: absolute;
        right: -12px;
        font-size: 18px;
    }
    img {
        width: 120px;
    }
}

.anima {
    animation-name: likes; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 1s; // 动画完成时间
}

@keyframes likes {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(0.85);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}
</style>
