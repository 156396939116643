import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
// Layout
import Layout from "@/layout";
import store from "@/store";
import accountRouter from "@/router/module/account";
import productRouter from "@/router/module/product";
import communityRouter from "@/router/module/community";
import checkoutRouter from "@/router/module/checkout";
import merchantRouter from "@/router/module/merchant";
import shippingRouter from "@/router/module/shipping";
import statementRouter from "@/router/module/statement";
import paymentRouter from "@/router/module/payment";
import activityRouter from "@/router/module/activity";
import talentRouter from "@/router/module/talent";
import { getShortLink } from "@/utils/auth";
import { postShortLink } from "@/api/home";

const routes = [
    {
        path: "/",
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                name: "Home",
                meta: { keepAlive: true, title: "首頁" },
                component: () => import("@/views/home/index.vue"),
            },
            {
                path: "/bags",
                name: "Bags",
                meta: { auth: false, title: "購物車" },
                component: () => import("@/views/bags/index.vue"),
            },
            {
                path: "/brandList",
                name: "BrandList",
                meta: { auth: true, title: "品牌頁" },
                component: () => import("@/views/brandList/index.vue"),
            },
            {
                path: "/collect",
                name: "Collect",
                meta: { auth: true, title: "心願單" },
                component: () => import("@/views/collect/index.vue"),
            },
            {
                path: "/payment/success",
                name: "success",
                meta: { title: "支付成功頁" },
                component: () => import("@/views/checkout/success.vue"),
            },
            {
                path: "/page/brand",
                name: "brand",
                meta: { title: "品牌頁" },
                component: () => import("@/views/product/productList/components/brandPage"),
            },
            {
                path: "/findDetail",
                name: "FindDetail",
                component: () => import("@/views/find/findDetail/detail.vue"),
                meta: { find: true, title: "社區詳情" },
            },
            ...paymentRouter,
            ...statementRouter,
            ...productRouter,
            accountRouter,
            communityRouter,
            shippingRouter,
            ...checkoutRouter,
        ],
    },
    ...talentRouter,
    ...activityRouter,
    ...merchantRouter,
    {
        path: "/payment/:orderCode/:price/:businessType",
        name: "payment",
        component: () => import("@/views/payment/payment.vue"),
    },
    {
        path: "/payment/check/bank",
        name: "bank",
        component: () => import("@/views/payment/bank.vue"),
    },
    {
        path: "/payment/fps",
        name: "fsp",
        component: () => import("@/views/payment/fps.vue"),
    },
    { path: "*", component: () => import("@/views/error-page/404.vue") },
];
// 全局前置守卫
const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition && to.meta.keepAlive) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});
router.beforeEach((to, from, next) => {
    if (getShortLink()) {
        postShortLink({ shortStr: getShortLink(), route: to.fullPath, source: "pc" }).then((item) => {
            console.log(item);
        });
    }

    if (from.path === "/product/list" && to.path === "/product/detail") {
        const { matched } = router.resolve(from.path).route;
        const componentInstance = matched[matched.length - 1].instances.default;
        const productListAllData = {
            brandPageInfo: componentInstance.brandPageInfo,
            categoryDescribe: componentInstance.categoryDescribe,
            products: componentInstance.products,
            categoryListTree: componentInstance.categoryListTree,
            level2SearchBoList: componentInstance.level2SearchBoList,
            isBrandPage: componentInstance.isBrandPage,
            goodListData: componentInstance.goodListData,
            params: componentInstance.params,
        };
        store.dispatch("web/setProductList", productListAllData);
    }
    next();
});

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});

export default router;
