<template>
    <div>
        <el-tabs v-model="activeName" class="tabs" @tab-click="setSizeStyle">
            <el-tab-pane :label="$t('商品详情')" name="1">
                <div class="good-dec space-between">
                    <div class="box-info" style="flex-shrink: 0">
                        <div class="title">
                            {{ goods?.productTitle }}
                        </div>
                        <div class="sub-title">{{ $t("商品编码") }}：{{ goods?.productId }}</div>
                        <div class="sub-des">
                            <p v-html="goods?.productDesc" />
                            <p v-if="goods?.articleNumber">{{ $t("商品货号") }}：{{ goods?.articleNumber }}</p>

                            <p v-for="(val, key) in goods?.descVoList" :key="key">
                                {{ val.descTitle }}: {{ val.descValue }}
                            </p>
                        </div>
                    </div>
                    <div class="model-images" style="width: 100%">
                        <el-carousel
                            v-if="skuSloganList.length"
                            :interval="3000"
                            arrow="always"
                            type="card"
                            class="model-carousel"
                            height="31rem"
                        >
                            <el-carousel-item v-for="(item, index) in skuSloganList" :key="index">
                                <el-image :key="item.skuId" :src="item.skuImg" fit="cover" class="model-image" />
                            </el-carousel-item>
                        </el-carousel>
                        <el-carousel
                            v-if="skuDetailList.length"
                            :interval="3000"
                            arrow="always"
                            type="card"
                            class="model-carousel"
                            height="31rem"
                        >
                            <el-carousel-item v-for="(item, index) in skuDetailList" :key="index">
                                <el-image :key="item.skuId" :src="item.skuImg" fit="cover" class="model-image" />
                            </el-carousel-item>
                        </el-carousel>
                        <div class="buyKnow">
                            <h3>{{ $t("购物需知") }}：</h3>
                            <p>·{{ $t("港澳免运：香港、澳门地区，均享免运服务，同时鼓励顾客到黄竹坑office自取") }}。</p>
                            <p>
                                ·{{
                                    $t(
                                        "全新正品保障：平台所售卖的商品均通过严格的商家筛选、商品及质量检测，确保为全新正品"
                                    )
                                }}。
                            </p>
                            <p>·{{ $t("Famkools购买的商品均为平行进口，是否有门店正单需以商品页面选择的为准") }}。</p>
                            <p>
                                ·{{
                                    $t(
                                        "香港及澳门地区2-4个工作天内平台出货。 其他地区因海外商品受报关等因素影响预计5-18个工作天内平台出货"
                                    )
                                }}。
                            </p>
                            <p>
                                ·{{
                                    $t(
                                        "不支持无理由退换货。 Famkools只受理商品本身质量问题的退货请求，如有商品质量问题退换货，可以联系客户服务专员处理"
                                    )
                                }}。
                            </p>
                            <h3 style="margin-top: 2rem">{{ $t("自提流程") }}：</h3>
                            <p>·{{ $t("下单时选择“门店自提”，并预留手机号码") }}。</p>
                            <p>
                                ·{{
                                    $t("Famkools准备货物之后，会根据您预留的手机号码进行提醒，请关注短讯或WhatsApp")
                                }}。
                            </p>
                            <p>·{{ $t("上门提货，出示订单及短信/WhatsApp的通知信息") }}。</p>
                            <p>·{{ $t("验货并签署自提确认单") }}。</p>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="`${$t('最近购买')} （${recentlyBuyTotal}）`" name="3">
                <RecentlyList @setTotal="setTotal" />
            </el-tab-pane>
        </el-tabs>
        <p class="recommend-title">{{ $t("热门推荐") }}</p>
        <recommend :products="products" />
    </div>
</template>

<script>
import dayjs from "dayjs";
import Recommend from "@/views/product/productDetail/components/recommend/cardBox.vue";
import RecentlyList from "@/views/product/productDetail/components/recentlyList/index.vue";
import { getGoodList } from "@/api/goods";
import { dataList } from "../../../mock/productList";

export default {
    name: "GoodDetail",
    components: { Recommend, RecentlyList },
    dataList() {
        return dataList;
    },
    props: {
        goods: {
            type: Object,
            default: () => {
                return {};
            },
        },
        skuDetailList: {
            type: Array,
            default: null,
        },
        skuSloganList: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            activeName: "1",
            recentlyBuyTotal: 0,
            products: [],
            dataList,
        };
    },
    computed: {
        // 配送时效
        standard() {
            return dayjs().add(9, "day").format("MM月DD日") + " - " + dayjs().add(17, "day").format("MM月DD日");
        },
        expressDelivery() {
            return dayjs().add(4, "day").format("MM月DD日") + " - " + dayjs().add(12, "day").format("MM月DD日");
        },
        srcList() {
            return this.skuDetailList.map((item) => item.skuImg);
        },
    },
    watch: {
        goods: function () {
            const params = {
                pageNum: 1,
                pageSize: 8,
                level1SearchBo: {
                    searchType: "1",
                    searchValue: this.goods.brandId,
                },
                orderByList: [{ orderByKey: "random" }],
            };
            getGoodList(params).then((value) => {
                this.products = value.data.list;
            });
        },
    },
    mounted() {},
    methods: {
        setTotal(val) {
            this.recentlyBuyTotal = val;
            this.$emit("setTotal", val);
        },
        setSizeStyle() {
            let that = this;
            let box = document.getElementsByClassName("ltr-1h5rh0");
            if (box) {
                box[0].style.display = "flex";
                box[0].style.justifyContent = "space-between";
            }
            let info = document.getElementsByClassName("ltr-182wjbq");
            if (info) {
                info[0].style.width = "30%";
            }
            let h4 = document.getElementsByClassName("ltr-2pfgen-Body-BodyBold");
            if (h4) {
                h4[0].style.marginBottom = "10px";
                for (let i = 1; i < h4.length; i++) {
                    h4[i].style.marginBottom = "10px";
                    h4[i].style.marginTop = "20px";
                }
            }
            let switcher = document.getElementsByTagName("fieldset");
            if (switcher) {
                for (let i = 0; i < switcher.length; i++) {
                    switcher[i].style.display = "none";
                }
            }
            let tip = document.getElementsByClassName("ltr-4y8w0i-Body");
            if (tip) {
                tip[0].style.marginTop = "10px";
            }

            let button = document.getElementsByClassName("ltr-1kukvql-Body");
            if (button) {
                button[0].addEventListener("click", function () {
                    that.$parent.$refs.spec.visibleDialog = true;
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.recommend-title {
    color: #1a1a1a;
    font-weight: 600;
    font-size: 22px;
    position: relative;
    padding: 60px 0 10px 0;
    z-index: 1;
}

.model-carousel {
    width: 98%;
    margin-left: 2%;
}

.model-images {
    margin-top: 20px;
}

.model-image {
    margin-left: 20px;
}

.box-info {
    width: 400px;
}

.good-dec {
    color: #434343;
    align-items: flex-start;
}

.margin-top-1 {
    margin-top: 1.875rem;
}

.margin-top-2 {
    margin-top: 1.25rem;
}

.margin-top-3 {
    margin-top: 0.3125rem;
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
}

::v-deep button {
    border: none !important;
    background: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}

.title {
    margin-top: 20px;
    font-size: 16px;
    color: #222222;
    font-weight: bold;
}

.sub-title {
    margin-top: 20px;
    font-size: 14px;
    color: #333333;
}

.sub-des {
    margin-top: 20px;
    color: #333333;

    p {
        margin-top: 20px;
        font-weight: 400;
    }
}

.buyKnow {
    padding-left: 20px;
}
</style>
