<template>
    <div class="jf-checkbox pointer" style="margin-right: 5px" @click="$emit('update', !$attrs.value)">
        <svg-icon
            :style="{ fontSize: size }"
            class="checkbox-color"
            :class="{ 'checkbox-error-color': $attrs.value === false && errorText }"
            :icon-class="$attrs.value ? 'checkbox' : 'checkbox-non'"
        />
        <div v-if="$attrs.value === false && errorText" class="jf-checkbox__error">
            {{ showText ? errorText : "" }}
        </div>
    </div>
</template>

<script>
export default {
    // 组件的JavaScript代码
    model: {
        prop: "value",
        event: "update",
    },
    props: {
        size: {
            type: String,
            default: "20px",
        },
        errorText: {
            type: String,
            default: "",
        },
        showText: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
.jf-checkbox {
    position: relative;
    .jf-checkbox__error {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4px;
        position: absolute;
        top: 80%;
        left: 0;
        white-space: nowrap;
        transition: 1s;
    }
    .checkbox-color {
        fill: #000000;
    }
    .checkbox-error-color {
        fill: #f00;
    }
}
</style>
