<template>
    <div>
        <el-form ref="forgetForm" :model="forgetForm" :rules="forgetFormRules">
            <el-form-item v-if="forgetInputType === 'email'" prop="email">
                <div class="form-title">
                    {{ forgetInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input
                        v-if="forgetInputType === 'email'"
                        v-model="forgetForm.email"
                        type="text"
                        class="password"
                        :placeholder="$t('请输入电邮')"
                    />
                </div>
            </el-form-item>

            <el-form-item v-else prop="phonenumber">
                <div class="form-title">
                    {{ forgetInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input v-model="forgetForm.phonenumber" :placeholder="$t('请输入手机号')" class="el-select-logo">
                        <el-select slot="prepend" v-model="forgetForm.areaCode">
                            <el-option
                                v-for="(item, index) in countCode()"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </div>
            </el-form-item>
            <div class="switch-login">
                {{ $t("或")
                }}<span class="underline pointer" @click="changeType">{{
                    forgetInputType === "phone" ? $t("电子电邮") : $t("手机号码")
                }}</span>
            </div>

            <el-form-item v-if="forgetInputType === 'email'" prop="mailCode">
                <div class="form-title password-input">
                    {{ $t("验证码") }}
                </div>
                <div style="display: flex; justify-content: space-between">
                    <el-input
                        v-model="forgetForm.mailCode"
                        type="text"
                        class="password verification-code"
                        :placeholder="$t('请输入电邮验证码')"
                    />
                    <el-button v-if="!sendStatus" type="primary" class="verification-code-button" @click="sendCode">
                        <span>{{ $t("发送验证码") }}</span>
                    </el-button>
                    <el-button
                        v-if="sendStatus"
                        type="primary"
                        class="verification-code-button"
                        disabled="disabled"
                        style="background: #4b4a4a"
                    >
                        <span>{{ countBackwards }}s</span>
                    </el-button>
                </div>
            </el-form-item>

            <el-form-item v-else prop="smsCode">
                <div class="form-title password-input">
                    {{ $t("验证码") }}
                </div>
                <div style="display: flex; justify-content: space-between">
                    <el-input
                        v-model="forgetForm.smsCode"
                        type="text"
                        class="password verification-code"
                        :placeholder="$t('请输入验证码')"
                    />
                    <el-button
                        v-if="!sendStatus"
                        type="primary"
                        class="verification-code-button"
                        :loading="loadingCode"
                        :disabled="codeButton"
                        @click="sendCode"
                    >
                        <span>{{ $t("发送验证码") }}</span>
                    </el-button>
                    <el-button
                        v-if="sendStatus"
                        type="primary"
                        class="verification-code-button"
                        disabled="disabled"
                        style="background: #4b4a4a"
                    >
                        <span>{{ countBackwards }}s</span>
                    </el-button>
                </div>
            </el-form-item>

            <el-form-item prop="password">
                <div class="form-title password-input">
                    {{ $t("设置新密码") }}
                </div>
                <el-input
                    v-model="forgetForm.password"
                    type="password"
                    show-password
                    class="password"
                    :placeholder="$t('请输入新密码')"
                />
            </el-form-item>
            <el-form-item prop="confirmPassword">
                <div class="form-title password-input">
                    {{ $t("确认密码") }}
                </div>
                <el-input
                    v-model="forgetForm.confirmPassword"
                    type="password"
                    show-password
                    class="password"
                    :placeholder="$t('请再次输入新密码')"
                />
            </el-form-item>
            <div style="text-align: center">
                <el-button type="primary" class="login-button" :loading="loading" @click="confirmCombine">
                    {{ $t("确认") }}
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import { authGetMailCode, authGetPhoneCode, forgotPassword } from "@/api/login";
import { countCode } from "@/enum";

const defaultForget = {
    phonenumber: "",
    password: "",
    areaCode: "+852",
    email: "",
    confirmPassword: "",
    smsCode: "",
    mailCode: "",
};
export default {
    data() {
        return {
            codeButton: false,
            loading: false,
            loadingCode: false,
            sendStatus: false,
            countBackwards: 60,
            countBackwardsTimer: null,
            forgetInputType: "phone", // phone email
            forgetForm: Object.assign({}, defaultForget),
            forgetFormRules: {
                phonenumber: [
                    {
                        required: true,
                        message: this.$t("请输入您的手机号码"),
                        trigger: "blur",
                    },
                    {
                        required: true,
                        pattern: /^\d{8,}$/,
                        message: this.$t("请输入正确的手机号码"),
                        trigger: "blur",
                    },
                ],
                password: [{ required: true, message: this.$t("请输入您的密码"), trigger: "change" }],
                mailCode: [{ required: true, message: this.$t("请输入电邮验证码"), trigger: "change" }],
                smsCode: [{ required: true, message: this.$t("请输入验证码"), trigger: "change" }],
                areaCode: [{ required: true, message: this.$t("请选择区"), trigger: "change" }],
                email: [{ required: true, message: this.$t("请输入电邮号"), trigger: "change" }],
                confirmPassword: [
                    {
                        required: true,
                        message: this.$t("请再次确认密码"),
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
        countCode() {
            return countCode;
        },
        rsaEncrypt(pas) {
            /*const key = CryptoJS.enc.Utf8.parse(this.rsaKey);
            const srcs = CryptoJS.enc.Utf8.parse(pas);
            const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            return encrypted.toString();*/
            return pas;
        },
        changeType() {
            this.forgetInputType = this.forgetInputType === "email" ? "phone" : "email";
            this.sendStatus = false;
            clearInterval(this.countBackwardsTimer);
            this.countBackwards = 60;
        },
        confirmCombine() {
            this.loading = true;
            this.$refs["forgetForm"].validate(async (valid) => {
                if (valid) {
                    let phone = this.rsaEncrypt(`${this.forgetForm.areaCode}-${this.forgetForm.phonenumber}`);
                    if (this.forgetInputType === "phone") {
                        this.forgetForm.email = "";
                    } else {
                        phone = "";
                    }
                    this.forgetForm.password = this.rsaEncrypt(this.forgetForm.password);
                    this.forgetForm.confirmPassword = this.rsaEncrypt(this.forgetForm.confirmPassword);
                    forgotPassword({ ...this.forgetForm, phonenumber: phone }).then((val) => {
                        if (val.code === 200) {
                            this.$refs["forgetForm"].resetFields();
                            this.$message({
                                message: this.$t("找回密码成功"),
                                type: "success",
                            });
                            this.$emit("registerBack", "forget");
                            this.$emit("selectForgetPassword", "login");
                        } else {
                            this.$message.warning(val.msg);
                        }
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        sendCode() {
            // 发送验证码点击后不可再点
            this.codeButton = true;

            this.loadingCode = true;
            this.$refs["forgetForm"].validateField(["phonenumber", "email"], async (valid) => {
                // valid为正则不通过时候的提示信息。请输入正确的手机号码。无valid说明正则通过
                if (!valid) {
                    if (this.forgetInputType === "phone") {
                        if (this.forgetForm.phonenumber.trim() === "") {
                            this.loadingCode = false;
                            this.codeButton = false;
                            return;
                        }
                    }
                    const phone = `${this.forgetForm.areaCode}-${this.forgetForm.phonenumber}`;

                    if (this.forgetInputType === "phone") {
                        authGetPhoneCode({ phonenumber: phone }).then((val) => {
                            // 发送验证码
                            if (val.code === 200) {
                                this.sendStatus = true;
                                this.countBackwardsTimer = setInterval(() => {
                                    this.countBackwards--;
                                    if (this.countBackwards < 1) {
                                        this.sendStatus = false;
                                        clearInterval(this.countBackwardsTimer);
                                        this.countBackwards = 60;
                                    }
                                }, 1000);
                            }
                            this.loadingCode = false;
                            this.codeButton = false;
                        });
                    } else {
                        const email = this.forgetForm.email;
                        authGetMailCode({ email: email }).then((val) => {
                            // 发送验证码
                            if (val.code === 200) {
                                this.sendStatus = true;
                                this.countBackwardsTimer = setInterval(() => {
                                    this.countBackwards--;
                                    if (this.countBackwards < 1) {
                                        this.sendStatus = false;
                                        clearInterval(this.countBackwardsTimer);
                                    }
                                }, 1000);
                            }
                            this.loading = false;
                            this.codeButton = false;
                        });
                    }
                } else {
                    this.$message.error(valid);
                }
                this.loadingCode = false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.keep-login-status {
    margin-top: 16px;
}
.login-button {
    margin-top: 30px;
    background-color: #104c3d;
    border-radius: 8px;
}
.password-input {
    margin-top: 15px;
}
.forget-password-input {
    text-decoration: underline;
    margin-top: 3px;
}
.switch-login {
    margin-top: 10px;
    font-size: 12px;
}
.verification-code {
    width: 200px;
}

.verification-code-button {
    width: 140px;
    background-color: #1a1a1a;
}

.verification-code-button span {
    font-size: 12px;
}

::v-deep .el-button.login-button.el-button--primary,
.el-button.verification-code-button.el-button--primary {
    background-color: #104c3d;
}
</style>
