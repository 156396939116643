<template>
    <div class="switch pointer no-select">
        <div
            v-for="item in list"
            :key="item.id"
            class="item"
            :class="{ itemActive: active === item.id }"
            @click="changeCatelog(item.id)"
        >
            {{ item.label }}
        </div>
        <span class="line" style="visibility: visible">|</span>
        <div class="langChange" style="visibility: visible">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    {{ langLabel }}<i class="el-icon-arrow-down el-icon--right" style="margin-left: 8px"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="lang in langLabelList" :key="lang.id" :command="lang.command">{{
                        lang.label
                    }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { getLang, getSexType, setLang, setSexType } from "@/utils/auth";

export default {
    name: "JfSwitch",
    data() {
        return {
            list: [
                { id: 1, label: this.$t("女士") },
                { id: 2, label: this.$t("男士") },
            ],
            langLabelList: [
                { id: 1, label: this.$t("繁体中文"), command: "cht" },
                { id: 2, label: "English", command: "en" },
            ],
            active: 1,
            langLabel: this.$t("繁体中文"),
        };
    },
    mounted() {
        this.active = parseInt(getSexType().id);
        const lang = getLang();
        if (lang) {
            const langFilter = this.langLabelList.filter((item) => item.command === lang);
            this.langLabel = langFilter[0]["label"];
        }
    },
    methods: {
        changeCatelog(item) {
            const path = "/";
            this.active = item;
            setSexType(item);
            const t = this.active === 1 ? "w" : "m";
            this.$router.push({
                path: path,
                query: { l: t },
            });
            this.$store.dispatch("getDropDownMenuByStory"); // 这里要优化
        },
        handleCommand(command) {
            const langFilter = this.langLabelList.filter((item) => item.command === command);
            this.langLabel = langFilter[0]["label"];
            setLang(langFilter[0]["command"]);
            location.reload();
        },
    },
};
</script>

<style scoped lang="scss">
.line {
    margin-left: 10px;
}
.langChange {
    margin-left: 18px;
}
.switch {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #222222;
    margin-left: -0.75rem;
    .item {
        padding: 0.75rem;
    }
    .itemActive {
        font-weight: bold;
        font-size: 20px;
    }
    .item:hover {
        background-color: #f5f5f5;
    }
    .item:first-child:hover {
        border-radius: 0.5rem 0 0 0.5rem;
    }
    .item:last-child:hover {
        border-radius: 0 0.5rem 0.5rem 0;
    }
}
</style>
