export const sexType = {
    1: "woman",
    2: "man",
};

export const homeComponentType = {
    0: "activeBox",
    1: "towPictures",
    2: "threePictures",
    3: "carouselResources",
    4: "community",
    5: "notice",
    6: "carouselProducts",
    7: "banner",
};

export const countCode = [
    { label: "CN +86", value: "+86" },
    { label: "MO +853", value: "+853" },
    { label: "HK +852", value: "+852" },
    { label: "TW +886", value: "+886" },
    { label: "KH +855", value: "+855" }, // 柬埔寨
    { label: "UK +44", value: "+44" }, // 英国
    { label: "VN +84", value: "+84" }, // 越南
    { label: "ID +62", value: "+62" }, // 印尼
    { label: "TH +66", value: "+66" }, // 泰国
    { label: "SG +65", value: "+65" }, // 新加坡
    { label: "MY +60", value: "+60" }, // 马来西亚
    { label: "PH +63", value: "+63" }, // 菲律宾
    { label: "CA +1", value: "+1" }, // 加拿大
];
