<template>
    <div class="pointer space-between">
        <div v-for="(item, index) in helpList" :key="index" class="help">
            <el-image :src="item.icon" style="width: 2rem" lazy />
            <div class="title">
                {{ item.title }}
            </div>
            <div class="subtitle">
                {{ item.subTitle }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // 组件的JavaScript代码
    data() {
        return {
            helpList: [
                {
                    icon: require("@/assets/images/goodList/gouwuche.png"),
                    title: this.$t("如何购物"),
                    subTitle: this.$t("新客必备选购指南"),
                },
                {
                    icon: require("@/assets/images/goodList/wenhao.png"),
                    title: this.$t("常见问题"),
                    subTitle: this.$t("这里或许有你要的答案"),
                },
                {
                    icon: require("@/assets/images/goodList/kefu.png"),
                    title: this.$t("需要帮助"),
                    subTitle: this.$t("敬请联系我们的客服"),
                },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.help {
    width: 29%;
    height: 6.625rem;
    border: 1px solid #ececec;
    padding: 1.1875rem 1.1875rem 1.6875rem 1.1875rem;
    .title {
        color: #6f6f6f;
        font-size: 1.1875rem;
        margin: 0.625rem 0;
    }
    .subtitle {
        font-size: 1.375rem;
    }
}
</style>
