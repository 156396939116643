exports.install = function (Vue) {
    // 获取品牌名称
    Vue.prototype.getBrandName = function (str) {
        if (!str) return "";
        return str.substr(0, str.indexOf("/"));
    };
    // 是否移动端
    Vue.prototype.isMobile = function () {
        return navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
    };
};
