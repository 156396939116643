<template>
    <div class="space-between product-spec">
        <div class="detail" style="align-items: baseline">
            <div v-sticky="{ stickyTop: 120 }" class="detail">
                <div class="item-images">
                    <div
                        v-for="(item, index) in skuDetailList"
                        :key="index"
                        :class="{ 'product-image-select': index === carouselIndex }"
                        @click="carouselChange(index)"
                    >
                        <el-image v-if="item.type == 1" :src="item.skuImg" fit="cover" class="child-item-img" />
                    </div>
                </div>
                <div class="main-img spec-box">
                    <el-carousel ref="carousel" class="main-swiper" arrow="always" @change="setSwiperChange">
                        <el-carousel-item v-for="(item, index) in skuDetailList" :key="index">
                            <el-image v-if="item.type == 1" class="main-img" :src="item.skuImg" fit="cover" />
                            <video
                                v-if="item.type == 2"
                                class="video-swiper"
                                controlslist="nodownload"
                                autoplay
                                :muted="muted"
                                controls
                            >
                                <source :src="item.skuImg" type="video/mp4" />
                            </video>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div v-if="goods" class="good-info">
            <div v-show="quick_buy_show" class="quick-buy">
                <div class="close">
                    <i class="el-icon-close" @click="quick_buy_show = false" />
                </div>

                <buyNow v-if="!miss_product" :goods="goods" :product-price="productPrice" @createOrder="createOrder" />
                <div v-else>
                    <div class="miss-product">
                        <jf-checkbox v-model="missProduceModel" size="1.25rem" />
                        <span>{{ $t("缺货登记（商品到货后平台将通过账号联系方式通知）") }}</span>
                    </div>
                    <button class="box miss-confirm" @click="missProduceHandel">{{ $t("确定") }}</button>
                </div>
            </div>

            <div v-if="sellBox" class="offer-box">
                <div class="close close-sell">
                    <i class="el-icon-close" @click="sellBox = false" />
                </div>
                <goodIndividual ref="GoodIndividual" @getSkuId="getSkuId" />
            </div>
            <div v-show="showActivity">
                <div v-if="activityStatus === 'process'" class="active">
                    <div>{{ $t("限时优惠") }}</div>
                    <div>{{ `${day > 0 ? `${day}天 ` : ""}${hour}:${min}:${second}` }}</div>
                </div>
                <div v-if="activityStatus === 'noStart'" class="active">
                    <div>{{ $t("即将开抢") }}</div>
                    <div>{{ `${day > 0 ? `${day}天 ` : ""}${hour}:${min}:${second}` }}</div>
                </div>
            </div>
            <div class="tag space-between">
                <span style="display: flex; align-items: center"
                    >{{ productPrice | currencyHk }}
                    <!-- <span style="font-weight: 400; font-size: 0.75rem">({{ $t("含税价") }})</span> -->
                </span>
                <span v-show="goods?.sellPrice > productPrice">{{ goods?.sellPrice ?? 0 | currencyHk }}</span>
                <span
                    :style="{
                        visibility: (goods?.sellPrice - productPrice) / goods?.sellPrice > 0.1 ? 'visible' : 'hidden',
                    }"
                >
                    {{ (((goods?.sellPrice - productPrice) / goods?.sellPrice) * 100).toFixed() }}% OFF
                </span>
                <span>{{ $t("已售出") }}{{ recentlyBuyTotal }}{{ $t("件") }}</span>
            </div>
            <el-tooltip v-if="showUpPick" effect="dark" :content="$t('到店提货支持无理由退货')" placement="right">
                <div class="isPick">{{ $t("专业鉴定师即场验货") }}</div>
            </el-tooltip>
            <div class="product-name">
                <div>{{ `${goods?.brandName} ${goods?.productTitle}` }}</div>
                <div>
                    <Share :detail-data="detailData" type="product" @copyLink="copyLink">
                        <img :src="require('@/assets/images/goods/share.png')" alt="share" />
                    </Share>
                </div>
            </div>
            <div v-if="false" class="product-color">
                <div class="color-select">
                    {{ $t("颜色") }}: <span>{{ color }}</span>
                </div>
                <div class="color-box">
                    <div :class="{ 'color-select-box': color === '公主橙' }">
                        <span style="background-color: #e55809" @click="selectColor('公主橙')" />
                    </div>
                    <div :class="{ 'color-select-box': color === '紫金黄' }">
                        <span style="background-color: #ebd82c" @click="selectColor('紫金黄')" />
                    </div>
                    <div :class="{ 'color-select-box': color === '翡翠绿' }">
                        <span style="background-color: #4f881b" @click="selectColor('翡翠绿')" />
                    </div>
                    <div :class="{ 'color-select-box': color === '深邃蓝' }">
                        <span style="background-color: #0290cd" @click="selectColor('深邃蓝')" />
                    </div>
                </div>
            </div>

            <SkuBox
                ref="skuBoxRef"
                :product="goods"
                @missProduct="missProduct"
                @setParentSkuDetailList="setParentSkuDetailList"
                @selectChannelPrice="selectChannelPrice"
            />

            <div class="product-brand">
                <div class="brand-label">
                    {{ $t("商品品牌") }}
                </div>
                <div v-if="goods?.brandLogo" class="brand-info">
                    <img :src="goods?.brandLogo" alt="share" @click="goProductList" />
                    <div>
                        <span class="brand-name">{{ goods?.brandName }}</span> <br />
                        <span class="brand-product">{{ brandInfo?.productNum || 0 }}{{ $t("件商品") }}</span>
                        <span class="brand-des">{{ brandInfo.brandDesc }}</span>
                    </div>
                </div>
            </div>

            <div class="space-between goods-button">
                <el-button type="primary" class="box sell-bottom" @click="sellProduct">
                    <span class="goods-button-item">{{ $t("我要出售") }}</span>
                </el-button>

                <el-button type="primary" class="box" @click="openQuickBuyBox">
                    <span class="goods-button-item">{{ miss_product ? $t("缺货登记") : $t("立即购买") }}</span>
                </el-button>
                <div>
                    <img
                        :style="{ visibility: miss_product ? 'hidden' : 'visible' }"
                        class="collect-img"
                        :src="require('@/assets/icons/cart.png')"
                        @click="addCart"
                    />
                </div>
                <div @click="handleCollect">
                    <img v-if="heart" class="collect-img" :src="require('@/assets/images/goods/heart.png')" />
                    <img v-else class="collect-img" :src="require('@/assets/images/goods/heart-empty.svg')" />
                </div>
            </div>
            <div class="notice" @click="$router.push('/statement/platformMind')">
                <el-popover placement="bottom" width="200" trigger="hover" :content="content" popper-class="popperDiv">
                    <div class="info">
                        {{ $t("平台所售卖的商品均通过严格的商家筛选、商品及质量检测，确保为全新正品") }}<br />
                        {{ $t("Famkools专业鉴别团队逾10年行业经验，鉴别数量过百万件商品") }}<br />
                        {{ $t("平台出售的所有商品，香港、澳门地区均享免运服务，同时鼓励顾客到黄竹坑office自取") }}<br />
                        {{ $t("平台出售的包袋、手表，均提供1次/年保养服务，提供全方位服务流程并发出相关检测报告。") }}
                    </div>
                    <img
                        v-if="lang == 'en'"
                        slot="reference"
                        :src="require('@/assets/images/goods/notice-en.png')"
                        width="100%"
                    />
                    <img v-else slot="reference" :src="require('@/assets/images/goods/notice.png')" width="100%" />
                </el-popover>
            </div>
        </div>
        <size-dialog :visible.sync="visibleDialog" :title="$t('女装')" :goods="goods" @close="visibleDialog = false" />
        <quoteStatus
            :merchant-type="merchantType"
            :bottom-text="quotaBottomText"
            :text="quotaText"
            :quote-dialog-table-visible="quoteDialogTableVisible"
            :is-identify="goods?.isIdentify"
            @closeQuoteDialog="closeQuoteDialog"
        />
    </div>
</template>

<script>
import SizeDialog from "@/views/product/productDetail/components/sizeDialog/index.vue";
import dayjs from "dayjs";
import buyNow from "./buyNow.vue";
import { addCart, createOrder } from "@/api/order";
import SkuBox from "@/views/product/productDetail/components/spec/skuBox.vue";
import goodIndividual from "@/views/product/productDetail/components/goodIndividual/index.vue";
import JfCheckbox from "@/components/JfCheckbox/index.vue";
import { createOrderBody, orderProductInList } from "@/model/checkOut";
import { noStockRecord, productActivity } from "@/api/goods";
import quoteStatus from "@/views/dialog/quoteStatus.vue";
import VueSticky from "vue-sticky";

import { currencyHk } from "@/filters";
import { getSoundControl } from "@/utils/auth";
import Share from "@/components/Share/index.vue";
import { getLang } from "@/utils/auth.js";
import { buy_now } from "@/utils/analytics.js";

export default {
    name: "GoodSpec",
    components: {
        Share,
        JfCheckbox,
        SizeDialog,
        buyNow,
        SkuBox,
        goodIndividual,
        quoteStatus,
    },
    directives: {
        sticky: VueSticky,
    },
    props: {
        goods: {
            type: Object,
            default: () => ({}),
        },
        brandInfo: {
            type: Object,
            default: () => ({}),
        },
        recentlyBuyTotal: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            detailData: {},
            content:
                "平台所售賣的商品均通過嚴格的商家篩選、商品及質量檢測，確保為全新正品。\n平台發出的所有商品，均逐件鑒別並出具鑒別證書、添加防偽扣。\n平台出售的所有商品，均享受免費郵寄服務，同時支持用戶到門店自提。\n平台出售的包袋、手錶，均提供1次/年保養服務，提供全方位服務流程並發出相關檢測報告。",
            day: 0,
            hour: 0,
            min: 0,
            second: 0,
            activityTime: "",
            sellNum: 0,
            tagName: "",
            activityStatus: "",
            showActivity: true,
            size: this.$t("均码"),
            sizeName: this.$t("尺码"),
            missProduceModel: true,
            sizeValue: [],
            sellBox: false,
            colorValue: "",
            colorName: this.$t("颜色"),
            color: "-",
            channel: "香港 HK$12231234",
            channelValue: "",
            area: "",
            skuDetailList: [],
            sku: [],
            skuName: [],
            skuList: [],
            productPrice: 0,
            salesPrice: 0,
            visibleDialog: false,
            carouselIndex: 0,
            quick_buy_show: false,
            quoteDialogTableVisible: false,
            merchantType: "",
            quotaText: "",
            quotaBottomText: "",
            miss_product: false,
            activityData: [],
            placeholder: [
                { productImg: require("@/assets/images/placeorder/smGoodsPlaceHorder.png") },
                { productImg: require("@/assets/images/placeorder/goodsPlaceHorder.png") },
            ],
            datalist: [
                { name: "1" },
                { name: "12" },
                { name: "1w" },
                { name: "14" },
                { name: "1w" },
                { name: "1y" },
                { name: "1t" },
                { name: "1c" },
                { name: "1c" },
                { name: "1d" },
                { name: "1g" },
                { name: "1d" },
            ],
        };
    },
    computed: {
        // 配送时效
        deliveryTimeliness() {
            return dayjs().add(9, "day").format("MM月DD日") + " - " + dayjs().add(17, "day").format("MM月DD日");
        },
        heart() {
            return this.$store.getters.collectNums.includes(this.goods?.productId);
        },
        muted() {
            return getSoundControl();
        },
        showUpPick() {
            const categoryIdGroud = this.goods.categoryIdGroud.split("|");
            const noShowIds = ["553", "554", "601", "811"];

            const isShow = noShowIds.filter((item) => {
                return categoryIdGroud.includes(item);
            });

            return !isShow.length;
        },
    },
    watch: {
        goods: function () {
            this.productPrice = this.goods?.sellPrice ?? 0;
            this.goods.skuAttrList.forEach((item) => {
                this.sizeValue = JSON.parse(item.skuAttrValues);
                this.size = this.size = this.sizeValue[0];
                this.sizeName = item.skuAttrName;
                const sku = {
                    skuLabel: item.skuAttrName,
                    skuSelect: this.sizeValue[0],
                    skuValue: JSON.parse(item.skuAttrValues),
                };
                this.skuList.push(sku);
                this.getSkuItem();
            });
            this.goods.tagList?.forEach((item) => {
                if (item.shopTagId === 2000000 && item.isShow) {
                    this.tag = `${((1 - this.goods.discount) * 100).toFixed()}% OFF`;
                }
                if (item.shopTagId === 2000002 && item.isShow) {
                    this.showActivity = true;
                }
            });
        },
    },

    mounted() {
        /*        getProductById(this.$route.query.id).then((item) => {
            this.sellNum = item.data?.sellNum ?? 0;
        });*/
        productActivity(this.$route.query.id).then((item) => {
            this.activityData = item.data;
            if (Object.values(this.activityData).length) {
                const currentTime = new Date().getTime();
                const upTime = new Date(this.activityData.upTime).getTime();
                const offTime = new Date(this.activityData.offTime).getTime();

                if (currentTime < upTime) {
                    this.activityStatus = "noStart";
                    this.activityTime = this.activityData.upTime;
                }
                if (upTime < currentTime && currentTime < offTime) {
                    this.activityStatus = "process";
                    this.activityTime = this.activityData.offTime;
                }

                this.countTime();
            }
        });
        this.lang = getLang();
    },
    methods: {
        currencyHk,
        countTime() {
            let date = new Date();
            let now = date.getTime();
            let endDate = new Date(this.activityTime);
            let end = endDate.getTime();
            let leftTime = end - now;
            if (leftTime >= 0) {
                this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
                let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
                this.hour = h < 10 ? "0" + h : h;
                let m = Math.floor((leftTime / 1000 / 60) % 60);
                this.min = m < 10 ? "0" + m : m;
                let s = Math.floor((leftTime / 1000) % 60);
                this.second = s < 10 ? "0" + s : s;
            } else {
                this.day = 0;
                this.hour = "00";
                this.min = "00";
                this.second = "00";
            }
            if (
                !(
                    Number(this.hour) === 0 &&
                    Number(this.day) === 0 &&
                    Number(this.min) === 0 &&
                    Number(this.second) === 0
                )
            ) {
                setTimeout(this.countTime, 1000);
            }
        },
        setSwiperChange(v) {
            this.carouselIndex = v;
        },
        handleCollect() {
            if (this.getToken()) {
                // 调用心愿单方法
                const status = this.heart ? 0 : 1;
                const wishItem = { productId: this.goods?.productId, status };
                this.$store.dispatch("changeCollect", wishItem);
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },
        openQuickBuyBox() {
            if (this.getToken()) {
                buy_now();
                if (this.miss_product) {
                    this.quick_buy_show = true;
                } else {
                    const shopProductPriceId = this.$refs["skuBoxRef"].shopProductPriceId;
                    const skuIdResult = this.$refs["skuBoxRef"].getSkuItem();
                    const checkOutQuery = [
                        {
                            skuId: skuIdResult[0].skuId,
                            productId: this.goods?.productId,
                            memberPoints: 0,
                            couponCode: "",
                            shopProductPriceId,
                        },
                    ];
                    const toString = encodeURIComponent(JSON.stringify(checkOutQuery));
                    this.$router.push({
                        path: "/checkout",
                        query: { conclusion: toString, quicklyBuy: 1 },
                    });
                }
            } else {
                buy_now();
                this.$store.dispatch("changeOpenState", true);
            }
        },
        missProduceHandel() {
            const skuIdResult = this.$refs["skuBoxRef"].getSkuItem(); // 获取sku结果
            const data = {
                productId: parseInt(this.$route.query.id),
                skuId: skuIdResult[0].skuId,
            };
            noStockRecord(data).then((val) => {
                if (val.code === 200) {
                    this.quick_buy_show = false;
                    this.$notify({
                        message: this.$t("登记成功"),
                        type: "success",
                    });
                }
            });
        },
        goProductList() {
            const level1SearchBo = {
                searchType: 1, // 品牌
                searchValue: this.goods.brandId,
            };
            this.$store.dispatch("web/setProductList", null);
            this.$router.push({
                path: "/product/list",
                query: {
                    title: this.$t("品牌"),
                    category: this.brandInfo.brandName,
                    level1: encodeURIComponent(JSON.stringify(level1SearchBo)),
                    level2: encodeURIComponent(JSON.stringify([])),
                },
            });
        },
        sellProduct() {
            this.merchantType = this.$store.getters.roleData?.seller?.merchant?.merchantType || null;
            if (this.merchantType) {
                if (this.merchantType === "1") {
                    // 个卖
                    if (this.goods?.isIdentify === "0") {
                        this.quotaText = this.$t("该商品仅支持品牌方/正品正店出售，请入驻后出价");
                        this.quotaBottomText = this.$t("前往入驻");
                    } else {
                        this.sellBox = true;
                        return;
                    }
                } else {
                    this.quotaText = this.$t("企业商家请前往商家中心出价");
                    this.quotaBottomText = this.$t("前往商家中心");
                }
            } else {
                this.quotaText = this.$t("该商品支持品牌方/正品正店/普通企业/个人商家出售，请入驻后出价");
                this.quotaBottomText = this.$t("前往入驻");
            }
            this.closeQuoteDialog(true);
        },
        closeQuoteDialog(v) {
            this.quoteDialogTableVisible = v;
        },
        missProduct(v) {
            this.miss_product = v;
            if (v) this.productPrice = "-";
        },
        copyLink() {
            const link = window.location.href;
            this.copyTextToClipboard(link);
            this.$notify({
                message: this.$t("复制成功，去分享给好友吧"),
                type: "success",
            });
        },
        setParentSkuDetailList(item) {
            this.skuDetailList = item.product;
            this.$emit("setSkuDetailList", item);
        },
        getSkuItem() {
            const skuIdResult = [];
            const skuSet = this.skuList.map((item) => item.skuSelect);
            this.goods.skuList.forEach((item) => {
                let diff = item.skuNameList.filter(function (val) {
                    return skuSet.indexOf(val) === -1;
                });
                if (!diff.length) skuIdResult.push(item);
            });
            if (skuIdResult.length) {
                const channelResult = skuIdResult[0].priceList;
                this.skuDetailList = skuIdResult[0].skuImag.product;
                this.$emit("setSkuDetailList", skuIdResult[0].skuImag);

                if (channelResult) {
                    this.channelValue = channelResult;
                    this.channel = `${channelResult[0].goodsChannelName} HK$${channelResult[0].price}`;
                    this.productPrice = channelResult[0].price;
                    this.salesPrice = channelResult[0]?.sellPrice ?? 0;
                } else {
                    this.missProduct(true);
                }
            }
            return skuIdResult;
        },
        addCart() {
            if (this.getToken()) {
                const shopProductPriceId = this.$refs["skuBoxRef"].shopProductPriceId;
                const skuIdResult = this.$refs["skuBoxRef"].getSkuItem(); // 获取sku结果

                const productId = parseInt(this.$route.query.id);
                addCart({
                    productId: productId,
                    shopProductPriceId: shopProductPriceId,
                    skuId: skuIdResult[0].skuId,
                }).then((value) => {
                    if (value.code === 200) {
                        this.$store.dispatch("updateCartNum");
                        this.$message({
                            message: this.$t("成功加入购物车"),
                            type: "success",
                        });
                    }
                });
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },

        getSkuId(callback) {
            const skuIdResult = this.$refs["skuBoxRef"].getSkuItem(); // 获取sku结果
            callback(skuIdResult[0].skuId);
        },
        createOrder() {
            const skuIdResult = this.$refs["skuBoxRef"].getSkuItem(); // 获取sku结果
            const skuNameList = this.$refs["skuBoxRef"].skuList;
            const skuName = JSON.stringify(skuNameList.map((item) => item.skuLabel));
            const productItemList = orderProductInList(this.goods, skuName, skuIdResult[0].skuName, 1);

            const body = createOrderBody(
                this.goods,
                1,
                this.productPrice,
                this.productPrice,
                1,
                skuIdResult[0],
                this.salesPrice,
                skuName,
                [productItemList]
            );
            createOrder(body).then((val) => {
                if (val.code === 200) {
                    this.$router.push(`/payment/${val.data.salesOrderCode}`);
                }
            });
        },
        copyTextToClipboard(text) {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
        },
        selectSize(item, index) {
            this.skuList[index].skuSelect = item;
            this.getSkuItem();
        },
        selectChannelPrice(v) {
            this.productPrice = v;
        },
        selectColor(v) {
            this.color = v;
        },
        carouselChange(index) {
            this.carouselIndex = index;
            this.$refs.carousel.setActiveItem(index);
        },
        prev() {
            this.$refs.carousel.prev();
        },
        next() {
            this.$refs.carousel.next();
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.sell-bottom {
    background-color: white;
    span {
        color: black;
    }
    border: 1px solid #1a1a1a;
}

.isPick {
    width: 146px;
    background-color: #0d4b3c;
    color: #e5e4e4;
    padding: 0.3rem;
    text-align: center;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: -10px;
    cursor: pointer;
}

.product-image-select {
    border-bottom: 0.2rem solid gray;
    margin-bottom: 0.2rem;
}

.miss-confirm {
    background-color: #1a1a1a;
    width: 70% !important;
    border-radius: 8px;
    color: white;
    margin-left: 60px;
    margin-top: 30px;
    cursor: pointer;
}

.miss-product {
    display: flex;
    font-size: 14px;
    justify-content: center;
    span {
        margin-left: 10px;
    }
}

.goods-button-item {
    font-size: 14px;
    image {
        cursor: pointer;
    }
}

.notice {
    margin-top: 30px;
    cursor: pointer;
}

.goods-button {
    margin-top: 30px;
}

.box {
    width: 154px;
    height: 40px;
}

.main-swiper {
    height: 600px;
}

.item-images {
    width: 120px;
    overflow-y: auto;
    height: 600px;
    margin-right: 20px;
    div {
        cursor: pointer;
        margin-bottom: 0.4rem;
    }
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.item-images .child-item-img {
    width: 100%;
}

.brand-label {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
}

.brand-info {
    display: flex;
    margin-top: 15px;
}

.brand-info div {
    margin-left: 15px;
}

.brand-info img {
    width: 100px;
    cursor: pointer;
}

.brand-name {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
}

.brand-des {
    font-size: 14px;
    display: block;
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.brand-product {
    color: #222222;
    font-size: 14px;
}

.product-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 30px 0;
}

.product-name div:nth-child(1) {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: bold;
}

.product-name div:nth-child(2) {
    cursor: pointer;
}

.product-name div:nth-child(2) img {
    width: 35px;
}

.color-select,
.size-select {
    margin-top: 20px;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 600;
    span {
        font-weight: 400;
        padding-left: 12px;
    }
}

.size-box {
    flex-wrap: wrap;
}

.product-color,
.product-size {
    div {
        display: flex;
    }
}

.color-box,
.size-select {
    margin-top: 15px;
}

.color-box div {
    margin-right: 20px;
    padding: 1%;
    border-radius: 35px;
}

.color-select-box {
    border: 1px solid black;
}

.color-box div span {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    cursor: pointer;
}

.size-box span {
    display: block;
    height: 45px;
    min-width: 90px;
    margin-right: 10px;
    border: 1px solid #e5e4e4;
    text-align: center;
    line-height: 45px;
    border-radius: 10px;
    margin-top: 12px;
    cursor: pointer;
    padding: 0 10px 0 10px;
}

.size-guide {
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
}

.size-guide img {
    margin-right: 8px;
}

.color-box span:nth-child(1) {
    margin-left: 0px;
}

.good-info {
    width: 468px;
    position: relative;
    .tag {
        span {
            color: #999999;
        }
        align-items: center;
    }

    .tag span:nth-child(1) {
        color: #1a1a1a;
        font-size: 20px;
        font-weight: 600;
    }

    .tag span:nth-child(2) {
        color: #999999;
        text-decoration: line-through;
        font-size: 16px;
        margin-left: -55px;
    }

    .tag span:nth-child(3) {
        background: #fbeee6;
        color: #ec5d29;
        margin-left: -50px;
    }

    .tag span:nth-child(4) {
        font-size: 14px;
    }

    .brand {
        font-size: 1.3125rem;
        font-weight: bold;
        padding: 0.1875rem 0;
    }
    .dec {
        font-size: 1.125rem;
        color: #404040;
    }
    .price {
        font-size: 1.3125rem;
        margin-top: 3.0625rem;
    }
    .price-dec {
        color: #404040;
        margin-bottom: 1.875rem;
    }
}
//
.hover {
    padding: 0 1.25rem;
    border-radius: 0;
}
.select {
    width: 100%;
    margin-top: 1.25rem;
}
::v-deep .el-input--prefix .el-input__inner {
    padding-left: 4.6875rem;
}
.option {
    color: #404040;
    display: flex;
    .country {
        width: 3.4375rem;
    }
    .keynote {
        color: #222222;
        font-size: 1.125rem;
        width: 5rem;
        text-align: center;
    }
}
.delivery-time {
    color: #404040;
    margin-top: 1rem;
    line-height: 1.375rem;
}
.tip {
    padding: 1.25rem 1.25rem 3.125rem;
    background: #f5f5f5;
    margin-top: 3.75rem;
}

.spec-box {
    display: inline-block;
}

.detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand-info-tips {
    width: 300px;
}

.child-item {
    width: 260px;
    position: relative;
}

.size-select-box {
    border: 1px solid black !important;
}

.child-item .prev,
.child-item .next {
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.prev {
    left: 100px;
    bottom: 10px;
}

.next {
    left: 100px;
    top: 10px;
}

.main-img {
    width: 600px;
}

.video-swiper {
    width: 600px;
    height: 600px;
}

.child-item-img {
    width: 280px;
}

.swiper-child {
    height: 600px;
}

.collect-img {
    width: 40px;
    cursor: pointer;
}

.quick-buy {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 160px;
    bottom: 80px;
    box-shadow: 0px 0px 10px 0px #0000001a;
}

.offer-box {
    position: absolute;
    background-color: white;
    width: 100%;
    z-index: 9;
    height: 750px;
    box-shadow: 0px 0px 10px 0px #0000001a;
}

.channel-box span {
    display: block;
    height: 45px;
    min-width: 90px;
    margin-right: 10px;
    border: 1px solid #e5e4e4;
    text-align: center;
    border-radius: 10px;
    margin-top: 12px;
    cursor: pointer;
    padding: 8px 8px 8px 8px;
}

.channel {
    margin-top: 25px;
}

.quick-buy .close {
    padding: 10px 10px 5px 10px;
    text-align: right;
    font-size: 18px;
    cursor: pointer;
}

.seller-number {
    font-size: 14px;
}

.close-sell {
    margin-top: 10px;
    text-align: right;
    padding-right: 10px;
    cursor: pointer;
}

.active {
    width: 200px;
    display: flex;
    margin-bottom: 10px;
    div {
        text-align: center;
        line-height: 26px;
        font-weight: bold;
    }
    div:nth-child(1) {
        background-color: #0a5544;
        font-size: 14px;
        color: white;
        width: 70px;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }
    div:nth-child(2) {
        background-color: #e0e0e0;
        font-size: 14px;
        color: #0a5544;
        width: 110px;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
}
</style>
<style>
.main-img img {
    margin-top: 50%;
    transform: translateY(-50%);
}
.main-swiper .el-carousel__arrow--right {
    right: 1rem;
}
.el-dropdown-link {
    cursor: pointer;
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}

.select-payment {
    color: #333333;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.price-title {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}

.price-item {
    font-size: 14px;
    margin-top: 10px;
}

.price-item span {
    font-size: 12px;
    color: #ec5d29;
}

.price-item-info {
    color: #333333;
    font-size: 14px;
}

.price-item-info .item {
    margin-top: 15px;
}

.price-item-info .item .price {
    margin-left: 50px;
    font-size: 14px;
}

.prev .el-icon-arrow-down {
    color: #222222;
    font-weight: bold;
    font-size: 1.125rem;
}

.main-swiper .el-carousel__container {
    height: 600px;
}
.popperDiv {
    background-color: black !important;
    color: white !important;
    width: 425px !important;
}
</style>
