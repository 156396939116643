import { render, staticRenderFns } from "./JfRibbon.vue?vue&type=template&id=86869998&scoped=true&"
import script from "./JfRibbon.vue?vue&type=script&lang=js&"
export * from "./JfRibbon.vue?vue&type=script&lang=js&"
import style0 from "./JfRibbon.vue?vue&type=style&index=0&id=86869998&prod&scoped=true&lang=scss&"
import style1 from "./JfRibbon.vue?vue&type=style&index=1&id=86869998&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86869998",
  null
  
)

export default component.exports