import request from "@/utils/request";
// 用户注册
export const userRegister = (data) =>
    request({
        url: "/auth/register",
        method: "post",
        data,
    });
// 登录
export const autoLogin = (data) =>
    request({
        url: "/auth/login",
        method: "post",
        data,
    });

// 自动登录注册
export const autoRegisterAndLogin = (data) =>
    request({
        url: "/auth/autoRegisterAndLogin",
        method: "post",
        data,
    });
// 获取手机验证码
export function authGetPhoneCode(query) {
    return request({
        url: "/auth/untoken/getPhoneCode",
        method: "get",
        params: query,
    });
}
//获取电邮验证码
export function authGetMailCode(query) {
    return request({
        url: "/auth/untoken/getMailCode",
        method: "get",
        params: query,
    });
}
// 获取用户详细信息
export function getUserInfo() {
    return request({
        url: "/system/user/getInfo",
        method: "get",
    });
}
//判断手机号码是否已经注册过
export function isRegister(query) {
    return request({
        url: "/auth/userIsExit",
        method: "get",
        params: query,
    });
}
//商家入驻(企业资质提交申请)
export function merchantSettlement(data) {
    return request({
        url: "/seller/merchant",
        method: "post",
        data: data,
    });
}
// 修改材料后提交
export function merchantSettlementAgain(data) {
    return request({
        url: "/seller/merchant/processAgain",
        method: "put",
        data: data,
    });
}

export function forgotPassword(data) {
    return request({
        url: "/auth/forgotPassword",
        method: "post",
        data: data,
    });
}

// facebook登录
export function facebookLogin(data) {
    return request({
        url: "/auth/fb/login",
        method: "post",
        data: data,
    });
}

// facebook邮箱登录
export function fbEmailLogin(data) {
    return request({
        url: "/auth/fb/fbEmailLogin",
        method: "post",
        data: data,
    });
}
