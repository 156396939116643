import request from "@/utils/request";

// 赠送好友积分
export function sendFriendsPoins(data) {
    return request({
        url: "/order/points/rewardPoints",
        method: "POST",
        data,
    });
}

/**
 * 账户信息表单数据更新
 */
export function updatePersonalInfo(data) {
    return request({
        url: "/system/member/updateMemberInfo",
        method: "POST",
        data,
    });
}

/**
 * 获取验证码
 */
export function getCode(data) {
    return request({
        url: "/system/member/sendCode",
        method: "POST",
        data,
    });
}

/**
 * 更新联系方式
 */
export function updateContact(data) {
    return request({
        url: "/system/member/updateContact",
        method: "POST",
        data,
    });
}

/**
 * 更换密码
 */
export function changePassword(data) {
    return request({
        url: "/system/member/updatePassword",
        method: "POST",
        data,
    });
}

/**
 * 搜索好友
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @param query
 */
export function getFriends({ searchWord }) {
    return request({
        url: `/system/user/selectUser?searchWord=` + searchWord,
        method: "GET",
    });
}

/**
 * 写入心愿单状态
 * @returns {Promise<axios.AxiosResponse<any>>}
 * @param data
 */
export const setWishList = (data) =>
    request({
        url: `/system/wishList`,
        method: "post",
        data,
    });

export const updateWishList = (data) =>
    request({
        url: `/system/wishList`,
        method: "put",
        data,
    });

export function friendWishList() {
    return request({
        url: `/system/wishList/friendWishList`,
        method: "get",
    });
}

/**
 * 获取心愿单状态
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getWishList = (params) =>
    request({
        url: `/system/wishList`,
        method: "get",
        params,
    });

/**
 * 心愿单助力
 */
export const wishListHelp = (data) =>
    request({
        url: `/system/like/help`,
        method: "POST",
        data,
    });

/**
 * 基础地域服务
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBaseAddressList = (data) =>
    request({
        url: `/system/user/baseAddressList`,
        method: "post",
        data,
    });

/**
 * 個人信息專用地區展示
 */
export const getProfileArea = (data) =>
    request({
        url: "system/user/dresserAddressList",
        method: "POST",
        data,
    });

/**
 * 個人信息
 * @param data
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getAccountAddress = (data) =>
    request({
        url: `/system/user/queryAddress`,
        method: "post",
        data,
    });

export const createAddress = (data) =>
    request({
        url: `/system/user/createAddress`,
        method: "post",
        data,
    });

export const updateAddress = (data) =>
    request({
        url: `/system/user/updateAddress`,
        method: "post",
        data,
    });

export const getWishDetailList = () =>
    request({
        url: `/system/wishList/wishDetailList`,
        method: "get",
    });

export const getWishDetailListForMore = (userId) =>
    request({
        url: `/system/wishList/wishDetailList/${userId}`,
        method: "get",
    });

export const deleteAddress = (data) =>
    request({
        url: `/system/user/deleteAddress`,
        method: "post",
        data,
    });

/**
 * 获取我的消息
 */
export const getMyMessage = (params) =>
    request({
        url: "/system/message/messageList",
        method: "get",
        params,
    });

/**
 * 获取我的消息-新
 */
export const getMyMessageNew = (data) =>
    request({
        url: "/system/message/memberMessageList",
        method: "post",
        data,
    });

/**
 * 获取消息详情
 * */
export function getMessageDetail(data) {
    return request({
        url: "/system/message/member/getMessageDetail",
        method: "POST",
        data,
    });
}

/**
 * 获取是否未读消息
 */
export function getMessagesUnread() {
    return request({
        url: "/system/message/member/messageCount",
        method: "POST",
    });
}

/**
 * 查询用户所有的账户信息
 */
export const getAllBankInfo = (data) =>
    request({
        url: "/system/account/getBank",
        method: "POST",
        data,
    });

/**
 * 查询用户银行信息
 */
export const getBankAccountInfo = (data) =>
    request({
        url: "/system/account/getBanksDetail",
        method: "POST",
        data,
    });

/**
 *
 */
export const updateBankInfo = (data) =>
    request({
        url: "/system/account/updateDetail",
        method: "POST",
        data,
    });

/**
 * 查询用户信息
 */
export function getUserDetailInfo() {
    return request({
        url: "/system/member/info",
        method: "POST",
    });
}

// 优惠与结余接口

/**
 * 获取账户信息
 */
export const getAccountDetail = (data) =>
    request({
        url: "/system/account/getAccount",
        method: "post",
        data,
    });

/**
 * 获取账户信息-明细
 */
export const getAccountDetailInfo = (data) =>
    request({
        url: "/system/account/getAccountLogs",
        method: "post",
        data,
    });

/**
 * 提现接口
 */
export const withDrawMoney = (data) =>
    request({
        url: "/system/account/withdraw",
        method: "post",
        data,
    });

/**
 * 查询用户积分
 */
export const getUserPoints = (data) =>
    request({
        url: "/order/points/query",
        method: "post",
        data,
    });

/**
 * 查询用户积分流水
 */
export const getUserPointsInfo = (params) =>
    request({
        url: "/order/points/queryLogs",
        method: "post",
        params,
    });

/**
 * 查詢當前用戶的入駐信息
 */
export const getUserCheckInfo = () =>
    request({
        url: "/system/sys/kol",
        method: "GET",
    });
/**
 * 新增達人入駐信息
 */
export const postTalentUserInfo = (data) =>
    request({
        url: "/system/sys/kol",
        method: "POST",
        data,
    });

/**
 *  根据id查询入驻详情
 */
export const checkInfoByInfoId = (id) =>
    request({
        url: `/system/sys/kol/detail/` + id,
        method: "GET",
    });
