<template>
    <div class="quick-buy-box">
        <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link">
                {{ $t("支付方式") }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>{{ $t("支付宝") }}</el-dropdown-item>
                <el-dropdown-item>{{ $t("微信") }}</el-dropdown-item>
                <el-dropdown-item>{{ $t("银行卡") }}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <p class="select-payment">{{ $t("支付宝") }}/{{ $t("购物卡") }}</p>
        <p class="price-title">{{ $t("到手价") }}</p>
        <p class="price-item">
            HK$18，316.8 {{ $t("共享受") }} <span>20%OFF</span>
            <i
                v-if="priceItemShow"
                class="el-icon-arrow-down el-icon--right icon-arrow"
                @click="priceItemShow = false"
            ></i>
            <i
                v-if="!priceItemShow"
                class="el-icon-arrow-up el-icon--right icon-arrow"
                @click="priceItemShow = true"
            ></i>
        </p>
        <div class="price-item-info" :style="{ visibility: priceItemShow ? 'visible' : 'hidden' }">
            <el-radio label="1" class="item"
                ><span class="item-name">{{ $t("优&nbsp;惠&nbsp;卷&nbsp;") }}</span>
                <span class="price">-2289.6</span></el-radio
            >
            <br />
            <el-radio label="2" class="item"
                ><span class="item-name">{{ $t("会员积分") }}</span> <span class="price">-2289.6</span></el-radio
            >
        </div>
        <div class="seller-info">
            <p class="price-title">{{ $t("卖家ID") }}</p>
            <p class="seller-number">2742784683246238472398</p>
            <p class="seller-know">
                {{ $t("提交订单即表示同意") }} <span>{{ $t("使用条款") }}</span>
            </p>
        </div>
        <el-button type="primary" class="quick-buy-button" @click="createOrder">
            <span class="goods-button-item">{{ $t("立即支付") }}</span>
        </el-button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            priceItemShow: true,
        };
    },
    methods: {
        createOrder() {
            this.$emit("createOrder");
        },
    },
};
</script>

<style scoped>
.quick-buy-button {
    margin: 20px 0px 0px 20px;
}
</style>

<style>
.quick-buy-box .icon-arrow {
    font-size: 16px;
    font-weight: bold;
    margin-left: 12px;
    cursor: pointer;
}
.quick-buy-box {
    padding: 0 20px 20px 20px;
}

.seller-know {
    font-size: 16px;
    font-weight: bold;
    margin-top: 30px;
}

.seller-know span {
    cursor: pointer;
    text-decoration: underline;
}
</style>
