export const createOrderBody = (
    productInfo,
    isBuyNow,
    orderAddressIVO,
    orderProductInList,
    isBill,
    payMethod,
    couponPrice,
    memberPointsPrice,
    pickUpEmail,
    balanceTotal
) => {
    return {
        isBuyNow: isBuyNow, // 是否为立即购买,1：是，0： 否
        orderType: "11", // 订单类型 11:普通订单,21:活动订单,31:虚拟商品订单,33:数字商品订单,41:兑换商品订单,51:预售订单,61:COD订单,99:其他
        payment: parseInt(payMethod), // 支付方式. 1 - 支付宝
        terminal: "11", // 下单终端 11--PC端,21--WEB端,31--安卓APP,41--IOSAPP, 71--PC-EN,81--WEB-EN
        amount: parseFloat(productInfo.amount), // 实际售价的总价
        total: parseFloat(productInfo.total), // 吊牌价总价
        couponPrice: couponPrice, // 优惠券抵扣的金额
        memberPointsPrice: memberPointsPrice, // 会员积分折算金额
        memberPoints: memberPointsPrice, // 会员积分
        balanceTotal: balanceTotal,
        orderProductInList: orderProductInList,
        orderAddressIVO: orderAddressIVO,
        remark: "", // 订单买家备注
        isBill: isBill,
        pickUpEmail: pickUpEmail,
        billEmail: pickUpEmail,
    };
};

export const orderProductInList = (productItem, skuName, saleProperty, count) => {
    return {
        shopProductPriceId: productItem.shopProductPriceId,
        articleNumber: productItem.articleNumber,
        brandName: productItem.brandName,
        brandId: productItem.brandId,
        brandLogo: productItem.brandLogo,
        deliveryType: productItem.deliveryType ? 1 : 2, // 物流类型 1-自提 2-快递物流
        merchantId: productItem.merchantId, //productItem.brandId,
        productId: productItem.productId,
        skuId: productItem.skuId,
        productName: productItem.title,
        skuName: skuName,
        saleProperty: saleProperty, //skuIdResult.skuName,
        productImg: productItem.productImg,
        listPrice: parseFloat(productItem.listPrice) || 0,
        salesPrice: parseFloat(productItem.salesPrice),
        count: parseFloat(count),
        // 暂时传空
        memberPointsPrice: parseFloat(productItem.memberPointsPrice) ?? 0, // 会员积分折算金额
        memberPoints: parseFloat(productItem.memberPoints) ?? 0, // 会员积分
        couponPrice: productItem.couponPrice ? parseFloat(productItem.couponPrice) : 0, // 优惠券金额
        promotionId: 0, // 活动id(是否是促销以活动id为准).
        promotionTitle: "", // 促销名称.
        promotionType: "", // 促销类型.
        couponCode: productItem.couponCode ?? "", // 优惠券
        describe: "",
        isHighRisk: productItem.isHighRisk,
        categoryIdGroud: productItem.categoryIdGroud,
    };
};

export const orderAddress = (addressResult, bill, firstAddress, logisticsType) => {
    return {
        orderCode: "",
        orderId: 0,
        logisticsType: Number(logisticsType),
        userAddressId: addressResult.userAddressId,
        firstName: addressResult.firstName,
        lastName: addressResult.lastName,
        consignee: addressResult.firstName + addressResult.lastName,
        countryCode: addressResult.countryCode,
        country: addressResult.country,
        provinceCode: addressResult.provinceCode,
        province: addressResult.province,
        cityCode: addressResult.cityCode,
        city: addressResult.city,
        areaCode: addressResult.areaCode,
        area: addressResult.area,
        address: addressResult.address,
        postCode: "",
        mobile: `${addressResult.mobile}`,
        idCard: "",
        bill: bill,
        areaNum: addressResult.areaNum?.replace(" ", ""),
    };
};
