<template>
    <div class="bar-box">
        <div v-for="childer in navigationData" :key="childer.navigationBarId" class="bar-second-box">
            <div
                v-for="item in childer"
                :key="item.navigationBarNodeId"
                :class="{ 'bar-title': item.children, 'bar-child': !item.children, 'link': true }"
                @click="toProductList(item)"
            >
                {{ item.label }}
            </div>
        </div>
        <!--    <div class="placeholder-image">
      <el-image
          :src="require('@/assets/images/mock/9.png')"
          fit="contain"
          class="placeholder-img"
      />
      <div class="placeholder-title">
        重磅热品
      </div>
      <div class="placeholder-des">典藏包带 致敬匠心</div>
      <div class="margin-top-20">
        <span class="underline pointer">去逛逛</span>
      </div>
    </div>-->
    </div>
</template>

<script>
export default {
    props: {
        navigationData: {
            type: Array,
            default: null,
        },
        firstLevelLabel: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            category1: { title: "", child: [] },
            category2: { title: "", child: [] },
            category3: { title: "", child: [] },
            imgBox: { img: "", des: "", title: "" },
        };
    },
    methods: {
        toProductList(item) {
            if (item.searchType === 9) {
                this.$router.push("/page/brand");
            } else {
                const level1SearchBo = {
                    searchType: item.searchType,
                    searchValue: item.searchValue,
                };
                const parentSearchBo = {
                    searchType: this.firstLevelLabel.searchType,
                    searchValue: this.firstLevelLabel.searchValue,
                };
                this.$store.dispatch("web/setProductList", null);
                this.$router.push({
                    path: "/product/list",
                    query: {
                        title: this.firstLevelLabel.label,
                        category: item.label,
                        level1: encodeURIComponent(JSON.stringify(level1SearchBo)),
                        level2: encodeURIComponent(JSON.stringify([])),
                        parentSearchBo: encodeURIComponent(JSON.stringify(parentSearchBo)),
                        searchOrderType: item.searchOrderType,
                    },
                });
            }
        },
    },
};
</script>
<style scoped>
.bar-box {
    display: flex;
    width: 100%;
}

.bar-second-box {
    margin-right: 120px;
}

.bar-title {
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 25px;
    max-width: 220px;
}

.bar-child {
    margin-bottom: 18px;
    max-width: 220px;
}

.placeholder-image {
    width: 500px;
    font-size: 17px;
    color: #1a1a1a;
}

.placeholder-title {
    margin: 10px 0 10px 0;
}
</style>
