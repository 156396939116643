const merchantRouter = [
    {
        path: "/merchantpartnership",
        name: "MerchantEnter",
        component: () => import("@/views/business/merchantEnter.vue"),
    },
    {
        path: "/brandEntry",
        name: "BrandEntry",
        component: () => import("@/views/business/brandEntry.vue"),
    },
    {
        path: "/brandEntryPdf",
        name: "BrandEntryPdf",
        component: () => import("@/views/business/brandEntryPdf.vue"),
    },
];

export default merchantRouter;
