const accountRouter = {
    path: "/account",
    name: "PersonalCenter",
    // redirect: '/account/order',
    meta: { auth: true },
    component: () => import("@/views/center/index.vue"),
    children: [
        {
            path: "profile",
            name: "Profile",
            meta: { auth: true, title: "賬號信息" },
            component: () => import("@/views/center/profile/index.vue"),
        },
        {
            path: "order",
            name: "Order",
            meta: { auth: true, title: "全部訂單" },
            component: () => import("@/views/center/order/index.vue"),
        },
        {
            path: "orderSta1",
            name: "OrderSta1",
            meta: { auth: true, title: "待付款" },
            component: () => import("@/views/center/order/index.vue"),
        },
        {
            path: "orderSta2",
            name: "OrderSta2",
            meta: { auth: true, title: "待商家發貨" },
            component: () => import("@/views/center/order/index.vue"),
        },
        {
            path: "orderSta3",
            name: "OrderSta3",
            meta: { auth: true, title: "待平台發貨" },
            component: () => import("@/views/center/order/index.vue"),
        },
        {
            path: "orderSta4",
            name: "OrderSta4",
            meta: { auth: true, title: "待收貨" },
            component: () => import("@/views/center/order/index.vue"),
        },
        {
            path: "goodInfo",
            name: "GoodInfo",
            meta: { auth: true },
            component: () => import("@/views/center/order/components/orderDetail.vue"),
        },
        {
            path: "message",
            name: "message",
            meta: { auth: true, title: "全部消息" },
            component: () => import("@/views/center/message/index.vue"),
            chileren: [
                {
                    path: "detail",
                    name: "MessageDetail",
                    meta: { auth: true, title: "消息詳情" },
                    component: () => import("@/views/center/message/index.vue"),
                },
            ],
        },
        {
            path: "plantNotice",
            name: "plantNotice",
            meta: { auth: true, title: "平台通知" },
            component: () => import("@/views/center/message/index.vue"),
        },
        {
            path: "logistics",
            name: "logistics",
            meta: { auth: true, title: "交易物流" },
            component: () => import("@/views/center/message/index.vue"),
        },
        {
            path: "interaction",
            name: "interaction",
            meta: { auth: true, title: "互動消息" },
            component: () => import("@/views/center/message/index.vue"),
        },
        {
            path: "discount",
            name: "discount",
            meta: { auth: true, title: "優惠活動" },
            component: () => import("@/views/center/message/index.vue"),
        },
        {
            path: "wallet",
            name: "Wallet",
            meta: { auth: true, title: "我的錢包" },
            component: () => import("@/views/center/wallet/index.vue"),
        },
        {
            path: "withdrawaccount",
            name: "WithdrawAccount",
            meta: { auth: true, title: "收款賬戶" },
            component: () => import("@/views/center/withdrawaccount/index.vue"),
        },
        {
            path: "voucher",
            name: "Voucher",
            meta: { auth: true },
            component: () => import("@/views/center/voucher/index.vue"),
        },
        {
            path: "myaddress",
            name: "MyAddress",
            meta: { auth: true, title: "我的地址" },
            component: () => import("@/views/center/myAddress/index.vue"),
        },
        {
            path: "cityAgency",
            name: "CityAgency",
            meta: { auth: true },
            component: () => import("@/views/center/cityAgency/index.vue"),
        },
        {
            path: "invite",
            name: "Invite",
            meta: { auth: true },
            component: () => import("@/views/center/invite/index.vue"),
        },
    ],
};

export default accountRouter;
