export function addGoogleAnalytics() {
    let eventCategory = "注册"; //事件类别
    let eventAction = "PC端注册"; //事件操作
    let eventLabel = "注册人电话号码"; //事件标签
    let eventValue = 1; //事件数量
    window.gtag("event", eventAction, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue,
        send_to: "UA-XXXXX-X", //跟踪ID
    });
}
export function visitLoginPage(durationStay) {
    const eventAction = "visit_login_page";
    const eventLabel = "登录页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function visitRegisterPage(durationStay) {
    const eventAction = "visit_register_page";
    const eventLabel = "注册页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function visitProductPage(durationStay, productId) {
    const eventAction = "visit_pdp_page";
    const eventLabel = "PDP页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
        product_id: productId,
    });
}

export function visitCartPage(durationStay) {
    const eventAction = "visit_cart_page";
    const eventLabel = "购物车停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function visitOrderPage(durationStay) {
    const eventAction = "visit_order_page";
    const eventLabel = "结算页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function visitPaymentPage(durationStay, paymentType) {
    const eventAction = "visit_payment_page";
    const eventLabel = "支付页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
        payment_type: paymentType,
    });
}

export function visitSuccessPaymentPage(durationStay) {
    const eventAction = "visit_payment_success_page";
    const eventLabel = "支付成功页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function visitCommunityByIcon() {
    const eventAction = "visit_community_by_icon";
    const eventLabel = "通过icon访问社区";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}

export function visitCommunityByLife() {
    const eventAction = "visit_community_by_life";
    const eventLabel = "通过香港时尚生活访问社区";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}

export function communityCategoryClick(category) {
    const eventAction = "community_category_click";
    const eventLabel = "社区分类点击";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        event_category: category,
    });
}

export function visitCommunityPage(durationStay) {
    const eventAction = "visit_community_page";
    const eventLabel = "社区页停留时间";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        duration_stay_time: durationStay,
    });
}

export function communityInteractiveBehaviour(behaviour) {
    const eventAction = "community_interactive_behaviour";
    const eventLabel = "社区互动行为";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
        behaviour: behaviour,
    });
}

export function pickUpClickBehaviour() {
    const eventAction = "checkout_pick_up_click";
    const eventLabel = "自提提示点击";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}



// 埋点20240428

export function enter_register() {
    const eventAction = "enter_register";
    const eventLabel = "进入注册页面";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


export function complete_registration() {
    const eventAction = "complete_registration";
    const eventLabel = "完成注册";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


export function add_to_wishlist() {
    const eventAction = "add_to_wishlist";
    const eventLabel = "加入心愿单";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}

export function product_details() {
    const eventAction = "product_details";
    const eventLabel = "进入商品详情页面";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


export function buy_now() {
    const eventAction = "buy_now";
    const eventLabel = "点击立即购买";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


export function enter_order_confirmation() {
    const eventAction = "enter_order_confirmation";
    const eventLabel = "进入订单确认";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


export function click_pay_now() {
    const eventAction = "click_pay_now";
    const eventLabel = "点击立即支付";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}

export function complete_payment_process() {
    const eventAction = "complete_payment_process";
    const eventLabel = "完成支付流程";
    window.gtag("event", eventAction, {
        event_action: eventAction,
        event_label: eventLabel,
    });
}


