import request from "@/utils/request";
import { getSexType } from "@/utils/auth";

export const getGoodList = (data) =>
    request({
        url: `/search/untoken/product/search`,
        method: "post",
        data: { ...data, sexType: getSexType().label },
    });

/*
 *商品详情
 * */
export const getGoods = (params) =>
    request({
        url: `/seller/product/detailWeb/${params}`,
        method: "get",
    });

export const noStockRecord = (data) =>
    request({
        url: `/seller/noStockRecord`,
        method: "post",
        data,
    });

export const getProductByIds = (data) =>
    request({
        url: `/search/untoken/product/product`,
        method: "post",
        data,
    });

export const getRecentlyOrder = (params) =>
    request({
        url: `/order/fakeOrderData/untoken/lastOrder`,
        method: "get",
        params,
    });

export const searchAdvice = (params) =>
    request({
        url: `/search/untoken/product/searchAdvice`,
        method: "get",
        params,
    });

export const getProductById = (pid) =>
    request({
        url: `/search/untoken/product/product/${pid}`,
        method: "get",
    });

export const productActivity = (productId) =>
    request({
        url: `/seller/seckillActivity/untoken/getProductActivity?productId=${productId}`,
        method: "get",
    });
// H5 shareWishList详情
export const proDetailList = (userId) =>
    request({
        url: `/system/like/untoken/wishList?userId=${userId}`,
        method: "get",
    });
