const paymentRouter = [
    {
        path: "/payment/check/paypal",
        name: "paypal",
        meta: { title: "Paypal" },
        component: () => import("@/views/payment/paypal.vue"),
    },
];

export default paymentRouter;
