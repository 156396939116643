<template>
    <div id="app">
        <router-view :key="$route.fullPath" />
        <paymentStatus />
        <taxRatePopup />
        <drag-it-dude class="dragBox" style="left: 84%; top: 94%">
            <OnlineService class="h5-container" />
        </drag-it-dude>
    </div>
</template>
<script>
import { getLang, getPlatform, setLang, setPlatform, setSource } from "@/utils/auth";
import { setCustomerUdeskInfo } from "@/utils/udesk";
import paymentStatus from "@/views/dialog/paymentStatus.vue";
import { invitee } from "@/api/home";
import OnlineService from "@/components/OnlineService";
import { distinguishApp } from "@/utils";
import DragItDude from "vue-drag-it-dude";
import TaxRatePopup from "@/views/dialog/taxRatePopup.vue";
export default {
    components: { TaxRatePopup, paymentStatus, OnlineService, DragItDude },
    created() {
        const searchParams = new URLSearchParams(window.location.search);
        const source = searchParams.get("source");
        const inviteCode = searchParams.get("inviteCode");
        if (source) setSource(source);
        if (inviteCode) {
            invitee(inviteCode).then((val) => {
                console.log(val);
            });
        }
    },
    mounted() {
        if (!getLang()) setLang("cht");
        if (this.getToken()) {
            setCustomerUdeskInfo({ userId: this.$store.getters.name, userName: this.$store.getters.userId });
        }
        if (this.getToken()) {
            // entry api
            this.$store.dispatch("getWishListToCatch");
            this.$store.dispatch("GetUserInfo");
        }
        this.$store.dispatch("updateCartNum");
        this.$store.dispatch("getDropDownMenuByStory");

        let platform = "pc";
        if (window.innerWidth < 768) {
            platform = `h5_${distinguishApp()}`;
        }
        if (!getPlatform()) setPlatform(platform);
    },
};
</script>
<style>
#app {
    position: relative;
}
</style>
