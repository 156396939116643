const activityRouter = [
    {
        path: "/order/dentify/card/:id",
        name: "dentifyCard",
        component: () => import("@/views/activity/dentifyCard.vue"),
    },
    {
        path: "/order/dentify/lock/:id",
        name: "dentifyLock",
        component: () => import("@/views/activity/dentifyLock.vue"),
    },
    {
        path: "/specialty",
        name: "supply",
        meta: { title: "專供" },
        component: () => import("@/views/activity/specialtySupply.vue"),
    },
    {
        path: "/downloadApp",
        name: "downloadApp",
        meta: { title: "時尚生活" },
        component: () => import("@/views/activity/downloadPageApp.vue"),
    },
    {
        path: "/postShare",
        name: "postShare",
        component: () => import("@/views/activity/postShare.vue"),
    },
    {
        path: "/quicklyRegister",
        name: "quicklyRegister",
        meta: { title: "快速註冊" },
        component: () => import("@/views/activity/quickRegister.vue"),
    },
    {
        path: "/sizeGuide",
        name: "sizeGuide",
        meta: { title: "尺碼指南" },
        component: () => import("@/views/activity/sizeGuide.vue"),
    },
    {
        path: "/postNewThread",
        name: "postNewThread",
        meta: { title: "新帖子" },
        component: () => import("@/views/post/H5/postNewThread.vue"),
    },
    {
        path: "/beforeLicensePage",
        name: "beforeLicensePage",
        meta: { title: "H5鑒別引導頁" },
        component: () => import("@/views/activity/beforeLicensePage.vue"),
    },
    {
        path: "/payment/check/paypal/h5",
        name: "paypalH5",
        meta: { title: "H5Paypal頁" },
        component: () => import("@/views/payment/paypalH5.vue"),
    },
    {
        path: "/app/socialDetail",
        name: "socialDetail",
        meta: { title: "H5社区详情" },
        component: () => import("@/views/find/findDetail/detail.vue"),
    },
    {
        path: "/shareWishList",
        name: "shareWishList",
        meta: { title: "心願清單-新春活動 推薦好友有獎賞" },
        component: () => import("@/views/activity/shareWishList.vue"),
    },
    {
        path: "/collectBlessingCard",
        name: "collectBlessingCard",
        meta: { title: "FAMKOOLS新年集五福" },
        component: () => import("@/views/activity/collectBlessingCard.vue"),
    },
    {
        path: "/redPacketRain",
        name: "redPacketRain",
        meta: { title: "龍年利是雨 全港瘋搶百萬大利是" },
        component: () => import("@/views/activity/redPacketRain.vue"),
    },
    {
        path: "/redPacketRainGames",
        name: "redPacketRainGames",
        meta: { title: "龍年利是雨 全港瘋搶百萬大利是" },
        component: () => import("@/views/games/redPacketRain/index.vue"),
    },
    {
        path: "/giftLove",
        name: "giftLove",
        meta: { title: "情人節心願單" },
        component: () => import("@/views/activity/giftLove/index.vue"),
    },
    {
        path: "/globalSearch",
        name: "globalSearch",
        meta: { title: "全球搵貨" },
        component: () => import("@/views/activity/globalSearch/index.vue"),
    },
    {
        path: "/payment/h5",
        name: "paymentH5",
        meta: { title: "全球搵貨" },
        component: () => import("@/views/activity/globalSearch/paymentIframe.vue"),
    },
    {
        path: "/dolphinFeed",
        name: "dolphinFeed",
        meta: { title: "投餵海豚" },
        component: () => import("@/views/activity/dolphinFeed.vue"),
    },
    {
        path: "/linkUp",
        name: "linkUp",
        meta: { title: "連連包" },
        component: () => import("@/views/games/linkUp/index.vue"),
    },
    {
        path: "/linkUp/rule",
        name: "linkUpRule",
        meta: { title: "活動規則" },
        component: () => import("@/views/games/linkUp/rule.vue"),
    },
    {
        path: "/vip",
        name: "vip",
        meta: { title: "黑卡會員" },
        component: () => import("@/views/vip/index.vue"),
    },
];

export default activityRouter;
