import { getCartList, getMiniCartInfo } from "@/api/order";

const cart = {
    state: {
        cartNums: 0,
        cartList: [],
    },
    mutations: {
        SET_CART_NUM: (state, num) => {
            state.cartNums = num;
        },
        SET_CART_LIST: (state, data) => {
            state.cartList = data;
        },
    },
    actions: {
        updateCartNum({ commit }) {
            return new Promise((resolve) => {
                getMiniCartInfo({}).then((val) => {
                    const nums = val.data;
                    commit("SET_CART_NUM", nums);
                    resolve();
                });
            });
        },
        cleanCartNum({ commit }) {
            return new Promise((resolve) => {
                commit("SET_CART_NUM", []);
                resolve();
            });
        },
        getCartList({ commit }, data) {
            return new Promise((resolve) => {
                getCartList(data).then((val) => {
                    if (val.code === 200) {
                        commit("SET_CART_LIST", val.data);
                        resolve(val);
                    }
                });
            });
        },
    },
};

export default cart;
