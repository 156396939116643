<template>
    <div class="goods">
        <spec
            :key="pageKey"
            ref="spec"
            :recently-buy-total="recentlyBuyTotal"
            :goods="goods"
            :brand-info="brandInfo"
            @setSkuDetailList="setSkuDetailList"
        />
        <detail
            :goods="goods"
            class="product-detail"
            :sku-detail-list="skuDetailList['model'] || []"
            :sku-slogan-list="skuDetailList['slogan'] || []"
            @setTotal="setTotal"
        />
    </div>
</template>

<script>
import Spec from "./components/spec/index.vue";
import Detail from "./components/detail/index.vue";
import data from "./data.js";
import womenData from "@/views/home/data.js";
import { getGoods } from "@/api/goods";
import { getBrandInfo } from "@/api/brand";
import { visitProductPage,product_details } from "@/utils/analytics";
export default {
    name: "Goods",
    components: {
        Spec,
        Detail,
    },
    data() {
        return {
            goods: null,
            pageKey: 1,
            footprintList: [], // 足迹列表
            recommendList: [], // 相似列表
            skuDetailList: [],
            productSizeData: [],
            brandInfo: {},
            startTime: 0,
            productName: "商品详情",
            recentlyBuyTotal: 0,
        };
    },
    metaInfo() {
        return {
            title: this.productName,
            meta: [
                {
                    name: "keywords",
                    content: "",
                },
                {
                    name: "description",
                    content: "",
                },
            ],
        };
    },
    computed: {
        data() {
            return data;
        },
        womenData() {
            return womenData;
        },
    },
    created() {
        // 尺码指南接口调用
        /*    const productId = 196514;
   // this.$route.query.id
    sizeGuide({productId: productId}).then((val)=>{
      if (val.rows.length) {
        this.productSizeData = JSON.parse(val.rows[0]['productSizeData']);
        console.log(this.productSizeData)

      }
    })*/
    },
    async mounted() {
        await this.getData();
        if (this.goods.skuDetailList) {
            this.skuDetailList = Object.values(this.goods.skuDetailList)[0];
        }
        this.startTime = new Date().getTime();
    },
    beforeDestroy() {
        const currentTime = new Date().getTime();
        const stayTime = (currentTime - this.startTime) / 1000;
        const productId = this.goods.productId;
        visitProductPage(stayTime, productId);
    },
    methods: {
        async getData() {
            product_details();
            const { data } = await getGoods(this.$route.query.id);
            this.goods = data;
            this.productName = this.goods?.productTitle;
            if (this.goods?.brandId) {
                getBrandInfo(this.goods.brandId).then((val) => {
                    this.brandInfo = val.data;
                });
            }
        },
        setSkuDetailList(item) {
            this.skuDetailList = item;
        },
        setTotal(item) {
            this.recentlyBuyTotal = item;
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.goods {
    padding-top: 50px;
    width: 1440px;
    margin: 0 auto;
}
.guarantee {
    margin-bottom: 4.6875rem;
    .guarantee-box {
        width: 20.125rem;
    }
    .name {
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 0.625rem;
    }
}

.test {
    font-size: 40px;
}

.product-detail {
    margin: 50px 0 50px 0;
}
</style>
