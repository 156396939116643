class Storage {
    constructor(prefix, timer) {
        this.$prefix = prefix;
        this.$timer = timer;
        this.checkStorageSupport();
    }

    checkStorageSupport() {
        if (typeof Storage === "undefined") {
            throw new TypeError("localStorage does not support");
        }
    }

    setItem(key, value) {
        const data = {
            value,
            timer: this.$timer * 60 * 60 * 1000,
            createAt: new Date().getTime(),
        };
        localStorage.setItem(`${key}`, JSON.stringify(data));
    }

    getItem(key) {
        try {
            const storage = localStorage.getItem(`${key}`);
            if (storage) {
                const data = JSON.parse(storage);
                if (data !== null) {
                    if (data.createAt != null && data.createAt + data.timer <= new Date().getTime()) {
                        localStorage.removeItem(key);
                    } else {
                        return data.value;
                    }
                }
            }
            return null;
        } catch (err) {
            return null;
        }
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }
}

export default Storage;
