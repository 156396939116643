import request from "@/utils/request";

// 查询OSS对象存储列表
export function listOss(query) {
    return request({
        url: "/resource/oss/list",
        method: "get",
        params: query,
    });
}
// 查询OSS对象基于id串
export function listByIds(ossId) {
    return request({
        url: "/resource/oss/listByIds/" + ossId,
        method: "get",
    });
}
// 查询OSS对象基于id
export function getOssById(ossId) {
    return request({
        url: "/resource/oss/getById/" + ossId,
        method: "get",
    });
}
// 删除OSS对象存储
export function delOss(ossId) {
    return request({
        url: "/resource/oss/" + ossId,
        method: "delete",
    });
}
// 获取分组类型列表
export function listGroupTypes(query) {
    return request({
        url: "/resource/oss/group/types",
        method: "get",
        params: query,
    });
}
// 获取分组列表
export function groupTree(query) {
    return request({
        url: "/resource/oss/groupTree",
        method: "get",
        params: query,
    });
}

// 新增分组
export function addGroup(data) {
    return request({
        url: "/resource/oss/group",
        method: "post",
        data: data,
    });
}
// 修改分组
export function editGroup(data) {
    return request({
        url: "/resource/oss/group",
        method: "put",
        data: data,
    });
}

export function removeGroup(groupIds) {
    return request({
        url: "/resource/oss/group/" + groupIds,
        method: "delete",
    });
}
export function getGroup(groupId) {
    return request({
        url: "/resource/oss/group/" + groupId,
        method: "get",
    });
}

// 查询OSS对象存储列表
export function listByGroup(query) {
    return request({
        url: "/resource/oss/listByGroup",
        method: "get",
        params: query,
    });
}
