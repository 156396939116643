import productDetail from "@/views/product/productDetail/index.vue";

const productRouter = [
    {
        path: "/product/list",
        name: "GoodList",
        component: () => import("@/views/product/productList/index.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
        },
    },
    {
        path: "/product/detail",
        name: "Goods",
        component: productDetail,
    },
];

export default productRouter;
