import Vue from "vue";
import ElementUI from "element-ui";
// import 'element-ui/lib/theme-chalk/'
import PATCH_fixMouseOutsideAutoClose from "./fixMouseOutsideAutoClose.js";
import "@/assets/font_f9is1m08e0j/iconfont.css";
import "./element-variables.scss";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import JfDialog from "@/components/JfDialog/index.vue";
import JfHelp from "@/components/JfHelp/index.vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import * as filters from "./filters"; // global filters
import base from "./base.js";
import plugins from "./plugins"; // plugins
import VueClipboard from "vue-clipboard2";
import "./utils/rem";
import { getDicts } from "@/api/dict/data";
import global from "@/utils/global";
import VueMeta from "vue-meta";

// 全局方法挂载
Vue.prototype.getDicts = getDicts;
import {
    parseTime,
    resetForm,
    addDateRange,
    selectDictLabel,
    selectDictLabels,
    handleTree,
    getUploadImgUrl,
} from "@/utils/ruoyi";
// 字典数据组件
import DictData from "@/components/DictData";
// 字典标签组件
import DictTag from "@/components/DictTag";
import "./permission";

DictData.install();
// 全局组件挂载
Vue.component("DictTag", DictTag);
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.selectDictLabels = selectDictLabels;
Vue.prototype.handleTree = handleTree;
Vue.prototype.getUploadImgUrl = getUploadImgUrl;
Vue.prototype.rsaKey = "SLDfUQjCMH9JDLSd";

import ImageUpload from "@/components/ImageUpload";
import fileUpload from "@/components/fileUpload";
Vue.component("ImageUpload", ImageUpload);
Vue.component("FileUpload", fileUpload);
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.use(plugins);

const i18n = new VueI18n({
    locale: getLang() || "cht",
    silentFallbackWarn: true,
    messages: {
        zh: require("@/i18n/zh.json"),
        en: require("@/i18n/en.json"),
        cht: require("@/i18n/hk.json"),
    },
});

Vue.use(VueMeta);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(PATCH_fixMouseOutsideAutoClose);
Vue.component("JfDialog", JfDialog);
Vue.component("JfHelp", JfHelp);
Vue.use(global);
Vue.use(base);
Vue.use(VueClipboard);
import "@/icons"; // icon
import "@/styles/index.scss"; // global css
import "swiper/css/swiper.css";
import { getLang } from "@/utils/auth";

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});
Vue.use(VueAwesomeSwiper);
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
