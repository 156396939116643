<template>
    <div class="component-upload-image">
        <el-upload
            ref="imageUpload"
            multiple
            :action="uploadImgUrl"
            list-type="picture-card"
            :on-success="handleUploadSuccess"
            :before-upload="handleBeforeUpload"
            :limit="limit"
            :on-error="handleUploadError"
            :on-exceed="handleExceed"
            :on-remove="handleDelete"
            :show-file-list="true"
            :headers="headers"
            :file-list="fileList"
            :on-preview="handlePictureCardPreview"
            :class="{ hide: fileList.length >= limit }"
        >
            <i class="el-icon-plus" />
        </el-upload>

        <!-- 上传提示 -->
        <div v-if="showTip" slot="tip" class="el-upload__tip">
            {{ $t("请上传") }}
            <template v-if="limit">
                <b style="color: #f56c6c">{{ limit }}</b> {{ $t("张") }}
            </template>
            <template v-if="fileSize">
                {{ $t("大小不超过") }} <b style="color: #f56c6c">{{ fileSize }}MB</b>
            </template>
            <template v-if="fileType">
                {{ $t("格式为") }} <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
            </template>
            {{ $t("的文件") }}
        </div>

        <el-dialog :visible.sync="dialogVisible" :title="$t('预览')" width="800" append-to-body>
            <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Object, Array],
        // 图片数量限制
        limit: {
            type: Number,
            default: 5,
        },
        imgUrl: {
            type: String,
            default: "",
        },
        // 大小限制(MB)
        fileSize: {
            type: Number,
            default: 10,
        },
        // 文件类型, 例如['png', 'jpg', 'jpeg']
        fileType: {
            type: Array,
            default: () => ["png", "jpg", "jpeg"],
        },
        // 是否显示提示
        isShowTip: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            number: 0,
            uploadList: [],
            dialogImageUrl: "",
            img: "",
            dialogVisible: false,
            hideUpload: false,
            uploadImgUrl: "/prod-api/resource/oss/upload", // 上传的图片服务器地址
            headers: {
                // Authorization: "Bearer " + getToken(),
            },
            fileList: [],
        };
    },
    computed: {
        // 是否显示提示
        showTip() {
            return this.isShowTip && (this.fileType || this.fileSize);
        },
    },
    watch: {
        value: {
            async handler(val) {
                if (val) {
                    // 首先将值转为数组
                    let list = [];
                    if (Array.isArray(val)) {
                        list = val;
                    } else {
                        list = val.split(",");
                        // list.push(val)
                    }
                    this.fileList = list.map((item) => {
                        item = { url: item };
                        return item;
                    });
                } else {
                    this.fileList = [];
                    return [];
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.img = this.imgUrl;
    },
    methods: {
        // 上传前loading加载
        handleBeforeUpload(file) {
            let isImg = false;
            this.$emit("testhandle", file);
            if (this.fileType.length) {
                let fileExtension = "";
                if (file.name.lastIndexOf(".") > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
                }
                isImg = this.fileType.some((type) => {
                    if (file.type.indexOf(type) > -1) return true;
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                    return false;
                });
            } else {
                isImg = file.type.indexOf("image") > -1;
            }

            if (!isImg) {
                // this.$modal.error(`文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!`);
                this.$modal.msgError(this.$t("文件格式不正确, 请上传") + `${this.fileType.join("/")}图片格式文件!`);
                // this.$message({
                // message:`文件格式不正确, 请上传${this.fileType.join("/")}图片格式文件!!`
                // })
                return false;
            }
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize;
                if (!isLt) {
                    // this.$modal.error(`上传头像图片大小不能超过 ${this.fileSize} MB!`);
                    //   this.$message({
                    // message:`上传头像图片大小不能超过 ${this.fileSize} MB!`
                    // })

                    this.$modal.msgError(this.$t("上传头像图片大小不能超过") + ` ${this.fileSize} MB!`);
                    return false;
                }
            }
            this.number++;
            this.$modal.loading(this.$t("正在上传图片，请稍候") + "...");
        },
        // 文件个数超出
        handleExceed() {
            this.$message({
                message: this.$t("上传文件数量不能超过") + ` ${this.limit} ` + this.$t("个") + `!`,
            });
            // this.$modal.error(`上传文件数量不能超过 ${this.limit} 个!`);

            this.$modal.msgError(this.$t("上传文件数量不能超过") + ` ${this.limit}!` + this.$t("个"));
        },
        // 上传成功回调
        handleUploadSuccess(res, file) {
            if (res.code === 200) {
                this.uploadList.push({ name: res.data.fileName, url: res.data.url, ossId: res.data.ossId });
                this.uploadedSuccessfully();
            } else {
                this.number--;
                // this.$message.closeLoading();

                // this.$modal.closeLoading();
                // this.$modal.error(res.msg);

                this.$modal.msgError(res.msg);
                this.$refs.imageUpload.handleRemove(file);
                this.uploadedSuccessfully();
            }
        },
        // 删除图片
        handleDelete(file) {
            const findex = this.fileList.map((f) => f.name).indexOf(file.name);
            if (findex > -1) {
                this.fileList.splice(findex, 1);
                this.$emit("input", this.listToString(this.fileList));
            }
        },
        // 上传失败
        handleUploadError() {
            // this.$message.error("上传图片失败，请重试");

            this.$modal.msgError(this.$t("上传图片失败，请重试"));
            // this.$message.closeLoading();

            this.$modal.closeLoading();
        },
        // 上传结束处理
        uploadedSuccessfully() {
            if (this.number > 0 && this.uploadList.length === this.number) {
                this.fileList = this.fileList.concat(this.uploadList);
                this.uploadList = [];
                this.number = 0;
                this.$emit("input", this.listToString(this.fileList));
                this.$modal.closeLoading();
                // this.$message.closeLoading();
            }
        },
        // 预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 对象转成指定字符串分隔
        listToString(list, separator) {
            let strs = "";
            separator = separator || ",";
            for (let i in list) {
                if (list[i].url) {
                    strs += list[i].url + separator;
                }
            }
            return strs != "" ? strs.substr(0, strs.length - 1) : "";
        },
    },
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter,
.el-list-leave-active {
    opacity: 0;
    transform: translateY(0);
}
</style>
