<template>
    <div>
        <template v-for="(item, index) in options">
            <template v-if="values.includes(item.value)">
                <span
                    v-if="item.raw.listClass == 'default' || item.raw.listClass == ''"
                    :key="item.value"
                    :index="index"
                    :class="item.raw.cssClass"
                    >{{ item.label + " " }}</span
                >
                <el-tag
                    v-else
                    :key="item.value"
                    :disable-transitions="true"
                    :index="index"
                    :type="item.raw.listClass == 'primary' ? '' : item.raw.listClass"
                    :class="item.raw.cssClass"
                >
                    {{ item.label + " " }}
                </el-tag>
            </template>
        </template>
        <template v-if="unmatch && showValue">
            {{ unmatchArray | handleArray }}
        </template>
    </div>
</template>

<script>
export default {
    name: "DictTag",
    filters: {
        handleArray(array) {
            if (array.length === 0) return "";
            return array.reduce((pre, cur) => {
                return pre + " " + cur;
            });
        },
    },
    props: {
        options: {
            type: Array,
            default: null,
        },
        value: [Number, String, Array],
        // 当未找到匹配的数据时，显示value
        showValue: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            unmatchArray: [], // 记录未匹配的项
        };
    },
    computed: {
        values() {
            if (this.value !== null && typeof this.value !== "undefined") {
                return Array.isArray(this.value) ? this.value : [String(this.value)];
            } else {
                return [];
            }
        },
        unmatch: {
            set: function () {
                this.unmatchArray = [];
                if (this.value !== null && typeof this.value !== "undefined") {
                    // 传入值为非数组
                    if (!Array.isArray(this.value)) {
                        if (this.options.some((v) => v.value == this.value)) return false;
                        this.unmatchArray.push(this.value);
                        return true;
                    }
                    // 传入值为Array
                    this.value.forEach((item) => {
                        if (!this.options.some((v) => v.value == item)) this.unmatchArray.push(item);
                    });
                    return true;
                }
                // 没有value不显示
                return false;
            },
        },
    },
};
</script>
<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}
</style>
