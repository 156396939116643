import request from "@/utils/request";

// 获取手机验证码
export function getPhoneCode(query) {
    return request({
        url: "/auth/getPhoneCode",
        method: "get",
        params: query,
    });
}

/**
 * 获取发货地
 * @param query
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getChannelList(query) {
    return request({
        url: `/system/dict/data/type/untoken/${query}`,
        method: "get",
    });
}

/**
 * 获取预计收入
 * @param query
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getProductMerchantPrice(query) {
    return request({
        url: `/seller/productMerchantPrice/getPriceLimit`,
        method: "get",
        params: query,
    });
}

/**
 * 获取支付保证金
 * @param query
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export function getMarginPrice(query) {
    return request({
        url: `/seller/margin/getMargin`,
        method: "get",
        params: query,
    });
}

export function postMerchantPrice(data) {
    return request({
        url: `/seller/productMerchantPrice`,
        method: "post",
        data,
    });
}

export function getMarginInfo(marginId) {
    return request({
        url: `/seller/margin/${marginId}`,
        method: "get",
    });
}
