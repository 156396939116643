import { getWishList, setWishList } from "@/api/account";
import { getDropDownMenu } from "@/api/category";
import { getSexType } from "@/utils/auth";
import { add_to_wishlist } from "@/utils/analytics";

const product = {
    state: {
        collectNums: [],
        dropDownMenuData: [],
        first_level_navigation: [],
        couponStatus: 0,
    },
    mutations: {
        SET_COLLECT_NUMS: (state, result) => {
            state.collectNums = result;
        },
        SET_DOWN_MENU_DATA: (state, result) => {
            state.dropDownMenuData = result;
        },
        SET_FIRST_LEVEL_NAVIGATION: (state, result) => {
            state.first_level_navigation = result;
        },
    },
    actions: {
        changeCollect({ commit }, data) {
            return new Promise((resolve) => {
                setWishList(data).then((res) => {
                    if (res.code === 200) {
                        add_to_wishlist();
                        getWishList({}).then((val) => {
                            if (val.code === 200) {
                                commit("SET_COLLECT_NUMS", val.data);
                                resolve(val.data);
                            }
                        });
                    }
                });
            });
        },
        getWishListToCatch({ commit }) {
            return new Promise((resolve) => {
                getWishList({}).then((val) => {
                    if (val.code === 200) {
                        commit("SET_COLLECT_NUMS", val.data);
                        resolve(val.data);
                    }
                });
            });
        },
        resetWishListToCatch({ commit }) {
            return new Promise(() => {
                commit("SET_COLLECT_NUMS", []);
            });
        },
        getDropDownMenuByStory({ commit }) {
            return new Promise((resolve) => {
                getDropDownMenu().then((val) => {
                    if (val.code === 200) {
                        const type = getSexType().label;
                        const first_level_navigation = val.data[type].map((item) => {
                            return {
                                label: item.label,
                                searchOrderType: item.searchOrderType,
                                navigationBarId: item.navigationBarId,
                                searchType: item.searchType,
                                searchValue: item.searchValue,
                            };
                        });
                        commit("SET_FIRST_LEVEL_NAVIGATION", first_level_navigation);
                        commit("SET_DOWN_MENU_DATA", val.data);
                        resolve(val.data);
                    }
                });
            });
        },
    },
};

export default product;
