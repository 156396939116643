<template>
    <div class="navbar">
        <div class="navbar-left">
            <div
                v-for="(item, index) in firstLevelNavigation"
                :key="index"
                :class="{
                    navbarItemHover: barIndex !== index && barIndex !== -1,
                    firstChildColor: item.label === typeLabel && barIndex === -1,
                }"
                class="navbar-item pointer no-select"
                @mouseenter="
                    (e) => {
                        barChange(e, item.navigationBarId);
                    }
                "
                @click="to(item)"
            >
                {{ item.label }}
            </div>
        </div>
        <div class="navbar-right">
            <div class="search no-select" @click="searchDrawer = true">
                <div class="text">
                    {{ $t("专业鉴别，全新正品") }}
                </div>
                <svg-icon icon-class="search" />
            </div>
        </div>
        <el-drawer
            :visible.sync="searchDrawer"
            direction="ttb"
            append-to-body
            :before-close="searchDrawerClose"
            class="searchDrawer"
        >
            <div class="drawer-content">
                <el-image :src="require('@/assets/images/logo.png')" />
                <el-input
                    v-model="searchKey"
                    :placeholder="$t('输入品牌或产品名称,搜寻Famkools为您精心准备的商品')"
                    prefix-icon="search"
                    clearable
                    class="input-1"
                    style=""
                    @clear="searchKey = ''"
                    @keyup.enter.native="searchCategory"
                >
                    <div slot="prefix" style="line-height: 2.8125rem; margin-left: 0.625rem; font-size: 1.5rem">
                        <svg-icon icon-class="search" />
                    </div>
                </el-input>
                <div style="padding-bottom: 1rem; padding-top: 1rem">
                    <div
                        v-for="(item, index) in searchList"
                        :key="index"
                        class="searchLine"
                        @click="toProductDetail(item.productId)"
                    >
                        <div class="img">
                            <img width="100%" height="99%" :src="item.productImg" alt="" />
                        </div>
                        <div class="title">
                            {{ item.productTitle }}
                        </div>
                        <div class="brand">{{ item.brandName }}</div>
                    </div>
                </div>
            </div>
        </el-drawer>
        <el-drawer
            :visible.sync="barDrawer"
            :with-header="false"
            direction="ttb"
            append-to-body
            :modal="false"
            size="26%"
            class="category-box"
            :class="{ categoryBox: topShow, categoryBoxLess: !topShow }"
        >
            <div class="classify space-between" @mouseleave="barLeave">
                <secondaryBar :navigation-data="navigationSecondResult" :first-level-label="first_level_label" />
            </div>
            <div class="jf-modal" />
        </el-drawer>
    </div>
</template>
<script>
import secondaryBar from "./secondaryBar.vue";
import type from "postcss-pxtorem/lib/type";
import { getSexType } from "@/utils/auth";
import debounce from "lodash.debounce";
import { searchAdvice } from "@/api/goods";
export default {
    name: "JfNavbar",
    components: { secondaryBar },
    data() {
        return {
            searchDrawer: false,
            barDrawer: false,
            barDrawerTop: "152px",
            topShow: true,
            searchKey: "",
            typeLabel: "",
            navigationSecondResult: [],
            barIndex: -1,
            first_level_label: {},
            searchList: [],
        };
    },
    computed: {
        type() {
            return type;
        },
        dropDownMenuData() {
            return this.$store.getters.dropDownMenuData;
        },
        firstLevelNavigation() {
            return this.$store.getters.firstLevelNavigation;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.title) {
                    this.typeLabel = this.$route.query.title;
                }
            },
        },
        searchKey(...args) {
            this.debouncedWatch(...args);
        },
    },
    mounted() {
        document.body.addEventListener("wheel", this.preventScroll, {
            passive: false,
        });
        const that = this;
        window.addEventListener("mousemove", function (e) {
            const offsetTopDom = document.querySelector(".app-main");
            if (offsetTopDom) {
                const offsetTop = offsetTopDom.offsetTop;
                const noticeBox = document.getElementById("notice-box");
                let boundaryValue = offsetTop < 32 ? 40 : 80;
                boundaryValue = noticeBox ? boundaryValue : 50;
                if (e.clientY < boundaryValue) that.barLeave();
            }
        });
    },
    beforeDestroy() {
        document.body.removeEventListener("wheel", this.preventScroll);
    },
    created() {
        this.debouncedWatch = debounce((newValue) => {
            searchAdvice({ searchWord: newValue }).then((item) => {
                this.searchList = item.data.list;
            });
        }, 500);
    },
    beforeUnmount() {
        this.debouncedWatch.cancel();
    },
    methods: {
        searchDrawerClose() {
            this.searchList = [];
            this.searchKey = "";
            this.searchDrawer = false;
        },
        searchCategory() {
            this.$router.push({ path: "/product/list", query: { productName: this.searchKey } });
        },
        barLeave(e) {
            if (e && e.clientY < 200) return;
            if (window.innerWidth < 1200) {
                if (e && e.clientY < 650) {
                    this.barIndex = -1;
                    this.barDrawer = false;
                }
            } else {
                this.barIndex = -1;
                this.barDrawer = false;
            }
        },
        preventScroll(event) {
            if (this.barDrawer) {
                event.preventDefault();
            }
        },
        toProductDetail(id) {
            this.$router.push(`/product/detail?id=${id}`);
        },
        to(item) {
            if ([23, 27].includes(item.navigationBarId)) {
                // 至臻精选跳社区
                this.$router.push({
                    path: "/findList",
                    query: {
                        title: item.label,
                        socialTypeId: 133,
                    },
                });
                return;
            }
            if ([25, 1].includes(item.navigationBarId)) {
                // 点击品牌跳转全部品牌页
                this.$router.push({
                    path: "/page/brand",
                    query: {
                        title: item.label,
                        socialTypeId: 133,
                    },
                });
                return;
            }
            const level1SearchBo = {
                searchType: item.searchType,
                searchValue: item.searchValue,
            };
            this.$store.dispatch("web/setProductList", null);
            this.$router.push({
                path: "/product/list",
                query: {
                    title: item.label,
                    level1: encodeURIComponent(JSON.stringify(level1SearchBo)),
                    level2: encodeURIComponent(JSON.stringify([])),
                    searchOrderType: item.searchOrderType,
                },
            });
        },
        barChange(e, navigationBarId) {
            this.barIndex = e.target.dataset.index;
            const offsetTop = document.querySelector(".app-main").offsetTop;
            this.topShow = offsetTop > 32;
            const noticeBox = document.getElementById("notice-box");
            if (!noticeBox) {
                this.topShow = false;
            }

            const type = getSexType().label;

            // 组装分类树
            const navigationSecond = this.dropDownMenuData[type].filter(
                (item) => item.navigationBarId == navigationBarId
            );
            this.first_level_label = navigationSecond[0];
            const navigationSecondResult = [];
            let secondResult = [];

            const setNavigationSecond = () => {
                if (secondResult.length > 8) {
                    navigationSecondResult.push(secondResult);
                    secondResult = [];
                }
            };

            navigationSecond[0].children.forEach((val) => {
                secondResult.push(val);
                if (val.children) {
                    val.children.forEach((item) => {
                        secondResult.push(item);
                        setNavigationSecond();
                    });
                    if (secondResult.length) {
                        // 有子类说明是有title，则换行
                        navigationSecondResult.push(secondResult);
                        secondResult = [];
                    }
                } else {
                    // 说明是一级，则不用换行
                    setNavigationSecond();
                }
            });
            if (secondResult.length) navigationSecondResult.push(secondResult);
            this.navigationSecondResult = navigationSecondResult;

            if (this.navigationSecondResult.length) {
                this.barDrawer = !(
                    !this.navigationSecondResult[0][0].children && this.navigationSecondResult.length === 1
                );
            } else {
                this.barDrawer = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.category-box {
    height: 1900px;
}

.categoryBox {
    top: 160px;
}

.categoryBoxLess {
    top: 120px;
}

.navbar {
    margin-top: 0.625rem;
    white-space: nowrap;

    .navbar-left {
        display: flex;

        .navbar-item {
            font-size: 16px;
            margin-right: 30px;
        }

        .navbar-item:hover,
        .navbar-item:first-child:hover {
            color: #222222;
        }

        .navbarItemHover {
            color: #999999;
        }

        .firstChildColor {
            color: #ee5e6e;
        }
    }

    .navbar-right {
        .search {
            width: 16.125rem;
            height: 2.8125rem;
            border: 1px solid #d9d9d9;
            border-radius: 0.1875rem;
            color: #929292;
            font-size: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.625rem;
            cursor: text;

            .text {
                overflow: hidden;
                margin-right: 0.625rem;
            }

            .svg-icon {
                color: #000000;
                flex-shrink: 0;
            }
        }

        .search:hover {
            border: 1px solid #b4b2b2;
        }
    }
}

.drawer-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .el-image {
        width: 13.125rem;
    }

    .el-input {
        width: 41.25rem;
        margin-top: 2.5rem;

        ::v-deep .el-input--prefix .el-input__inner {
            padding-left: 4.6875rem;
        }
    }
}

::v-deep .el-input--prefix .el-input__inner {
    padding-left: 3.125rem;
}

.jf-modal {
    position: fixed;
    top: 600px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    overflow: hidden;
}

.classify {
    width: $main-width;
    margin: 0 auto;
    padding: 1.875rem 0;
    border-top: 1px solid #ccc;
}

.searchLine .title:hover {
    text-decoration: underline;
}

.searchLine {
    display: flex;
    width: 650px;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(16, 76, 61);
    .title {
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    }
    .brand {
        font-weight: bold;
        text-align: right;
        width: 25%;
    }
    .img {
        width: 12%;
        height: 60px;
        position: relative;
        margin-right: 3%;
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>
<style>
.searchDrawer .el-drawer {
    height: auto !important;
    max-height: 850px;
}
</style>
