const statementRouter = [
    {
        path: "/statement/delivery",
        name: "delivery",
        component: () => import("@/views/statement/delivery.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "配送說明",
        },
    },
    {
        path: "/statement/disclaimer",
        name: "disclaimer",
        component: () => import("@/views/statement/disclaimer.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "免責聲明",
        },
    },
    {
        path: "/statement/payment",
        name: "statementPayment",
        component: () => import("@/views/statement/payment.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "付款方式",
        },
    },
    {
        path: "/statement/return",
        name: "return",
        component: () => import("@/views/statement/return.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "退貨政策",
        },
    },
    {
        path: "/statement/note",
        name: "note",
        component: () => import("@/views/statement/notesToBuyers.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "使用條款及細則",
        },
    },
    {
        path: "/statement/aboutUs",
        name: "aboutus",
        component: () => import("@/views/statement/abountUs.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "關於我們",
        },
    },
    {
        path: "/statement/shoppingGuide",
        name: "shoppingGuide",
        component: () => import("@/views/statement/shoppingGuide.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "購物流程",
        },
    },
    {
        path: "/statement/affiliate",
        name: "affiliate",
        component: () => import("@/views/statement/affiliate.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "聯盟營銷",
        },
    },
    {
        path: "/statement/privacy",
        name: "privacy",
        component: () => import("@/views/statement/privacy.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "私隱條款",
        },
    },
    {
        path: "/statement/partnership",
        name: "partnership",
        component: () => import("@/views/statement/partnership.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "達人入駐",
        },
    },
    {
        path: "/statement/membership",
        name: "membership",
        component: () => import("@/views/statement/membership.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "會員權益",
        },
    },
    {
        path: "/statement/FAQs",
        name: "FAQs",
        component: () => import("@/views/statement/FAQs.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "常見問題",
        },
    },
    {
        path: "/statement/careers",
        name: "careers",
        component: () => import("@/views/statement/careers.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "工作機會",
        },
    },
    {
        path: "/statement/platformMind",
        name: "platformMind",
        component: () => import("@/views/activity/platformMind.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "平台心智",
        },
    },
    {
        path: "/statement/lottery",
        name: "lottery",
        component: () => import("@/views/activity/lottery.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "註冊激獎",
        },
    },
    {
        path: "/statement/coupon",
        name: "lottery",
        component: () => import("@/views/activity/coupon.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "優惠卷大放送",
        },
    },
    {
        path: "/statement/liveLottery",
        name: "liveLottery",
        component: () => import("@/views/activity/liveLottery.vue"),
        meta: {
            keepAlive: true, // 需要被缓存
            title: "直播抽獎",
        },
    },
    {
        path: "/statement/wishListPoster",
        name: "wishListPoster",
        meta: { title: "心願清單 推薦好友有獎賞" },
        component: () => import("@/views/activity/wishListPoster.vue"),
    },
    {
        path: "/statement/collectFiveBlessing",
        name: "collectFiveBlessing",
        meta: { title: "百萬大獎齊齊分" },
        component: () => import("@/views/activity/collectFiveBlessing.vue"),
    },
];

export default statementRouter;
