<template>
    <el-dialog
        :visible="visible"
        v-bind="$attrs"
        append-to-body
        :width="width"
        class="dialog"
        :show-close="false"
        lock-scroll
        v-on="$listeners"
        @close="$emit('close')"
    >
        <div class="title" :style="{ color: color }">
            {{ title }}
        </div>
        <i class="el-icon-close close hover" @click="$emit('close')" />
        <slot />
    </el-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "FAMKOOLS",
        },
        visible: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "#000",
        },
        width: {
            type: String,
            default: "25rem",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped lang="scss">
.dialog {
    display: flex;
    .title {
        font-size: 1.375rem;
        color: #000000;
        position: absolute;
        top: 1.0625rem;
    }

    .close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        font-size: 1.5rem;
        color: #000000;
    }
}
::v-deep {
    .el-dialog {
        margin: auto !important;
        border-radius: 0.5rem !important;
    }
    .el-dialog__body {
        padding: 1.25rem 1.25rem 1.875rem !important;
    }
}
</style>
