import request from "@/utils/request";
import { getSexType } from "@/utils/auth";
// 获取分类
export const categoryList = (params) =>
    request({
        url: `/seller/category/untoken/app/list`,
        method: "get",
        params,
    });

// 商品列表分类聚合接口
export const searchCriteria = (data) =>
    request({
        url: `/search/untoken/product/searchCriteria`,
        method: "post",
        data: { ...data, sexType: getSexType().label },
    });

// 二级下拉列
export const getDropDownMenu = (params) =>
    request({
        url: `/seller/bar/untoken?channelType=pc`,
        method: "get",
        params,
    });

// 尺码指南
export const sizeGuide = (params) =>
    request({
        url: `/seller/productSizeData/untoken/list`,
        method: "get",
        params,
    });

export const getWishListProduct = (params) =>
    request({
        url: `/system/like/untoken/getAcvitityProduct`,
        method: "get",
        params,
    });
