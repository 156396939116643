<template>
    <div class="screen-bar space-between">
        <div class="select-bar" @click="orderItemShow = !orderItemShow">
            {{ selectItem }}
            <i v-if="!orderItemShow" class="el-icon-arrow-down el-icon--right icon-arrow"></i>
            <i v-if="orderItemShow" class="el-icon-arrow-up el-icon--right icon-arrow"></i>
        </div>
        <div v-if="orderItemShow" class="select-bar-child">
            <div v-for="(item, index) in select" :key="index" @click="selectSort(item)">{{ item.label }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        select: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            orderItemShow: false,
            selectItem: this.$t("请选择出货地"),
            selectId: "",
        };
    },
    mounted() {
        const searchOrderType = this.$route.query.searchOrderType || 1;
        if (searchOrderType) {
            const sortResult = this.select.filter((item) => item.id === Number(searchOrderType));
            this.selectItem = sortResult[0].label;
            this.selectId = sortResult[0].id;
        }
    },
    methods: {
        selectSort(v) {
            if (this.$route.query.searchOrderType) {
                this.$router.push({
                    path: this.$route.fullPath,
                    query: {
                        searchOrderType: v.id,
                        pageNum: 1,
                    },
                });
            } else {
                this.orderItemShow = false;
                this.selectItem = v.label;
                this.selectId = v.id;
                this.$emit("changeSelect", v);
            }
        },
    },
};
</script>
<style scoped lang="scss">
@import "~@/styles/variables";
.screen-bar {
    position: relative;
}

.select-bar,
.select-bar-child div {
    width: 188px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;
    cursor: pointer;
}

.screen-bar .select-bar-child {
    position: absolute;
    z-index: 99;
    top: 38px;
    border: 1px solid #e6e6e6;
}

.select-bar-child div {
    background-color: white;
    border: none;
}

.select-bar-child div:hover {
    background-color: #f5f5f5;
}
</style>
