import request from "@/utils/request";
import { getSexType } from "@/utils/auth";

export const getHomePageCms = () =>
    request({
        url: `/search/resourceLocation/getResourceLocation`,
        method: "get",
        params: { channelType: "pc", sexType: getSexType().label },
    });

export const getInviteCode = (data) =>
    request({
        url: `/system/invite`,
        method: "post",
        data,
    });

export const invitee = (inviteCode) =>
    request({
        url: `/system/invitee/untoken?inviteCode=${inviteCode}`,
        method: "get",
    });

export const getShareLink = () =>
    request({
        url: `/system/like/share`,
        method: "get",
    });

export const lotteryList = () =>
    request({
        url: `/system/prize/getWinnersList`,
        method: "post",
    });

export const getInviteCodeForPrize = (data) =>
    request({
        url: `/system/prize/createInviteCode`,
        method: "post",
        data,
    });

export const postShortLink = (data) =>
    request({
        url: `/system/link/untoken/footprints`,
        method: "post",
        data,
    });
