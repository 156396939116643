const state = {
    subscriberVisible: false,
    navbar: true,
    goodList: null,
    find: false,
};

const mutations = {
    SET_SUBSCRIBER: (state, value) => {
        state.subscriberVisible = value;
    },
    SET_NAVBAR: (state, value) => {
        state.navbar = value;
    },
    SET_FIND: (state, value) => {
        state.find = value;
    },
    SET_GOOD_LIST: (state, value) => {
        state.goodList = value;
    },
};

const actions = {
    setSubscriberVisible({ commit }, value) {
        commit("SET_SUBSCRIBER", value);
    },
    SET_NAVBAR({ commit }, value) {
        commit("SET_NAVBAR", value);
    },
    setProductList({ commit }, value) {
        commit("SET_GOOD_LIST", value);
    },
    SET_FIND({ commit }, value) {
        commit("SET_FIND", value);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
