<template>
    <div>
        <size-dialog :visible.sync="visibleDialog" :goods="product" @close="visibleDialog = false" />
        <div v-for="(skuItem, indexSku) in skuList" :key="indexSku" class="product-size">
            <div class="size-select" style="justify-content: space-between">
                <div>
                    {{ skuItem.skuLabel
                    }}<!--: <span>{{ skuItem.skuSelect }}</span>-->
                </div>
                <span v-if="indexSku === 0" class="size-guide" @click="visibleDialog = true">
                    <img style="width: 1.2rem" src="@/assets/images/goods/rule.png" />{{ $t("尺码指南") }}
                </span>
            </div>
            <div class="size-box">
                <span
                    v-for="(item, index) in skuItem.skuValue"
                    :key="index"
                    :class="{ 'size-select-box': skuItem.skuSelect === item, 'disable-box': disableSku(item) }"
                    @click="selectSize(item, indexSku)"
                    >{{ item }}</span
                >
            </div>
        </div>

        <div v-if="channelValue.length" class="product-size channel">
            <div class="size-select" style="justify-content: space-between">
                <div>
                    {{ $t("出货地")
                    }}<!--: <span>{{channel}}</span>-->
                </div>
            </div>
            <div class="size-box">
                <span
                    v-for="(item, index) in channelValue"
                    :key="index"
                    :class="{ 'size-select-box': channel === `${item.goodsChannelName}` }"
                    @click="selectChannel(item, index)"
                >
                    {{ item.goodsChannelName }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import SizeDialog from "@/views/product/productDetail/components/sizeDialog/index.vue";

export default {
    components: { SizeDialog },
    props: {
        product: {
            type: Object,
            default: null,
        },
        skuId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            skuName: [],
            skuList: [],
            sizeValue: [],
            size: "均码",
            sizeName: "尺码",
            skuDetailList: [],
            channel: "香港 HK$12231234",
            channelValue: [],
            productPrice: 0,
            visibleDialog: false,
            shopProductPriceId: "",
        };
    },
    watch: {
        product: function () {
            this.initSkuSelect();
        },
    },
    mounted() {
        this.initSkuSelect();
    },
    methods: {
        disableSku(skuName) {
            const result = this.product.skuList.filter((item) => {
                return item.priceList && item.skuNameList.includes(skuName);
            });
            return !result.length;
        },
        selectChannel(item) {
            this.channel = item.goodsChannelName;
            this.productPrice = item.price;
            this.shopProductPriceId = item.shopProductPriceId;
            if (this.productPrice) {
                this.$emit("selectChannelPrice", this.productPrice);
                this.$emit("missProduct", false);
            }
        },
        initSkuSelect() {
            this.skuList = [];
            let selectSkuItem = this.product.skuList?.filter((item) => item.skuId === this.skuId);

            if (!this.skuId) {
                // 默认选中有价的数据
                const selectSkuItemResult = this.product.skuList?.find((item) => item.priceList);
                selectSkuItem = selectSkuItemResult ? [selectSkuItemResult] : [];
            }

            this.product.skuAttrList.forEach((item, index) => {
                this.sizeValue = JSON.parse(item.skuAttrValues);

                let selectValue = this.sizeValue[0];
                if (selectSkuItem.length) {
                    selectValue = selectSkuItem[0].skuNameList[index];
                }
                this.size = selectValue;
                this.sizeName = item.skuAttrName;
                const sku = {
                    skuLabel: item.skuAttrName,
                    skuSelect: selectValue,
                    skuValue: JSON.parse(item.skuAttrValues),
                };
                this.skuList.push(sku);
                this.getSkuItem();
            });
        },
        selectSize(item, index) {
            this.skuList[index].skuSelect = item;
            this.shopProductPriceId = "";
            this.getSkuItem();
        },
        getSkuItem() {
            const skuIdResult = [];
            const skuSet = this.skuList?.map((item) => item.skuSelect);
            this.product.skuList?.forEach((item) => {
                let diff = item.skuNameList.filter(function (val) {
                    return skuSet.indexOf(val) === -1;
                });
                if (!diff.length) skuIdResult.push(item);
            });
            if (skuIdResult.length) {
                const channelResult = skuIdResult[0].priceList;
                this.skuDetailList = skuIdResult[0].skuImag?.product;
                this.$emit("setParentSkuDetailList", skuIdResult[0].skuImag);
                if (channelResult) {
                    if (!this.shopProductPriceId) {
                        // 店铺id存在则不重新赋值
                        this.channelValue = channelResult;
                        this.channel = `${channelResult[0].goodsChannelName}`;
                        this.productPrice = channelResult[0].price;
                        this.shopProductPriceId = channelResult[0].shopProductPriceId;
                        this.$emit("selectChannelPrice", this.productPrice);
                        this.$emit("missProduct", false);
                    }
                } else {
                    this.shopProductPriceId = "";
                    this.channelValue = [];
                    this.$emit("missProduct", true);
                }
            }
            return skuIdResult;
        },
    },
};
</script>
<style scoped lang="scss">
.product-size {
    div {
        display: flex;
    }
}

.disable-box {
    background-color: #f7f6f6;
    color: #999999;
}

.size-select {
    margin-top: 15px;
}

.size-guide {
    color: #999999;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
}

.size-guide img {
    margin-right: 8px;
}

.size-box {
    flex-wrap: wrap;
}

.size-box span {
    display: block;
    height: auto;
    min-width: 90px;
    margin-right: 10px;
    border: 1px solid #e5e4e4;
    text-align: center;
    line-height: 45px;
    border-radius: 10px;
    margin-top: 12px;
    cursor: pointer;
    padding: 0 10px 0 10px;
}

.channel {
    margin-top: 25px;
}

.channel-box span {
    display: block;
    height: 45px;
    min-width: 90px;
    margin-right: 10px;
    border: 1px solid #e5e4e4;
    text-align: center;
    border-radius: 10px;
    margin-top: 12px;
    cursor: pointer;
    padding: 8px 8px 8px 8px;
}

.size-select-box {
    border: 1px solid black !important;
}

.size-select {
    margin-top: 20px;
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 600;
    span {
        font-weight: 400;
        padding-left: 12px;
    }
}
</style>
