import { getUserInfo, autoLogin } from "@/api/login";
import { getToken, removeToken, getUserName, setToken, setUserName, setUserInfo, getTaxPopup } from "@/utils/auth";
import globalStorage from "@/storage/global-storage.js";
import { getPoints, getQueryUser } from "@/api/order";

const user = {
    state: {
        avatar: "",
        roles: [],
        permissions: [],
        roleData: [],
        token: getToken() || "",
        name: getUserName() || "",
        role: globalStorage.getItem("role") || [],
        menu: globalStorage.getItem("menu") || [],
        userInfo: globalStorage.getItem("userInfo") || {},
        currentRole: globalStorage.getItem("currentRole") || "",
        userCouponList: [],
        userCouponListIsNull: false,
        userServiceListIsNull: false,
        userServiceList: [],
        userPointsInfo: {},
        userId: "",
        showTaxPopup: 0,
        taxPopupValidate: false,
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
            setToken(token);
        },
        SET_SERVICE_LIST: (state, data) => {
            state.userServiceList = data;
            if (state.userServiceList.length < 1) {
                state.userServiceListIsNull = true;
            } else {
                state.userServiceListIsNull = false;
            }
        },
        SET_COUPON_LIST: (state, data) => {
            state.userCouponList = data;
            if (state.userCouponList.length < 1) {
                state.userCouponListIsNull = true;
            } else {
                state.userCouponListIsNull = false;
            }
        },
        SET_NAME: (state, name) => {
            state.name = name;
            setUserName(name);
        },
        SET_ROLE: (state, role) => {
            state.role = role;
        },
        SET_MENU: (state, menu) => {
            state.menu = menu;
        },
        SET_USER_INFO: (state, info) => {
            state.userInfo = info;
            setUserInfo(info);
        },
        // 新增
        SET_ROLES: (state, roles) => {
            state.roles = roles;
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions;
        },
        SET_ROLE_DATA: (state, roleData) => {
            state.roleData = roleData;
        },
        SET_AVATAR: (state, avatar) => {
            state.avatar = avatar;
        },
        SET_POINTS_INFO: (state, data) => {
            state.userPointsInfo = data;
        },
        SET_USER_ID: (state, data) => {
            state.userId = data;
        },
        SET_TAX_POPUP_STATUS: (state, data) => {
            state.showTaxPopup = data;
        },
        SET_TAX_VALIDATE_STATUS: (state, data) => {
            state.taxPopupValidate = data;
        },
    },

    actions: {
        setTaxPopupStatus({ commit }, value) {
            commit("SET_TAX_POPUP_STATUS", value);
        },
        setTaxValidateState({ commit }, value) {
            commit("SET_TAX_VALIDATE_STATUS", value);
        },
        getServiceList({ commit }, data) {
            return new Promise((resolve) => {
                getQueryUser(data)
                    .then((val) => {
                        commit("SET_SERVICE_LIST", val.data);
                        resolve(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        },
        getCouponList({ commit }, data) {
            return new Promise((resolve) => {
                getQueryUser(data)
                    .then((val) => {
                        commit("SET_COUPON_LIST", val.data);
                        resolve(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        },
        getPointsInfo({ commit }, data) {
            return new Promise((resolve) => {
                getPoints(data).then((val) => {
                    commit("SET_POINTS_INFO", val.data);
                    resolve(data);
                });
            });
        },
        LoginByUsername({ commit }, data) {
            return new Promise((resolve, reject) => {
                autoLogin(data)
                    .then((response) => {
                        const { code, data } = response;
                        if (code === 200) {
                            commit("SET_TOKEN", data.access_token);
                            resolve(data);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 获取用户信息
        GetUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getUserInfo()
                    .then((res) => {
                        if (res.code == 200) {
                            // 验证返回的roles是否是一个非空数组
                            const data = res.data;
                            commit("SET_ROLES", data.roles);
                            commit("SET_PERMISSIONS", data.permissions);
                            commit("SET_ROLE_DATA", data.roleData);
                            commit("SET_NAME", data.user.userName);
                            commit("SET_AVATAR", data.user.avatar);
                            commit("SET_USER_ID", data.user.userId);
                            commit("SET_USER_INFO", data.user);
                            const href = window.location.href;
                            if (
                                !(
                                    href.includes("merchantpartnership") ||
                                    href.includes("talentEntry") ||
                                    window.innerWidth < 768
                                )
                            ) {
                                if (!data.config.area && !getTaxPopup()) {
                                    commit("SET_TAX_POPUP_STATUS", 1);
                                }
                            }
                        } else {
                            commit("SET_ROLES", ["ROLE_DEFAULT"]);
                        }
                        resolve(res);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        // 登出
        LogOut({ commit }) {
            return new Promise((resolve) => {
                commit("SET_TOKEN", "");
                commit("SET_NAME", "");
                commit("SET_ROLE", []);
                commit("SET_MENU", []);
                commit("SET_USER_INFO", {});
                removeToken();
                resolve();
            });
        },
    },
};

export default user;
