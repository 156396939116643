const img =
    "https://cdn.poizon.com/du_app/2020/image/15733692_modelPBEM00model_byte275163byte_921550bdf8904e759103abfb2651f19d_du_android_w1080_h1440.png?x-oss-process=image/format,webp";
export const dataList = {
    code: 200,
    msg: "操作成功",
    data: {
        total: 3,
        list: [
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178046,
                productTitle: "A BATHING APE® 上衣T恤",
                categoryName: "女装|上衣|T恤",
                productImg: img,
                categoryIdList: [359, 360],
                brandId: 1,
                brandName: null,
                articleNumber: "123456",
                sellTime: "2022-01-01 00:00:00",
                price: 2900,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178047,
                productTitle: "A BETTER MISTAKE AAA123号很好非常好",
                categoryName: null,
                productImg: img,
                categoryIdList: [359, 360, 361],
                brandId: 2,
                brandName: null,
                articleNumber: "AAA",
                sellTime: "2022-01-01 00:00:00",
                price: 4500,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
            {
                productId: 178048,
                productTitle: "À La Garçonne AAAhao很好非常好",
                categoryName: null,
                productImg: img,
                categoryIdList: [359],
                brandId: 3,
                brandName: null,
                articleNumber: "huohao",
                sellTime: "2022-01-01 00:00:00",
                price: 300,
                attrs: [
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 99,
                        attributeAttrName: "古巴領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 193,
                        attributeAttrName: "尖領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 191,
                        attributeAttrName: "無領",
                    },
                    {
                        attributeId: 10,
                        attributeName: "领型",
                        attributeAttrId: 192,
                        attributeAttrName: "其他/other",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 115,
                        attributeAttrName: "LYCRA徠卡",
                    },
                    {
                        attributeId: 11,
                        attributeName: "面料",
                        attributeAttrId: 114,
                        attributeAttrName: "冰絲",
                    },
                ],
                sexType: null,
                collectionCount: null,
                viewCount: null,
                likeCount: null,
            },
        ],
        pageNum: 1,
        pageSize: 96,
        size: 3,
        startRow: 0,
        endRow: 2,
        pages: 1,
        prePage: 0,
        nextPage: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        navigatePages: 8,
        navigatePageNums: [1],
        navigateFirstPage: 1,
        navigateLastPage: 1,
        firstPage: true,
        lastPage: true,
    },
};
