const data = [
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag1.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 1,
        img: [require("@/assets/images/goodList/bag2.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag3.png"), require("@/assets/images/goodList/bag1.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag1.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag1.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 1,
        img: [require("@/assets/images/goodList/bag2.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag3.png"), require("@/assets/images/goodList/bag1.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
    {
        isCollect: 0,
        img: [require("@/assets/images/goodList/bag1.png"), require("@/assets/images/goodList/bag3.png")],
        brand: "品牌",
        dec: "商品描述",
        price: "17000",
        discount: "",
        discountedPrice: "",
    },
];

export default data;
