<template>
    <div class="card-box">
        <div v-if="!isFriendList" class="collect" @click.stop="handleCollect">
            <el-image v-if="heart" class="heart" :src="require('@/assets/images/goods/heart.png')" />
            <el-image v-if="!heart" class="heart" :src="require('@/assets/images/goods/heart-empty.svg')" />
        </div>
        <router-link :to="`/product/detail?id=${item.productId}`" class="link">
            <div class="card-box-img">
                <el-image
                    :src="item.productImg || require('@/assets/images/placeorder/Rectangle.png')"
                    fit="contain"
                    class="el-image"
                />
                <div v-show="showActivity" class="active">
                    <div>{{ $t("限时优惠") }}</div>
                    <div>{{ $t("活动进行时") }}</div>
                </div>
            </div>
            <div style="position: relative">
                <div class="label" :style="{ visibility: item.series ? 'visible' : 'hidden' }">
                    {{ item.series || $t("至善前瞻") }}
                </div>
                <div class="brand">{{ item.brandName }}</div>
                <el-tooltip class="item" effect="dark" :content="item.productTitle" placement="top">
                    <div class="des">{{ item.productTitle }}</div>
                </el-tooltip>
                <div class="tag">
                    <span>{{ item.price | currencyHk }}</span>
                    <span v-show="item.sellPrice > item.price">{{ item.sellPrice | currencyHk }}</span>
                    <span>{{ tag }}</span>
                </div>
            </div>
        </router-link>
        <div v-if="wishListStatus && !isFriendList">
            <div class="skuContent" @click="updateSku">
                {{ item.skuName ? item.skuName.replaceAll("|", " / ") : $t("请选择规格") }}
                <i class="el-icon-arrow-down"></i>
            </div>
            <div class="addCartBtn" @click="addCart">{{ $t("加入购物袋") }}</div>
        </div>
        <div v-if="wishListStatus && isFriendList">
            <div class="friendSkuContent">
                {{ item.skuName ? item.skuName.replaceAll("|", " / ") : $t("暂未选择规格") }}
            </div>
            <div class="button-group">
                <img
                    class="sendPoints"
                    :src="require('@/assets/images/goods/sendPoint.jpg')"
                    @click="openQuickBuyBox"
                />
                <img
                    class="giftPoints"
                    :src="require('@/assets/images/goods/giftPoints.jpg')"
                    @click="openPointsDialog(item.userId)"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { addCart } from "@/api/order";

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
        wishListStatus: {
            type: Boolean,
            default: false,
        },
        isFriendList: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tag: "",
            showActivity: false,
        };
    },
    computed: {
        heart() {
            return this.$store.getters.collectNums.includes(this.item.productId);
        },
    },
    mounted() {
        this.item.tagList?.forEach((item) => {
            if (item.shopTagId === 2000000 && item.isShow) {
                if (this.item.discount) {
                    this.tag = `${((1 - this.item.discount) * 100).toFixed()}% OFF`;
                } else {
                    this.tag = `${(
                        ((this.item?.sellPrice - this.item?.price) / this.item?.sellPrice) *
                        100
                    ).toFixed()}% OFF`;
                }
            }
            if (item.shopTagId === 2000002 && item.isShow) {
                this.showActivity = true;
            }
        });
    },
    methods: {
        handleCollect() {
            if (this.getToken()) {
                const status = this.heart ? 0 : 1;
                const wishItem = { productId: this.item.productId, status };
                this.$store.dispatch("changeCollect", wishItem);
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },
        routerToProduct(id) {
            if (!id) return;
            this.$router.push(`/product/detail?id=${id}`);
        },
        updateSku() {
            this.$emit("updateSku", this.item);
        },
        addCart() {
            if (this.getToken()) {
                if (!this.item.shopProductPriceId || !this.item.skuId) {
                    this.$message({
                        message: this.$t("请选择规格"),
                        type: "warning",
                    });
                    return;
                }
                addCart({
                    productId: this.item.productId,
                    shopProductPriceId: this.item.shopProductPriceId,
                    skuId: this.item.skuId,
                }).then((value) => {
                    if (value.code === 200) {
                        this.$store.dispatch("updateCartNum");
                        this.$message({
                            message: this.$t("成功加入购物车"),
                            type: "success",
                        });
                    }
                });
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },
        openPointsDialog(uid) {
            this.$emit("openPointsDialog", uid);
        },
        openQuickBuyBox() {
            if (this.getToken()) {
                if (!this.item.shopProductPriceId || !this.item.skuId) {
                    this.$router.push(`/product/detail?id=${this.item.productId}`);
                    return;
                }
                const shopProductPriceId = this.item.shopProductPriceId;
                const checkOutQuery = [
                    {
                        skuId: this.item.skuId,
                        productId: this.item?.productId,
                        memberPoints: 0,
                        couponCode: "",
                        shopProductPriceId,
                    },
                ];

                const toString = encodeURIComponent(JSON.stringify(checkOutQuery));
                this.$router.push({
                    path: "/checkout",
                    query: { conclusion: toString, quicklyBuy: 1 },
                });
            } else {
                this.$store.dispatch("changeOpenState", true);
            }
        },
    },
};
</script>
<style scoped lang="scss">
.el-image {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.label {
    color: #999999;
    font-weight: 400;
    font-size: 13px;
    margin-top: 8px;
}

.brand {
    color: #1a1a1a;
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
}

.des {
    color: #333333;
    margin-top: 8px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-box {
    width: 320px;
    position: relative;
    margin-bottom: 80px;
    cursor: pointer;
    margin-left: 24px;
}

.card-box .card-box-img {
    width: 300px;
    height: 300px;
    position: relative;
}

.collect {
    position: absolute;
    top: 10px;
    right: 25px;
    z-index: 10;
}

.heart {
    width: 30px;
    height: 30px;
}

.collect:hover {
    background: #ffffff;
    border-radius: 12px;
    cursor: pointer;
}

.tag {
    span {
        color: #999999;
    }
    align-items: end;
    margin-top: 20px;
}

.tag span:nth-child(1) {
    color: #1a1a1a;
    font-size: 15px;
    font-weight: 600;
}

.tag span:nth-child(2) {
    color: #999999;
    text-decoration: line-through;
    font-size: 13px;
    margin-left: 10px;
}

.tag span:nth-child(3) {
    background: #fbeee6;
    color: #ec5d29;
    font-size: 12px;
    margin-left: 10px;
}

.active {
    width: 200px;
    display: flex;
    position: absolute;
    bottom: 0;
    div {
        text-align: center;
        line-height: 26px;
        font-weight: bold;
    }
    div:nth-child(1) {
        background-color: #0a5544;
        font-size: 14px;
        color: white;
        width: 70px;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }
    div:nth-child(2) {
        background-color: #e0e0e0;
        font-size: 14px;
        color: #0a5544;
        width: 100px;
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }
}

.link {
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}

.addCartBtn {
    border-radius: 8px;
    border: 1px solid #1a1a1a;
    color: #1a1a1a;
    height: 40px;
    width: 300px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
    margin-top: 10px;
}

.skuContent {
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    height: 28px;
    width: 300px;
    font-size: 14px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 10px;
    box-sizing: border-box;
    color: #1a1a1a;
}

.friendSkuContent {
    color: #1a1a1a;
    font-size: 12px;
    margin-top: 10px;
}

.sendPoints {
    width: 150px;
    border-radius: 8px;
    cursor: pointer;
}

.giftPoints {
    width: 80px;
    cursor: pointer;
}

.button-group {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
