export const blackList = [
    "/seller/margin/",
    "/auth/untoken/getPhoneCode",
    "auth/forgotPassword",
    "/auth/register",
    "/auth/login",
    "order/order/detail",
    "/search/untoken/product/searchAdvice",
    "/social/browseSocial/socialBrowse",
    "/system/user/getInfo",
    "/system/wishList",
    "/order/cart/miniCart",
    "/coupon/coupon/queryUser",
    "/order/points/rewardPoints",
    "/order/points/query",
    "/social/untoken/comment/commentList",
    "/social/untoken/comment/praise",
    "/system/prize/getInviteUser",
    "/system/fuCard/inviteFriend",
];
