import request from "@/utils/request";

/**
 * 获取品牌列表
 * @param params
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBrandList = (params) =>
    request({
        url: "/seller/brand/untoken/app/list",
        method: "get",
        params,
    });

/**
 * 获取品牌详情
 * @param brandId
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const getBrandInfo = (brandId) =>
    request({
        url: `/search/untoken/product/brand/${brandId}`,
        method: "get",
    });
