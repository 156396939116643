<template>
    <el-dialog
        :visible="visible"
        v-bind="$attrs"
        append-to-body
        width="43rem"
        class="dialog"
        :show-close="false"
        lock-scroll
        v-on="$listeners"
        @close="$emit('close', false)"
    >
        <sizeTemplate :goods="goods" @close="close" />
    </el-dialog>
</template>

<script>
import sizeTemplate from "@/views/product/productDetail/components/sizeDialog/sizeTemplate.vue";

export default {
    name: "SizeDialog",
    components: { sizeTemplate },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        goods: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        close() {
            this.$emit("close", false);
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep {
    .el-dialog {
        margin: auto !important;
        border-radius: 0.5rem !important;
    }
    .el-dialog__body {
        padding: 1.25rem 1.25rem 1.875rem !important;
    }
}
.dialog {
    display: flex;
}
</style>
