const talentRouter = [
    {
        path: "/talent/talentEntry",
        name: "talentEntry",
        meta: { navbar: false },
        component: () => import("@/views/talent/talentEntry/index"),
    },
];

export default talentRouter;
