<template>
    <div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginFormRules">
            <el-form-item v-if="loginInputType === 'email'" prop="email">
                <div class="form-title">
                    {{ loginInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input
                        v-if="loginInputType === 'email'"
                        v-model="loginForm.email"
                        type="text"
                        class="password"
                        :placeholder="$t('请输入电邮')"
                    />
                </div>
            </el-form-item>

            <el-form-item v-else prop="phonenumber">
                <div class="form-title">
                    {{ loginInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input v-model="loginForm.phonenumber" :placeholder="$t('请输入手机号')" class="el-select-logo">
                        <el-select slot="prepend" v-model="loginForm.areaCode">
                            <el-option
                                v-for="(item, index) in countCode()"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </div>
            </el-form-item>
            <div class="switch-login">
                {{ $t("或")
                }}<span
                    class="underline pointer"
                    @click="loginInputType = loginInputType === 'email' ? 'phone' : 'email'"
                    >{{ loginInputType === "phone" ? $t("电子电邮") : $t("手机号码") }}</span
                >
            </div>
            <el-form-item prop="password">
                <div class="form-title password-input">
                    {{ $t("密码") }}
                </div>
                <el-input
                    v-model="loginForm.password"
                    type="password"
                    show-password
                    class="password"
                    :placeholder="$t('请输入密码')"
                />
                <div class="form-title forget-password-input" @click="selectForgetPassword">
                    {{ $t("忘记密码") }}
                </div>
            </el-form-item>
            <div class="flex keep-login-status">
                <jf-checkbox v-model="loginForm.keepLogin" size="1.25rem" />
                <span>{{ $t("保持登录状态。") }}</span>
            </div>
            <div style="text-align: center">
                <el-button type="primary" class="login-button" :loading="loading" @click="handleLogin">
                    {{ $t("登录账户") }}
                </el-button>
            </div>
            <div class="pc-container">
                <div class="line">— {{ $t("或") }} —</div>
                <div class="other-login">
                    <div class="item" @click="facebookLogin">
                        <img :src="require('@/assets/icons/Facebook.svg')" alt="" />
                    </div>
                    <!-- <div class="item" @click="insLogin">
                        <img :src="require('@/assets/icons/Ins.svg')" alt="" />
                    </div> -->
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import JfCheckbox from "@/components/JfCheckbox/index.vue";
import { getKeepLogin, removeKeepLogin, setKeepLogin } from "@/utils/auth";
import { setCustomerUdeskInfo } from "@/utils/udesk";
import { visitLoginPage } from "@/utils/analytics";
import { countCode } from "@/enum";
import CryptoJS from "crypto-js";

const defaultLogin = {
    phonenumber: "",
    password: "",
    // keepLogin: !!getKeepLogin(),
    keepLogin: true,
    email: "",
    areaCode: "+852",
};
export default {
    components: { JfCheckbox },
    props: {
        isH5: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            loginForm: Object.assign({}, defaultLogin),
            loginInputType: "phone", // phone  email
            loginFormRules: {
                phonenumber: [
                    {
                        required: true,
                        message: this.$t("请输入您的手机号码"),
                        trigger: "change",
                    },
                    {
                        required: true,
                        pattern: /^[0-9]*$/,
                        message: this.$t("请输入正确的手机号码"),
                        trigger: "change",
                    },
                ],
                password: [{ required: true, message: this.$t("请输入您的密码"), trigger: "change" }],
            },
            startTime: 0,
        };
    },
    mounted() {
        this.startTime = new Date().getTime();
    },
    beforeDestroy() {
        const currentTime = new Date().getTime();
        const stayTime = (currentTime - this.startTime) / 1000;
        visitLoginPage(stayTime);
    },
    methods: {
        countCode() {
            return countCode;
        },
        handleLogin() {
            this.loading = true;
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    const loginObject = {
                        username:
                            this.loginInputType === "email"
                                ? this.loginForm.email
                                : `${this.loginForm.areaCode}-${this.loginForm.phonenumber}`,
                        password: this.loginForm.password,
                    };

                    const rsaUserName = this.rsaEncrypt(loginObject.username);
                    const password = this.rsaEncrypt(loginObject.password);

                    this.$store
                        .dispatch("LoginByUsername", { username: rsaUserName, password: password })
                        .then((value) => {
                            if (value) {
                                this.$store.dispatch("GetUserInfo").then((val) => {
                                    if (val.code === 200) {
                                        if (this.isH5) {
                                            localStorage.setItem("wishListPopUpControl", 0);
                                            this.$emit("registerBack", "login");
                                            return;
                                        }
                                        const redirect = this.$route.query.redirect;
                                        if (this.loginForm.keepLogin) {
                                            setKeepLogin();
                                        } else {
                                            removeKeepLogin();
                                        }
                                        defaultLogin.keepLogin = !!getKeepLogin();
                                        setCustomerUdeskInfo(val.data.user);
                                        if (redirect) {
                                            this.$router.push(redirect);
                                        }
                                        this.$store.dispatch("getWishListToCatch");
                                        this.$store.dispatch("updateCartNum");
                                        this.$message({
                                            message: this.$t("登录成功"),
                                            type: "success",
                                        });
                                        this.loading = false;

                                        this.$emit("closeDialog");
                                    }
                                });
                            }
                            this.loading = false;
                        });
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        selectForgetPassword() {
            this.$emit("selectForgetPassword");
        },
        rsaEncrypt(pas) {
            const key = CryptoJS.enc.Utf8.parse(this.rsaKey);
            const srcs = CryptoJS.enc.Utf8.parse(pas);
            const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            return encrypted.toString();
        },
        facebookLogin() {
            // facebook ID
            const clientId = "736403448006474";
            const redirectUri = location.origin + "/home";
            // 打开授权页面
            window.open(
                `https://www.facebook.com/v19.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&state={type=facebook}`
            );
        },
        insLogin() {
            // instagram ID
            const clientId = "1123803225713324";
            const redirectUri = location.origin + "/home";
            // 构建Instagram授权登录的URL
            const authUrl = `https://api.instagram.com/oauth/authorize/?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code&state={type=instagram}`;
            // 跳转到Instagram授权页面
            window.open(authUrl);
        },
    },
};
</script>

<style scoped lang="scss">
.keep-login-status {
    margin-top: 16px;
}
.login-button {
    margin-top: 30px;
    background-color: #104c3d;
    border-radius: 8px;
}
.password-input {
    margin-top: 15px;
}
.forget-password-input {
    text-decoration: underline;
    margin-top: 3px;
    cursor: pointer;
    min-width: 64px;
}
.switch-login {
    margin-top: 10px;
    font-size: 12px;
}

::v-deep .el-button.login-button.el-button--primary {
    background-color: #104c3d;
}
.line {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    color: #999;
    font-size: 14px;
}
.other-login {
    display: flex;
    justify-content: center;
    gap: 0 34px;
    .item {
        cursor: pointer;
        img {
            width: 48px;
            height: 48px;
            display: block;
        }
    }
}
</style>
