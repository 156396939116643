<template>
    <el-dialog
        :visible.sync="dialogTableVisible"
        :title="taxType === 1 ? $t('还不知道你在哪里') : $t('更改国家/地区')"
        append-to-body
        width="30rem"
        @close="closeTable"
    >
        <div class="payment-text">
            <p class="text">
                {{
                    taxType === 1
                        ? $t("各地配送时效和税率不同，请先选择地区")
                        : $t("您的收货国家/地区将修改，商品价格可能会发生变化")
                }}
            </p>
            <div style="text-align: center">
                <div v-if="taxType === 1">
                    <span style="margin-right: 1rem">*{{ $t("选择地区") }}</span>
                    <el-select v-model="value" :placeholder="$t('请选择')">
                        <el-option v-for="item in areaList" :key="item.id" :label="item.area" :value="item.id">
                        </el-option>
                    </el-select>
                </div>

                <div v-if="taxType === 1">
                    <el-button type="primary" class="check-button" @click="saveArea">
                        <span>{{ $t("选择地区") }}</span>
                    </el-button>
                </div>
                <div v-if="taxType === 2" class="areaButton">
                    <el-button type="primary" class="check-button cancel" @click="changeValidate(false)">
                        <span>{{ $t("取消") }}</span>
                    </el-button>

                    <el-button type="primary" class="check-button" @click="changeValidate(true)">
                        <span>{{ $t("确认") }}</span>
                    </el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { getProfileArea, updatePersonalInfo } from "@/api/account";
import { setTaxPopup } from "@/utils/auth";

export default {
    props: {
        quoteDialogTableVisible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            value: "",
            dialogTableVisible: false,
            areaList: [],
            taxType: 0,
        };
    },
    computed: {
        taxPopupStatus() {
            return this.$store.getters.showTaxPopup;
        },
    },
    watch: {
        quoteDialogTableVisible(val) {
            this.dialogTableVisible = val;
        },
        taxPopupStatus(val) {
            this.taxType = val;
            this.dialogTableVisible = !!val;
        },
    },
    mounted() {
        this.getAddressArea({ id: 1, name: "" });
    },
    methods: {
        getAddressArea(item) {
            getProfileArea(item).then((val) => {
                this.areaList = val.data;
            });
        },
        closeTable() {
            this.$store.dispatch("setTaxPopupStatus", 0);
            this.$emit("closeQuoteDialog", false);
            setTaxPopup();
        },
        saveArea() {
            const area = this.areaList.find((item) => item.id === this.value);
            updatePersonalInfo({ area: area.area }).then(() => {
                this.closeTable();
                this.$message.success(this.$t("保存成功"));
            });
        },
        quickGo() {
            this.$router.push("/account/profile");
        },
        changeValidate(v) {
            this.$store.dispatch("setTaxValidateState", v);
            this.closeTable();
        },
    },
};
</script>
<style scoped lang="scss">
.payment-text {
    text-align: center;
    .text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
    }
}

.check-button {
    width: 280px;
    height: 40px;
    margin-top: 50px;
    span {
        font-size: 14px;
    }
}

.areaButton {
    display: flex;
    justify-content: center;
    .check-button {
        width: 200px;
    }
    .cancel {
        span {
            color: black;
        }
        border: 1px solid #1a1a1a;
        background-color: white;
    }
}

::v-deep {
    .el-dialog__header {
        text-align: center;
    }
}
</style>
