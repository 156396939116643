import Cookies from "js-cookie";

const app = {
    state: {
        sidebar: {
            opened: Cookies.get("sidebarStatus") ? !!+Cookies.get("sidebarStatus") : true,
            withoutAnimation: false,
        },
        device: "desktop",
        language: Cookies.get("language") || "zh",
        size: Cookies.get("size") || "medium",
        openLogin: false,
        openLoginType: "login",
        notPayment: false,
    },
    mutations: {
        SET_OPEN_LOGIN: (state, open) => {
            state.openLogin = open;
        },
        SET_OPEN_LOGIN_TYPE: (state, type) => {
            state.openLoginType = type;
        },
        TOGGLE_SIDEBAR: (state) => {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
            if (state.sidebar.opened) {
                Cookies.set("sidebarStatus", 1);
            } else {
                Cookies.set("sidebarStatus", 0);
            }
        },
        CLOSE_SIDEBAR: (state, withoutAnimation) => {
            Cookies.set("sidebarStatus", 0);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation;
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device;
        },
        SET_LANGUAGE: (state, language) => {
            state.language = language;
            Cookies.set("language", language);
        },
        SET_SIZE: (state, size) => {
            state.size = size;
            Cookies.set("size", size);
        },
        SET_NOT_PAYMENT_STATUS: (state, value) => {
            state.notPayment = value;
        },
    },
    actions: {
        changeOpenState({ commit }, open) {
            commit("SET_OPEN_LOGIN", open);
        },
        changeOpenStateType({ commit }, type) {
            commit("SET_OPEN_LOGIN_TYPE", type);
        },
        setPaymentStatus({ commit }, value) {
            commit("SET_NOT_PAYMENT_STATUS", value);
        },
        toggleSideBar({ commit }) {
            commit("TOGGLE_SIDEBAR");
        },
        closeSideBar({ commit }, { withoutAnimation }) {
            commit("CLOSE_SIDEBAR", withoutAnimation);
        },
        toggleDevice({ commit }, device) {
            commit("TOGGLE_DEVICE", device);
        },
        setLanguage({ commit }, language) {
            commit("SET_LANGUAGE", language);
        },
        setSize({ commit }, size) {
            commit("SET_SIZE", size);
        },
    },
};

export default app;
