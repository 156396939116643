const shippingRouter = {
    path: "/shipping",
    name: "Shipping",
    redirect: "/address",
    component: () => import("@/views/shipping/index.vue"),
    meta: { navbar: false },
    children: [
        {
            path: "/address",
            name: "ShippingAddress",
            component: () => import("@/views/shipping/address/index.vue"),
            meta: { navbar: false },
        },
        {
            path: "/pay",
            name: "ShippingPay",
            component: () => import("@/views/shipping/pay/index.vue"),
            meta: { navbar: false },
        },
        {
            path: "/paymentCompleted",
            name: "ShippingPaymentCompleted",
            component: () => import("@/views/shipping/paymentCompleted/index.vue"),
            meta: { navbar: false },
        },
    ],
};

export default shippingRouter;
