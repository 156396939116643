<template>
    <div class="service">
        <el-image class="chat-img" :src="require('@/assets/images/customer-mobile.png')" @click="openTo" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            customerLink: "https://1762835.s2.udesk.cn/im_client/?web_plugin_id=43379",
        };
    },
    methods: {
        openTo() {
            window.open(this.customerLink);
        },
    },
};
</script>
<style scoped>
.service {
    /* position: absolute; */
    /* bottom: 60px; */
    /* right: 0px; */
}

.service span {
    color: white;
    font-size: 14px;
}

.chat-img {
    width: 60px;
}
</style>
