<template>
    <div>
        <el-form ref="registerForm" :model="registerForm" :rules="registerFormRules">
            <el-form-item v-if="registerInputType === 'email'" prop="email">
                <div class="form-title">
                    {{ registerInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input
                        v-if="registerInputType === 'email'"
                        v-model="registerForm.email"
                        type="text"
                        class="password"
                        :placeholder="$t('请输入电邮')"
                    />
                </div>
            </el-form-item>

            <el-form-item v-else prop="phonenumber">
                <div class="form-title">
                    {{ registerInputType === "phone" ? $t("手机号码") : $t("电邮") }}
                </div>
                <div class="username align-center">
                    <el-input
                        v-model="registerForm.phonenumber"
                        :placeholder="$t('请输入手机号')"
                        class="el-select-logo"
                    >
                        <el-select slot="prepend" v-model="registerForm.areaCode">
                            <el-option
                                v-for="(item, key) in countCode()"
                                :key="key"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-input>
                </div>
            </el-form-item>
            <div class="switch-login">
                {{ $t("或")
                }}<span
                    class="underline pointer"
                    @click="registerInputType = registerInputType === 'email' ? 'phone' : 'email'"
                    >{{ registerInputType === "phone" ? $t("电子电邮") : $t("手机号码") }}</span
                >
            </div>
            <el-form-item v-if="registerInputType === 'email'" prop="mailCode">
                <div class="form-title password-input">
                    {{ $t("验证码") }}
                </div>
                <div style="display: flex; justify-content: space-between">
                    <el-input
                        v-model="registerForm.mailCode"
                        type="text"
                        class="password verification-code"
                        :placeholder="$t('请输入电邮验证码')"
                    />
                    <el-button v-if="!sendStatus" type="primary" class="verification-code-button" @click="sendCode">
                        <span>{{ $t("发送验证码") }}</span>
                    </el-button>
                    <el-button
                        v-if="sendStatus"
                        type="primary"
                        class="verification-code-button"
                        disabled="disabled"
                        style="background: #4b4a4a"
                    >
                        <span>{{ countBackwards }}s</span>
                    </el-button>
                </div>
            </el-form-item>

            <el-form-item v-else prop="smsCode">
                <div class="form-title password-input">
                    {{ $t("验证码") }}
                </div>
                <div style="display: flex; justify-content: space-between">
                    <el-input
                        v-model="registerForm.smsCode"
                        type="text"
                        class="password verification-code"
                        :placeholder="$t('请输入验证码')"
                    />
                    <el-button
                        v-if="!sendStatus"
                        type="primary"
                        class="verification-code-button"
                        :loading="loadingCode"
                        :disabled="codeButton"
                        @click="sendCode"
                    >
                        <span>{{ $t("发送验证码") }}</span>
                    </el-button>
                    <el-button
                        v-if="sendStatus"
                        type="primary"
                        class="verification-code-button"
                        disabled="disabled"
                        style="background: #4b4a4a"
                    >
                        <span>{{ countBackwards }}s</span>
                    </el-button>
                </div>
            </el-form-item>
            <el-form-item prop="password">
                <div class="form-title password-input">
                    {{ $t("设置密码") }}
                </div>
                <el-input v-model="registerForm.password" type="password" show-password class="password" />
            </el-form-item>
            <el-form-item prop="password">
                <div class="form-title password-input">
                    {{ $t("确认密码") }}
                </div>
                <el-input v-model="registerForm.passwordSecond" type="password" show-password class="password" />
            </el-form-item>
            <el-form-item>
                <div class="form-title invite-code">
                    {{ $t("邀请码(选填)") }}
                </div>
                <el-input
                    v-model="registerForm.inviteCode"
                    class="password"
                    :placeholder="$t('请输入邀请码')"
                ></el-input>
            </el-form-item>
            <div class="flex checkbox" style="margin-top: 1.5rem">
                <jf-checkbox
                    v-model="registerForm.consentClause"
                    size="1.25rem"
                    :error-text="registerFormRules.consentClause[0].message"
                    :show-text="false"
                />
                <div class="policy">
                    <span class="noShrink">{{ $t("我已年满18岁，同意并接受") }}</span>
                    <span
                        class="underline noShrink"
                        style="font-weight: bold; cursor: pointer"
                        @click="$router.push('/statement/note')"
                    >
                        {{ $t("使用条款") }}
                    </span>
                    <span class="noShrink">{{ $t("及") }}</span>
                    <span
                        class="underline noShrink"
                        style="font-weight: bold; cursor: pointer"
                        @click="$router.push('/statement/privacy')"
                    >
                        {{ $t("私隐政策") }}
                    </span>
                </div>
            </div>
            <div class="flex checkbox">
                <jf-checkbox
                    v-model="registerForm.consentClause2"
                    size="1.25rem"
                    :error-text="registerFormRules.consentClause2[0].message"
                    :show-text="false"
                />
                <span
                    style="
                         {
                            width: 19rem;
                            word-break: break-word;
                        }
                    "
                    >{{ $t("本人同意Famkools按隐私政策使用我的个人资料作直接促销用途。") }}</span
                >
            </div>
            <div style="text-align: center">
                <el-button type="primary" class="login-button" :loading="loading" @click="loginCombine">
                    {{ $t("注册") }}
                </el-button>
                <div class="registerContent">
                    {{ $t("注册famkools立即获得HK$2000迎新优惠券，现在购买有机会获得Hermès洗护套装") }}
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import JfCheckbox from "@/components/JfCheckbox/index.vue";
import { authGetMailCode, authGetPhoneCode, userRegister } from "@/api/login";
import { visitRegisterPage } from "@/utils/analytics";
import { countCode } from "@/enum";
import { getShortLink } from "@/utils/auth";
import { postShortLink } from "@/api/home";
import { setCustomerUdeskInfo } from "@/utils/udesk";
import CryptoJS from "crypto-js";
const defaultRegister = {
    phonenumber: "",
    password: "",
    passwordSecond: "",
    inviteCode: "",
    consentClause: true,
    consentClause2: true,
    areaCode: "+852",
    email: "",
    mailCode: "",
    smsCode: "",
};
export default {
    components: { JfCheckbox },
    props: {
        isH5: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            codeButton: false,
            sendStatus: false,
            loadingCode: false,
            loading: false,
            countBackwards: 60,
            registerInputType: "phone", // phone email
            registerForm: Object.assign({}, defaultRegister),
            registerFormRules: {
                phonenumber: [
                    { required: true, message: this.$t("请输入您的手机号码"), trigger: "change" },
                    {
                        required: true,
                        pattern: /^[0-9]*$/,
                        message: this.$t("请输入正确的手机号码"),
                        trigger: "change",
                    },
                ],
                password: [{ required: true, message: this.$t("请输入您的密码"), trigger: "change" }],
                consentClause: [{ required: true, message: this.$t("请阅读并同意条款"), trigger: "change" }],
                consentClause2: [{ required: true, message: this.$t("请阅读并同意条款"), trigger: "change" }],
                mailCode: [{ required: true, message: this.$t("请输入电邮验证码"), trigger: "change" }],
                smsCode: [{ required: true, message: this.$t("请输入验证码"), trigger: "change" }],
            },
        };
    },
    mounted() {
        this.registerForm.inviteCode = this.$route.query.inviteCode || "";
        this.startTime = new Date().getTime();
    },
    beforeDestroy() {
        const currentTime = new Date().getTime();
        const stayTime = (currentTime - this.startTime) / 1000;
        visitRegisterPage(stayTime);
    },
    methods: {
        countCode() {
            return countCode;
        },
        rsaEncrypt(pas) {
            const key = CryptoJS.enc.Utf8.parse(this.rsaKey);
            const srcs = CryptoJS.enc.Utf8.parse(pas);
            const encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
            return encrypted.toString();
        },
        loginCombine() {
            this.loading = true;
            this.registerForm.consentClause = !!this.registerForm.consentClause;
            this.registerForm.consentClause2 = !!this.registerForm.consentClause2;
            this.$refs["registerForm"].validate(async (valid) => {
                if (valid && this.registerForm.consentClause) {
                    let registerObject = {
                        userType: "sys_user",
                        nickName: "",
                        username:
                            this.registerInputType === "email"
                                ? this.registerForm.email
                                : `${this.registerForm.areaCode}-${this.registerForm.phonenumber}`,
                        password: this.registerForm.password,
                        confirmPassword: this.registerForm.passwordSecond,
                        inviteCode: this.registerForm.inviteCode,
                        registerType: this.registerInputType === "email" ? 3 : 2,
                        code: this.registerForm.smsCode || this.registerForm.mailCode,
                    };

                    registerObject = {
                        ...registerObject,
                        username: this.rsaEncrypt(registerObject.username),
                        password: this.rsaEncrypt(registerObject.password),
                        confirmPassword: this.rsaEncrypt(registerObject.confirmPassword),
                    };

                    userRegister(registerObject).then((val) => {
                        if (val.code === 200) {
                            if (val.msg === "success") {
                                localStorage.setItem("wishListPopUpControl", 1);
                            } else {
                                localStorage.setItem("wishListPopUpControl", 0);
                            }
                            this.$store.commit("SET_TOKEN", val.data);
                            this.$store.dispatch("GetUserInfo").then((val) => {
                                if (val.code === 200) {
                                    if (this.isH5) {
                                        this.$emit("registerBack", "register");
                                        return;
                                    }
                                    this.loading = false;
                                    this.$message({
                                        message: this.$t("注册成功"),
                                        type: "success",
                                    });
                                    const redirect = this.$route.query.redirect;
                                    setCustomerUdeskInfo(val.data.user);
                                    if (redirect) {
                                        this.$router.push(redirect);
                                    }
                                    this.$store.dispatch("getWishListToCatch");
                                    this.$store.dispatch("updateCartNum");
                                    this.$emit("closeDialog");
                                    this.loading = false;
                                }
                            });
                        } else {
                            this.loading = false;
                        }
                    });
                    if (getShortLink()) {
                        postShortLink({ shortStr: getShortLink(), route: "/register", source: "pc" }).then((item) => {
                            console.log(item);
                        });
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            });
        },
        sendCode() {
            // 发送验证码点击后不可再点
            this.codeButton = true;

            this.loadingCode = true;
            this.$refs["registerForm"].validateField(["phonenumber", "email"], async (valid) => {
                // valid为正则不通过时候的提示信息。请输入正确的手机号码。无valid说明正则通过
                if (!valid) {
                    if (this.registerInputType === "phone") {
                        if (this.registerForm.phonenumber.trim() === "") {
                            this.loadingCode = false;
                            this.codeButton = false;
                            return;
                        }
                    }
                    const phone = `${this.registerForm.areaCode}-${this.registerForm.phonenumber}`;

                    if (this.registerInputType === "phone") {
                        authGetPhoneCode({ phonenumber: phone }).then((val) => {
                            // 发送验证码
                            if (val.code === 200) {
                                this.sendStatus = true;
                                this.countBackwardsTimer = setInterval(() => {
                                    this.countBackwards--;
                                    if (this.countBackwards < 1) {
                                        this.sendStatus = false;
                                        clearInterval(this.countBackwardsTimer);
                                        this.countBackwards = 60;
                                    }
                                }, 1000);
                            }
                            this.loadingCode = false;
                            this.codeButton = false;
                        });
                    } else {
                        const email = this.registerForm.email;
                        authGetMailCode({ email: email }).then((val) => {
                            // 发送验证码
                            if (val.code === 200) {
                                this.sendStatus = true;
                                this.countBackwardsTimer = setInterval(() => {
                                    this.countBackwards--;
                                    if (this.countBackwards < 1) {
                                        this.sendStatus = false;
                                        clearInterval(this.countBackwardsTimer);
                                    }
                                }, 1000);
                            }
                            this.loading = false;
                            this.codeButton = false;
                        });
                    }
                } else {
                    this.$message.error(valid);
                }
                this.loadingCode = false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.keep-login-status {
    margin-top: 16px;
}
.login-button {
    margin-top: 30px;
    background-color: #104c3d;
    border-radius: 8px;
}
.password-input {
    margin-top: 15px;
}
.forget-password-input {
    text-decoration: underline;
    margin-top: 3px;
}
.switch-login {
    margin-top: 10px;
    font-size: 12px;
}
::v-deep .el-button.login-button.el-button--primary {
    background-color: #104c3d;
}
.registerContent {
    text-align: center;
    margin: 0 auto;
    margin-top: 7%;
    background: -webkit-linear-gradient(106.17deg, #e2c69a 0%, #d5a96d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 600;
    width: 22rem;
}
.policy {
    display: flex;
    flex-wrap: wrap;
}
.noShrink {
    flex-shrink: 0;
}
.verification-code {
    width: 200px;
}

.verification-code-button {
    min-width: 140px;
    width: auto;
    background-color: #1a1a1a;
}

.verification-code-button span {
    font-size: 12px;
}
</style>
