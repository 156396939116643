<template>
    <div>
        <div class="icons-area">
            <!--领取优惠卷提示隐藏-->
            <img
                v-if="!getToken() && false"
                class="register-notice"
                :src="require('@/assets/images/couponGift/register-notice.gif')"
            />
            <span v-if="$store.getters.name" class="user-name-login"
                >Hi, {{ $store.getters.userInfo.nickName || $store.getters.name }}</span
            >
            <div @click="goToFind">
                <el-image class="icons pointer hover" :src="findUrl" fit="contain" lazy />
            </div>
            <div @click="loginPopup('/account/order')">
                <el-image class="icons pointer hover" :src="centerUrl" fit="contain" lazy />
            </div>
            <div class="bags-box" @click="loginPopup('/collect')">
                <el-image class="icons pointer hover" :src="collectionUrl" fit="contain" lazy />
                <div v-if="getCollectNums > 0" class="bagsNums">
                    <span>
                        {{ getCollectNums }}
                    </span>
                </div>
            </div>
            <div class="bags-box" @click="loginPopup('/bags')">
                <el-image class="icons pointer hover" :src="bagUrl" fit="contain" lazy />
                <div v-if="$store.getters.cartNums > 0" class="bagsNums">
                    <span>
                        {{ $store.getters.cartNums }}
                    </span>
                </div>
            </div>
        </div>
        <jf-dialog
            :visible="dialogVisible"
            :class="{
                'login-from': true,
                'login-from-tab': activeName !== 'forget',
                'forget-from': activeName === 'forget',
            }"
            width="46rem"
            title="FAMKOOLS"
            color="#104C3D"
            @close="closeLoginBox"
        >
            <div class="contain-login" style="display: flex; align-items: center">
                <div>
                    <i v-if="activeName === 'forgets'" class="el-icon-arrow-left" @click="activeName = 'login'" />
                    <el-tabs v-if="dialogVisible" v-model="activeName" class="tabs" @tab-click="handleClick">
                        <el-tab-pane v-if="activeName !== 'forget'" :label="$t('登录账户')" name="login">
                            <loginFrom
                                v-if="activeName === 'login'"
                                @selectForgetPassword="selectForgetPassword"
                                @closeDialog="closeDialog"
                            />
                        </el-tab-pane>
                        <el-tab-pane v-if="activeName !== 'forget'" :label="$t('注册账户')" name="register">
                            <registerFrom
                                v-if="activeName === 'register'"
                                @selectForgetPassword="selectForgetPassword"
                                @closeDialog="closeDialog"
                            />
                        </el-tab-pane>
                        <el-tab-pane v-if="activeName === 'forget'" :label="$t('忘记密码')" name="forget">
                            <forgetPassword
                                v-if="activeName === 'forget'"
                                @selectForgetPassword="selectForgetPassword"
                            />
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="register-img">
                    <!-- <img :src="require('@/assets/images/couponGift/coupon-register.png')" /> -->
                    <div class="register-title">{{ $t("注册获取优惠券") }}</div>
                    <img :src="require('@/assets/images/couponGift/coupon-register-pc.png')" />
                </div>
            </div>
        </jf-dialog>

        <jf-dialog
            :visible.sync="wechatVisible"
            :title="$t('微信登录')"
            :show-close="false"
            @close="wechatVisible = false"
        >
            <div style="text-align: center; font-size: 1.375rem">
                <el-image
                    style="width: 18rem; height: 18rem; padding-top: 1.25rem"
                    :src="wechartQRcode"
                    :preview-src-list="[wechartQRcode]"
                    lazy
                />
                <div>{{ $t("使用微信扫一扫") }}</div>
                <div>FAMKOOLS</div>
                <el-button type="primary" style="width: 100%; margin-top: 2.5rem" @click="cancelWeChatLogin">
                    {{ $t("取消微信登录") }}
                </el-button>
            </div>
        </jf-dialog>
    </div>
</template>

<script>
import { userRegister } from "@/api/login";
import loginFrom from "../loginFrom/login.vue";
import registerFrom from "../loginFrom/register.vue";
import forgetPassword from "../loginFrom/forgetPassword.vue";
import { visitCommunityByIcon,enter_register,complete_registration } from "@/utils/analytics";
import { getToken } from "@/utils/auth";

const defaultLogin = {
    phonenumber: "",
    password: "",
    keepLogin: false,
    email: "",
};
const defaultRegister = {
    nickName: "",
    phonenumber: "",
    password: "",
    consentClause: null,
    consentClause2: null,
    areaCode: "1",
    email: "",
};
const facebookUrl = require("@/assets/images/facebook.png");
const instagramUrl = require("@/assets/images/instagram.png");
const wechatUrl = require("@/assets/images/wechat.png");
const appleUrl = require("@/assets/images/apple.png");
const wechartQRcode = require("@/assets/images/QRcode.png");
const findUrl = require("@/assets/icons/global.png");
const addressUrl = require("@/assets/images/ribbon/dizhi.png");
const centerUrl = require("@/assets/icons/personal.png");
const collectionUrl = require("@/assets/icons/collect.png");
const bagUrl = require("@/assets/icons/bag.png");
export default {
    name: "JfRibbon",
    components: {
        loginFrom,
        registerFrom,
        forgetPassword,
    },
    data() {
        return {
            dialogVisible: false,
            wechatVisible: false,
            activeName: "register", // login | register | forget
            clause: `<span style="color:#898989">在您使用我们的服务前，请您务必审慎阅读、充分理解')<span style="color:#222222">条款与条件、隐私政策</span>与<span style="color:#222222">个人敏感信息政策+</span>的各条款。我们在向您提供服务的过程中，需要将您的个人信息分享提供给平台服务相关的第三方，点击了解<span style="color:#222222">个人信息分享目录</span>我们可能会同时收集您的个人信息，从而为您展示个性化购物推荐和搜索热词，并为您订阅获取新近单品、优惠活动与折扣抢先购等资讯。您可随时在<span style="color:#222222">“我的设置”</span>中取消订阅。</span>`,
        };
    },
    computed: {
        openLoginStatus() {
            return this.$store.getters.openLogin;
        },
        getLoginStatus() {
            return this.$store.getters.openLoginType;
        },
        getCollectNums() {
            return this.$store.getters.collectNums.length;
        },
        otherLogin() {
            return [
                {
                    icon: facebookUrl,
                    text: "facebook",
                },
                {
                    icon: instagramUrl,
                    text: "instagram",
                },
                {
                    icon: wechatUrl,
                    text: "WeChat",
                },
                {
                    icon: appleUrl,
                    text: "Apple",
                },
            ];
        },
        wechartQRcode() {
            return wechartQRcode;
        },
        // 发现
        findUrl() {
            return findUrl;
        },
        // 地址
        addressUrl() {
            return addressUrl;
        },
        // 个人中心
        centerUrl() {
            return centerUrl;
        },
        // 收藏
        collectionUrl() {
            return collectionUrl;
        },
        // 购物袋
        bagUrl() {
            return bagUrl;
        },
    },
    watch: {
        openLoginStatus(newValue) {
            if (newValue) {
                this.dialogVisible = true;
            }
        },
        getLoginStatus(newValue) {
            if (newValue) {
                this.activeName = newValue;
            }
        },
    },
    methods: {
        getToken,
        goToFind() {
            visitCommunityByIcon();
            enter_register();
            this.$router.push("/find");
        },
        closeLoginBox() {
            this.activeName = "login";
            this.dialogVisible = false;
            this.$store.dispatch("changeOpenState", false);
            this.$store.dispatch("changeOpenStateType", "login");
        },
        loginPopup(path) {
            localStorage.setItem("current_light_id", 2);
            if (this.$store.getters.token) {
                this.$router.push(path);
            } else {
                this.dialogVisible = true;
            }
        },
        closeDialog() {
            this.dialogVisible = false;
        },
        selectForgetPassword(v = "forget") {
            this.activeName = v;
        },
        centerClickRight() {
            this.$router.push("/account");
            document.oncontextmenu = new Function("event.returnValue=false");
            setTimeout(function () {
                document.oncontextmenu = new Function("event.returnValue=true");
            }, 500);
        },
        handleClick() {
            this.loginForm = Object.assign({}, defaultLogin);
            this.registerForm = Object.assign({}, defaultRegister);
        },
        // Combine Login

        // 注册
        handleRegister() {
            this.registerForm.consentClause = !!this.registerForm.consentClause;
            this.$refs["registerForm"].validate((valid) => {
                if (valid && this.registerForm.consentClause) {
                    userRegister(this.registerForm);
                    complete_registration();
                    this.$message({
                        message: this.$t("注册成功"),
                        type: "success",
                    });
                    this.dialogVisible = false;
                } else {
                    return false;
                }
            });
        },
        // 其他登录方式
        handleOtherLogin(item) {
            if (item === "WeChat") {
                this.dialogVisible = false;
                setTimeout(() => {
                    this.wechatVisible = true;
                }, 300);
            } else {
                this.$message({
                    message: "使用" + item + "登录",
                    type: "success",
                });
            }
        },
        // 取消微信登录
        cancelWeChatLogin() {
            // 清空状态
            this.handleClick();
            this.wechatVisible = false;
            setTimeout(() => {
                this.dialogVisible = true;
            }, 300);
        },
    },
};
</script>

<style scoped lang="scss">
.register-img {
    width: 340px;
    height: 430px;
    border-left: 1px solid #e6e6e6;
    margin-left: 20px;
    padding: 0 0 0 20px;
    text-align: center;
    .register-title {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    img {
        width: 100%;
    }
}
.bags-box {
    position: relative;
    .bagsNums {
        position: absolute;
        width: 20px;
        height: 14px;
        background-color: #1a1a1a;
        right: 8px;
        bottom: 15px;
        border-radius: 10px;
        color: white;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: block;
            transform: scale(0.8);
        }
    }
}
.user-name-login {
    font-size: 16px;
    margin-right: 15px;
}
.keep-login-status {
    margin-top: 16px;
}
.login-button {
    margin-top: 30px;
    background-color: #104c3d;
    border-radius: 8px;
}
.password-input {
    margin-top: 15px;
}
.forget-password-input {
    text-decoration: underline;
    margin-top: 3px;
}
.icons-area {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1.375rem;
    white-space: nowrap;
    width: 240px;
    .register-notice {
        position: absolute;
        z-index: 999;
        width: 160px;
        right: 150px;
        top: -25px;
    }
    .icons {
        margin-left: 12px;
        width: 1.8rem;
        height: 1.8rem;
    }
}
::v-deep {
    .el-tabs__nav-scroll::after {
        bottom: 0.625rem !important;
    }
}
.tabs {
    margin-top: 0.9375rem;
    .username {
        width: 100%;
        .from-username {
            width: 5.5rem;
            height: 2.75rem;
            background: #f5f5f5;
            border-radius: 0.5rem;
            font-size: 1rem;
            color: #222222;
            flex-shrink: 0;
            white-space: nowrap;
            margin-right: 0.4375rem;
        }
        .arrow-down {
            color: #222222;
            font-size: 1rem;
            margin-left: 1rem;
        }
    }
    .switch-login {
        margin-top: 0.375rem;
        font-size: 0.875rem;
    }
    .checkbox {
        margin-top: 10px;
    }
}
</style>
<style>
.el-select-logo .el-input__inner {
    height: 46px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}
.el-select-logo .el-input {
    width: 110px;
    color: black;
}
.el-select-logo .el-input-group__prepend {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: none;
}
.login-from .title {
    font-size: 26px !important;
    font-weight: 600;
    margin-left: 100px;
    position: absolute;
}

.login-from-tab .el-tabs__nav {
    margin-left: 88px;
}

.forget-from .el-tabs__nav {
    margin-left: 135px;
}

.password .el-input__inner {
    height: 46px;
    border-radius: 8px;
}

/*.el-icon-arrow-left {
  position: relative;
  top: -23px;
  font-size: 20px;
  cursor: pointer;
}*/
</style>
