import Cookies from "js-cookie";
import { sexType } from "@/enum";

const TokenKey = "Authorization";
const UserName = "user_name";

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    const domain = window.location.hostname;
    if (domain.includes("famkools.com")) {
        return Cookies.set(TokenKey, token, { domain: ".famkools.com" });
    } else {
        return Cookies.set(TokenKey, token);
    }
}

export function getUserName() {
    return Cookies.get(UserName);
}

export function setUserName(name) {
    return Cookies.set(UserName, name);
}

export function removeToken() {
    Cookies.remove(UserName);
    return Cookies.remove(TokenKey);
}

export function setLang(v) {
    return Cookies.set("Accept-Language", v);
}

export function getLang() {
    return Cookies.get("Accept-Language");
}

export function setKeepLogin() {
    return Cookies.set("keep-login", "1");
}

export function getKeepLogin() {
    return Cookies.get("keep-login");
}

export function removeKeepLogin() {
    Cookies.remove("keep-login");
}

export function setSexType(v) {
    return Cookies.set("sexType", v);
}

export function getSexType(v) {
    const value = Cookies.get("sexType", v) ?? 1;
    return { id: value, label: sexType[value] };
}

export function setUserInfo(data) {
    return Cookies.set("info", JSON.stringify(data));
}

export function getUserInfo() {
    const info = Cookies.get("info");
    return info ? JSON.parse(info) : {};
}

export function setSource(source) {
    return Cookies.set("source", source);
}

export function setPlatform(platform) {
    return Cookies.set("platform", platform);
}

export function getSoundControl() {
    const soundSwitch = localStorage.getItem("soundSwitch") || "off";
    return soundSwitch === "off";
}

export function setSoundControl(val) {
    localStorage.setItem("soundSwitch", val);
}

export function setRecordTime(val) {
    localStorage.setItem("recordTime", val);
}

export function getRecordTime() {
    return localStorage.getItem("recordTime");
}

export function getShortLink() {
    return localStorage.getItem("shortLink");
}

export function setShortLink(link) {
    localStorage.setItem("shortLink", link);
}

export function getPlatform() {
    return Cookies.get("platform");
}

export function getTaxPopup() {
    return Cookies.get("taxStatus");
}

export function setTaxPopup() {
    return Cookies.set("taxStatus", "1");
}
