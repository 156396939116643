<template>
    <div class="sell-contain">
        <p class="title">{{ $t("出货地") }}</p>
        <JfSelect ref="channelSelect" :select="channel" />
        <div class="handle">
            <div class="counter">
                <p class="title">{{ $t("数量选择") }}</p>
                <el-input v-model="nums" type="number" :placeholder="$t('请输入数量')" @change="getMargin">
                    <div slot="prepend" @click="addNums">+</div>
                    <div slot="append" @click="lessNums">-</div>
                </el-input>
            </div>
            <div class="counter">
                <p class="title">{{ $t("出售价格") }}</p>
                <el-input v-model="price" type="number" :placeholder="$t('请输入价格')" @change="getService">
                    <div slot="prepend">HK$</div>
                </el-input>
            </div>
        </div>
        <span class="tips"
            >({{ $t("出价范围") }}:{{ limitGuide.lowerLimit }}～{{ limitGuide.upperLimit }},{{ $t("尾数规则") }}:{{
                limitGuide.priceEnd
            }})</span
        >
        <p class="title">{{ $t("预计收入") }}</p>
        <div class="price-list">
            <div>
                <span>{{ $t("技术服务费") }}{{ serviceFee.technologyServiceFeePercent ?? 0 }}%</span>
                <span>{{ serviceFee.technologyServiceFee ?? 0 }}{{ $t("元") }}</span>
            </div>
            <div>
                <span>{{ $t("支付手续费") }} {{ serviceFee.payServiceFeePercent ?? 0 }}%</span>
                <span> {{ serviceFee.payServiceFee ?? 0 }}{{ $t("元") }}</span>
            </div>
            <div>
                <span>{{ $t("操作服务费") }}</span> <span>{{ serviceFee.operationServiceFee ?? 0 }}{{ $t("元") }}</span>
            </div>
            <div>
                <span>{{ $t("物料费(以实际使用物料为主)") }}</span>
                <span>{{ serviceFee.materialCost ?? 0 }}{{ $t("元") }}</span>
            </div>
            <div>
                <span>{{ $t("邮费(以实际邮费为准)") }}</span>
                <span>{{ serviceFee.postageFee ?? 0 }}{{ $t("元") }}</span>
            </div>
            <hr style="border-bottom: 1px solid #e6e6e6" />
            <div>
                <span>{{ $t("预计收入") }}</span> <span>HK${{ expectedPrice }}/件</span>
            </div>
            <div class="tips1">{{ $t("实际收入以订单为主") }}</div>
        </div>
        <div class="total-price">{{ $t("保证金合计") }}: HK${{ marginPrice }}</div>
        <div class="tips1">{{ $t("实际收入以订单为主") }}</div>

        <p class="title" style="margin-top: 1rem">{{ $t("支付方式") }}</p>
        <div class="paymentMethod">
            <el-radio v-model="paymentMethod" label="1" class="item">
                <div class="paymentItem">
                    <span class="item-name">{{ $t("支付宝") }}</span
                    ><img :src="require('@/assets/icons/alipay.svg')" />
                </div>
            </el-radio>
        </div>
        <div class="buy-button">
            <el-button type="primary" class="box" @click="setMerchantPrice">
                <span class="goods-button-item">{{ $t("支付保证金") }}</span>
            </el-button>
        </div>
    </div>
</template>
<script>
import JfSelect from "@/components/JfSelect/index.vue";
import { getChannelList, getMarginPrice, getProductMerchantPrice, postMerchantPrice } from "@/api/dict/merchant";
import { getServiceFee } from "@/api/platform/productMerchantPrice";
const math = require("mathjs");

export default {
    components: { JfSelect },
    data() {
        return {
            paymentMethod: "1",
            channel: [this.$t("香港 (24小时发货)"), this.$t("大陆 (24小时发货)"), this.$t("澳门 (36小时发货)")],
            nums: 1,
            price: 0,
            limitGuide: "",
            serviceFee: {
                materialCost: 0,
                operationServiceFee: 0,
                payServiceFee: 0,
                payServiceFeePercent: 0,
                postageFee: 0,
                technologyServiceFee: 0,
                technologyServiceFeePercent: 0,
            },
            marginPrice: 0,
        };
    },
    computed: {
        expectedPrice() {
            return Number(
                math.format(
                    this.price -
                        Number(this.serviceFee.technologyServiceFee ?? 0) -
                        Number(this.serviceFee.payServiceFee ?? 0) -
                        Number(this.serviceFee.operationServiceFee ?? 0) -
                        Number(this.serviceFee.materialCost ?? 0) -
                        Number(this.serviceFee.postageFee ?? 0),
                    14
                )
            );
        },
    },
    created() {
        const product_id = parseInt(this.$route.query.id);
        getChannelList("merchant_price_channel").then((val) => {
            this.channel = val.data.map((item) => {
                return { id: item.dictValue, label: item.dictLabel };
            });
        });
        getProductMerchantPrice({ productId: product_id }).then((val) => {
            this.limitGuide = val.data;
            this.price = this.limitGuide.lowerLimit;
            this.getService();
        });
    },
    methods: {
        addNums() {
            this.nums++;
            this.getMargin();
        },
        lessNums() {
            if (this.nums > 0) {
                this.nums--;
                this.getMargin();
            }
        },
        getService() {
            const product_id = parseInt(this.$route.query.id);
            getServiceFee({ productId: product_id, price: this.price }).then((val) => {
                this.serviceFee = val.data;
            });
            getMarginPrice({ beginNum: this.nums, price: this.price }).then((val) => {
                this.marginPrice = val.data.marginTotalAmount;
            });
        },
        getMargin() {
            this.getService();
        },
        setMerchantPrice() {
            if (!this.price) {
                this.$message({
                    message: this.$t("请输入金额"),
                    type: "error",
                });
                return;
            }
            const product_id = parseInt(this.$route.query.id);
            let skuId = 0;
            this.$emit("getSkuId", (val) => {
                skuId = val;
            });
            const goodsChannel = this.$refs.channelSelect.selectId;

            if (!goodsChannel) {
                this.$message({
                    message: this.$t("请选择出货地"),
                    type: "warning",
                });
                return;
            }

            const data = [
                {
                    productId: product_id,
                    price: this.price,
                    beginNum: this.nums,
                    skuId: skuId,
                    goodsChannel: goodsChannel,
                },
            ];
            postMerchantPrice(data).then((val) => {
                if (val.code === 200 && val.data.length) {
                    this.$router.push(`/payment/${val.data[0].marginId}/${encodeURIComponent(this.marginPrice)}/1`);
                }
            });
        },
    },
};
</script>
<style scoped lang="scss">
.counter {
    width: 200px;
}
.product-info {
    display: flex;
    div {
        margin-bottom: 15px;
    }
}
.product-image {
    width: 110px;
    margin-right: 20px;
}

.title {
    font-size: 16px;
    font-weight: bold;
}

.select-box {
    display: flex;
    flex-wrap: wrap;
    div {
        display: block;
        height: 60px;
        min-width: 90px;
        margin-right: 10px;
        border: 1px solid #e5e4e4;
        text-align: center;
        line-height: 28px;
        border-radius: 10px;
        margin-top: 12px;
        cursor: pointer;
        padding: 0 10px 0 10px;
    }
}

.handle {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.channel-select {
    border: 1px solid black !important;
}

.price {
    margin-left: 30px;
}

.price-list {
    div {
        font-size: 14px;
        color: #333333;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
}

.goods-button-item {
    font-size: 14px;
    image {
        cursor: pointer;
    }
}

.buy-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.sell-contain {
    padding: 0 20px 0 20px;
}

.tips {
    font-size: 12px;
    text-align: right;
    display: block;
    margin-top: 8px;
}

.tips1 {
    font-size: 8px !important;
    color: #999999 !important;
    margin-top: -10px;
}

.total-price {
    color: #dd4b39;
    margin-bottom: 10px;
    margin-top: 30px;
}

.paymentItem {
    display: flex;
    align-items: center;
    img {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
    span {
        font-size: 16px;
    }
}
.item {
    display: flex;
    align-items: center;
}

.paymentMethod {
    width: 400px;
}
</style>
