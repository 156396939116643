<template>
    <jf-dialog
        :visible.sync="subscriberVisible"
        :title="$t('订阅享 8 折')"
        :show-close="false"
        @close="subscriberVisible = false"
    >
        <el-image style="width: 100%; padding-top: 0.625rem" :src="subscriberUrl" lazy />
        <div style="margin-top: 0.625rem">
            {{ $t("订阅方式") }}
        </div>
        <el-form ref="form" :model="form" :rules="rules">
            <el-form-item ref="email" prop="email">
                <div class="flex" style="margin-top: 0.9375rem">
                    <jf-checkbox v-model="form.emailCheck" size="1.25rem" />
                    <span>{{ $t("订阅邮件") }}</span>
                </div>
                <el-input v-if="form.emailCheck" v-model="form.email" class="input-1" />
            </el-form-item>
            <el-form-item ref="message" prop="message">
                <div class="flex">
                    <jf-checkbox v-model="form.messageCheck" size="1.25rem" />
                    <span>{{ $t("短信服务") }}</span>
                </div>
                <el-input v-if="form.messageCheck" v-model="form.message" class="input-1" />
            </el-form-item>
        </el-form>
        <span style="font-size: 14px; color: #4c4c4c">
            {{
                $t(
                    "订阅即表示您同意通过电子邮件和/或短信接收营销信息，并确认您已阅读我们的 隐私政策。 若要退订电子报，在邮件底部取消或者回复短信STOP即可。"
                )
            }}
        </span>
        <div v-if="errorShow" class="error-text">
            {{ $t("请选择一个订阅方式") }}
        </div>
        <el-button type="primary" style="width: 100%; margin-top: 2.5rem" @click="handleSubscriber">
            {{ $t("立即订阅") }}
        </el-button>
    </jf-dialog>
</template>

<script>
import JfCheckbox from "@/components/JfCheckbox/index.vue";

const subscriberUrl = require("@/assets/images/subscriber.png");
export default {
    components: { JfCheckbox },
    // 组件的JavaScript代码
    data() {
        return {
            form: {
                email: "",
                emailCheck: false,
                message: "",
                messageCheck: false,
            },
            rules: {},
            errorShow: false,
        };
    },
    computed: {
        subscriberUrl() {
            return subscriberUrl;
        },
        subscriberVisible: {
            get() {
                return this.$store.state.subscriberVisible;
            },
            set(val) {
                this.$store.dispatch("setSubscriberVisible", val);
            },
        },
    },
    watch: {
        "form.emailCheck"(val) {
            if (val) {
                this.rules.email = { required: true, message: this.$t("请输入有效的电邮地址"), trigger: "blur" };
                this.errorShow = false;
            } else {
                this.$refs.email.clearValidate();
                // 删除rules中的email
                delete this.rules.email;
            }
        },
        "form.messageCheck"(val) {
            if (val) {
                this.rules.message = { required: true, message: this.$t("请输入您的手机号码"), trigger: "blur" };
                this.errorShow = false;
            } else {
                this.$refs.message.clearValidate();
                // 删除rules中的message
                delete this.rules.message;
            }
        },
    },
    methods: {
        handleSubscriber() {
            if (Object.keys(this.rules).length === 0) {
                this.errorShow = true;
                return;
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.$message({
                        message: this.$t("订阅成功"),
                        type: "success",
                    });
                    this.subscriberVisible = false;
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
* {
    font-size: 1.125rem;
}
.error-text {
    font-size: 14px;
    margin-top: 10px;
    color: #f56c6c;
}
</style>
