import request from "@/utils/request";
// 服务费测算
export function getServiceFee(query) {
    return request({
        url: "/seller/productMerchantPrice/getServiceFee",
        method: "get",
        params: query,
    });
}

// 商品出价
export function addMerchantPrice(data) {
    return request({
        url: "/seller/productMerchantPrice",
        method: "post",
        data: data,
    });
}

//获取商品出价范围
export function getPriceLimit(query) {
    return request({
        url: "/seller/productMerchantPrice/getPriceLimit",
        method: "get",
        params: query,
    });
}
