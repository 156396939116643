import store from "@/store";
export default {
    install(Vue) {
        Vue.prototype.getToken = () => {
            return !!store.getters.token;
        };
        Vue.prototype.isPhone = () => {
            const availScreenWidth = window.screen.availWidth;
            return availScreenWidth < 700;
        };
    },
};
