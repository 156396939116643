const loading = {
    state: {
        loading: false,
        type: "loading",
    },

    mutations: {
        SET_LOADING: (state, loading) => {
            state.loading = loading;
        },
        SET_TYPE: (state, type) => {
            state.type = type;
        },
    },

    actions: {
        setLoading({ commit }, loading) {
            return new Promise((resolve) => {
                commit("SET_LOADING", loading);
                resolve();
            });
        },
        setType({ commit }, type) {
            return new Promise((resolve) => {
                commit("SET_TYPE", type);
                resolve();
            });
        },
    },
};

export default loading;
