<template>
    <el-dialog :visible.sync="dialogTableVisible" append-to-body width="30rem" @close="closeTable">
        <div class="payment-text">
            <p class="text">{{ text }}</p>
            <div style="text-align: center">
                <el-button type="primary" class="check-button" @click="quickGo">
                    <span>{{ bottomText }}</span>
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        quoteDialogTableVisible: {
            type: Boolean,
            default: false,
        },
        merchantType: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        bottomText: {
            type: String,
            default: "",
        },
        isIdentify: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            dialogTableVisible: false,
        };
    },
    watch: {
        quoteDialogTableVisible(val) {
            this.dialogTableVisible = val;
        },
    },
    methods: {
        closeTable() {
            this.$emit("closeQuoteDialog", false);
        },
        quickGo() {
            this.closeTable();
            if (this.merchantType) {
                if (this.merchantType !== "1") {
                    window.open(
                        `${process.env.VUE_APP_MERCHANTS_URL}`,
                        "_blank"
                    );
                } else {
                    if (this.isIdentify === "0") {
                        this.$router.push("/merchantpartnership");
                    }
                }
            } else {
                this.$router.push("/merchantpartnership");
            }
        },
    },
};
</script>
<style scoped lang="scss">
.payment-text {
    text-align: center;
    .text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
    }
}

.check-button {
    width: 280px;
    height: 40px;
    margin-top: 50px;
    span {
        font-size: 14px;
    }
}
</style>
