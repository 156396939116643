<template>
    <section class="app-main">
        <div class="app-content">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive" :key="key" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" :key="key" />
        </div>
    </section>
</template>

<script>
export default {
    name: "AppMain",
    computed: {
        key() {
            return this.$route.path;
        },
        background() {
            return this.$route.meta?.background || "#FFFFFF";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
$background: v-bind(background);
.app-main {
    width: 100%;
    min-height: 80vh;
    position: relative;
    background: $background;
    z-index: 1;
    .app-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: $main-width;
        margin: auto;
    }
}
</style>
