<template>
    <div>
        <el-dropdown trigger="click">
            <slot></slot>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                    <div style="display: flex" @click="shareFacebook">
                        <div><img class="share-class" :src="require('@/assets/icons/share/facebook.png')" /></div>
                        <div>{{ $t("分享到Facebook") }}</div>
                    </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="false">
                    <div style="display: flex">
                        <div><img class="share-class" :src="require('@/assets/icons/share/instagram.png')" /></div>
                        <div>{{ $t("分享到Instagram") }}</div>
                    </div>
                </el-dropdown-item>
                <el-dropdown-item>
                    <div style="display: flex" @click="shareWhatsApp">
                        <div><img class="share-class" :src="require('@/assets/icons/share/WhatsApp.png')" /></div>
                        <div>{{ $t("用WhatsApp发送") }}</div>
                    </div>
                </el-dropdown-item>
                <el-dropdown-item>
                    <div style="display: flex" @click="shareTwitter">
                        <div><img class="share-class" :src="require('@/assets/icons/share/twitter.png')" /></div>
                        <div>{{ $t("分享到Twitter") }}</div>
                    </div>
                </el-dropdown-item>
                <el-dropdown-item>
                    <div style="display: flex" @click="copyLink">
                        <div><img class="share-class" :src="require('@/assets/icons/share/link.png')" /></div>
                        <div>{{ $t("复制链接") }}</div>
                    </div>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
export default {
    props: {
        detailData: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            default: "social",
        },
    },
    data() {
        return {};
    },
    methods: {
        copyLink() {
            this.$emit("copyLink");
        },
        shareFacebook() {
            const link = this.type === "social" ? this.socialLink() : this.productLink();
            window.open(
                "http://www.facebook.com/sharer.php?u=" +
                    encodeURIComponent(link) +
                    "&t=" +
                    encodeURIComponent("Famkools社區"),
                "sharer",
                "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
            );
        },
        shareWhatsApp() {
            const link = this.type === "social" ? this.socialLink() : this.productLink();
            window.open(
                "https://api.whatsapp.com/send/?text=" + encodeURIComponent(link),
                "sharer",
                "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
            );
        },
        shareTwitter() {
            const link = this.type === "social" ? this.socialLink() : this.productLink();
            window.open(
                "https://twitter.com/share?url" + encodeURIComponent(link),
                "sharer",
                "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=600, height=450,top=100,left=350"
            );
        },
        socialLink() {
            const domain = `${location.protocol}//${window.location.host}` + "/findList";
            return `${domain}?socialId=${this.detailData.socialId}`;
        },
        productLink() {
            const domain = `${location.protocol}//${window.location.host}` + "/product/detail";
            return `${domain}?id=${this.$route.query.id}`;
        },
    },
};
</script>
<style scoped lang="scss">
.share-class {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}
</style>
